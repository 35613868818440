import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ButtonLoader from "../utils/components/ButtonLoader";
import renderHTML from "react-render-html";

export default class componentName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogs: null,
    };
  }
  fetchLatestBlogs = async () => {
    try {
      this.setState({ blogs: null });
      const res = await axios.get("/api/blogs/latest_blogs");
      const { blogs } = res.data;
      this.setState({ blogs });
    } catch (err) {
      console.log(err);
      this.setState({ blogs: false });
    }
  };
  extractContent = (s) => {
    let span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  renderBlogsHelper = () => {
    return this.state.blogs.map((blog, i) => (
      <a href={blog.link} target="_blank" className="util__noStyleLink">
        <div className="card card__infographic">
          <div
            className={`card__infographic--image__container card__infographic--image__container--${i +
              1}`}
          >
            <div
              className="card__infographic--image"
              style={{
                background: `url('${blog.cover}'), url('https://i.ibb.co/6bQNtVw/business-3560922-640.jpg')`,
                backgroundSize: "cover",
              }}
            ></div>
          </div>
          <div className="card__infographic__info">
            <p className="card__infographic__info--heading heading__goth">
              {blog.title}
            </p>
            <p className="card__infographic__info--sub heading__raleway--fam">
              {this.extractContent(blog.story).slice(0, 75)}...
            </p>
            <a
              href={blog.link}
              target="_blank"
              className="util__noStyleLink card__infographic__info--link"
            >
              Read more
            </a>
          </div>
        </div>
      </a>
    ));
  };
  renderBlogs = () => {
    if (this.state.blogs === false) {
      return (
        <div>
          Unable to fetch blogs.{" "}
          <span onClick={this.fetchLatestBlogs} className="util__blueLink">
            Retry
          </span>
        </div>
      );
    } else if (this.state.blogs && this.state.blogs.length > 0) {
      return this.renderBlogsHelper();
    } else {
      return <ButtonLoader size={"5rem"} />;
    }
  };
  componentDidMount() {
    this.fetchLatestBlogs();
  }
  render() {
    return (
      <div className="landing__alt__section--even">
        <div className="landing__alt__section__content">
          <div className="landing__title heading__primary heading__primary--light heading__goth--fam">
            Resources
          </div>
          <div
            style={{ alignItems: "center" }}
            className="landing__flexRowContainer"
          >
            {this.renderBlogs()}
          </div>
        </div>
      </div>
    );
  }
}
