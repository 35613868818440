export default () => {
  try {
    const appHeight = () => {
      console.log("setting vh");
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight}px`
      );
    };
    if (window) {
      window.addEventListener("resize", appHeight);
      appHeight();
    }
  } catch (err) {
    console.log(err);
  }
};
