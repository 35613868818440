import React, { Component } from "react";

export default class LoginGoogle extends Component {
	render() {
		return (
			<a href="/api/auth/google">
				<button
					id="googleButton"
					onClick={this.props.loginGoogle}
					className="btn btn__google btn__transparent btn__wide"
				>
					Login with Google
				</button>
			</a>
		);
	}
}
