class UTMStorage {
  constructor() {
    try {
      const ls = window.localStorage;
      this.setItem = ls.setItem.bind(ls);
      this.getItem = ls.getItem.bind(ls);
      this.removeItem = ls.removeItem.bind(ls);
      this.setItem("test", "test");
      this.removeItem("test");
    } catch (e) {
      this.prefixToAvoidBuiltIns = "_utm_unique_";
      this.localMockedStorage = {};
      this.setItem = this.setLocalItem;
      this.getItem = this.getLocalItem;
    }
  }

  setLocalItem(key, value) {
    this.localMockedStorage[this.prefixToAvoidBuiltIns + key] = value;
  }

  getLocalItem(key) {
    return this.localMockedStorage[this.prefixToAvoidBuiltIns + key];
  }
}

export default UTMStorage;
