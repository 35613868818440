import reactLogger from "../Component/utils/helpers/reactLogger";
import { CURRENT_JOB_POST, CURRENT_JOB_POST_UNDEF } from "./types";

export const setCurrentJobPost = (jobPost) => (dispatch) => {
  if (jobPost === null) {
    dispatch({
      type: CURRENT_JOB_POST_UNDEF,
      payload: false,
    });
  } else {
    dispatch({
      type: CURRENT_JOB_POST,
      payload: jobPost,
    });
  }
};

export const getCurrentJobPost = (jobId) => async (
  dispatch,
  getState,
  axiosInstance
) => {
  if (jobId === null) {
    dispatch({
      type: CURRENT_JOB_POST_UNDEF,
      payload: false,
    });
  } else {
    try {
      let res = await axiosInstance.post("/api/employer/getJobPostingFromId", {
        jobId,
      });
      console.log("res", res);
      dispatch({
        type: CURRENT_JOB_POST,
        payload: res.data.jobPosting,
      });
    } catch (err) {
      dispatch({
        type: CURRENT_JOB_POST_UNDEF,
        payload: false,
      });
      reactLogger(
        err,
        "currentJobPostActions",
        "employer's getpostingfromid of axios has failed"
      );
    }
  }
};
