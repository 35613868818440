import {
	CURRENT_CANDIDATE_IN_VIEW,
	CURRENT_CANDIDATE_IN_VIEW_UNDEF
} from "./types";
export const setCurrentCandidateInView = candidate => {
	return dispatch => {
		if (candidate === null) {
			dispatch({
				type: CURRENT_CANDIDATE_IN_VIEW_UNDEF,
				payload: false
			});
		} else {
			dispatch({
				type: CURRENT_CANDIDATE_IN_VIEW,
				payload: candidate
			});
		}
	};
};
