import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import SimpleLineIcon from "../utils/components/SimpleLineIcons";

export default class EmployerNavigationMenu extends Component {
  constructor(props) {
    super(props);
    const { active } = this.props;
    this.state = {
      active,
    };
  }

  componentDidMount() {
    if (window.location.pathname === "/employer/dashboard")
      this.setState({ active: 0 });
    else if (
      window.location.pathname.includes("/employer/recruit") ||
      window.location.pathname.includes("/employer/job")
    )
      this.setState({ active: 1 });
    else if (window.location.pathname.includes("/employer/employees"))
      this.setState({ active: 2 });
    else if (window.location.pathname === "/employer/messages")
      this.setState({ active: 3 });
    else if (window.location.pathname === "/employer/calendar")
      this.setState({ active: 4 });
    else if (window.location.pathname.includes("/employer/corps"))
      this.setState({ active: 5 });
    else if (window.location.pathname.includes("/employer/analytics"))
      this.setState({ active: 6 });
    else if (window.location.pathname.includes("/employer/settings"))
      this.setState({ active: -1 });
    else this.setState({ active: 0 });
  }

  renderNavigationMenu = () => {
    const { active } = this.state;
    const { messagesSeenHandler } = this.props;
    return (
      <>
        <div className="header__controlpane__navigationMenu">
          <Link
            className={
              active === 0
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            data-tooltip="Dashboard"
            data-background-color="black"
            to="/employer/dashboard"
          >
            <SimpleLineIcon
              style={{ fontSize: "inherit", fontWeight: "inherit" }}
              name="compass"
            />
          </Link>
          <Link
            className={
              active === 1
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/recruit/jobs"
            data-background-color="black"
            data-tooltip="Recruit"
          >
            <SimpleLineIcon
              style={{ fontSize: "inherit", fontWeight: "inherit" }}
              name="briefcase"
            />
          </Link>
          <Link
            className={
              active === 2
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/employees/list"
            data-background-color="black"
            data-tooltip="Employees"
          >
            <SimpleLineIcon
              style={{ fontSize: "inherit", fontWeight: "inherit" }}
              name="people"
            />
          </Link>
          <Link
            onClick={messagesSeenHandler}
            className={
              active === 3
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/messages"
            data-background-color="black"
            data-tooltip="Messages"
          >
            <SimpleLineIcon
              style={{ fontSize: "inherit", fontWeight: "inherit" }}
              name="speech"
            />
          </Link>
          <Link
            className={
              active === 4
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/calendar"
            data-background-color="black"
            data-tooltip="Calendar"
          >
            <SimpleLineIcon
              style={{ fontSize: "inherit", fontWeight: "inherit" }}
              name="calendar"
            />
          </Link>
          <Link
            className={
              active === 5
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/corps"
            data-background-color="black"
            data-tooltip="Team"
          >
            <SimpleLineIcon
              style={{ fontSize: "inherit", fontWeight: "inherit" }}
              name="organization"
            />
          </Link>
          {/* <Link
          className={
            active === 6
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/employer/clients"
          data-background-color="black"
          data-tooltip="Clients"
        >
          <SimpleLineIcon size={"large"} name="organization" />
        </Link> */}
          <Link
            className={
              active === 6
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/analytics/reports"
            data-background-color="black"
            data-tooltip="Analytics"
          >
            <SimpleLineIcon
              style={{ fontSize: "inherit", fontWeight: "inherit" }}
              name="graph"
            />
          </Link>
        </div>

        <div className="header__controlpane__footer">
          v&nbsp;
          {process.env.REACT_APP_VERSION}
        </div>
      </>
    );
  };

  renderMobileNavigationMenu = () => {
    const { active } = this.state;
    return (
      <div className="header__navigationMenu__mobile">
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/dashboard"
        >
          <div
            id="nav__dashboard"
            className={
              active === 0
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="compass" />
            <div style={{ marginLeft: "3rem" }}>Dashboard</div>
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/recruit/jobs"
        >
          <div
            id="nav__jobs"
            className={
              active === 1
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="briefcase" />
            <div style={{ marginLeft: "2rem" }}>My Job Postings</div>
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/employees/list"
        >
          <div
            id="nav__profile"
            className={
              active === 2
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="people" />
            <div style={{ marginLeft: "2rem" }}>Employees</div>
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/messages"
        >
          <div
            id="nav__profile"
            className={
              active === 3
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="speech" />
            <div style={{ marginLeft: "2rem" }}>Messages</div>
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/calendar"
        >
          <div
            id="nav__conf"
            className={
              active === 4
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="calendar" />
            <div style={{ marginLeft: "2rem" }}>My Calendar</div>
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/settings/account"
        >
          <div
            id="nav__conf"
            className={
              active === 6
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="settings" />
            <div style={{ marginLeft: "2rem" }}>Settings</div>
          </div>
        </Link>
      </div>
    );
  };

  render() {
    const { mobile } = this.props;
    if (mobile) return this.renderMobileNavigationMenu();
    return this.renderNavigationMenu();
  }
}

EmployerNavigationMenu.defaultProps = {
  active: 0,
  messagesSeenHandler: () => {},
  mobile: false,
};
EmployerNavigationMenu.propTypes = {
  active: PropTypes.number,
  mobile: PropTypes.bool,
  messagesSeenHandler: PropTypes.func,
};
