export default function getPlaceholderUrlByGender(gender) {
  switch (gender) {
    case 'Male' || 'male':
      return 'https://i.ibb.co/vJHQCjn/gender-male.png';
    case 'Female' || 'female':
      return 'https://i.ibb.co/FxsrjLN/gender-female.png';
    default:
      return 'https://i.ibb.co/QHsVKJR/gender-other.png';
  }
}
