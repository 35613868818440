import React, { Component } from "react";

export default class componentName extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer className="footer">
        <div className="footer__content">
          <div className="footer__copyright">
            ©&nbsp;Hiresome&nbsp;
            {new Date().getFullYear()}
          </div>
        </div>
      </footer>
    );
  }
}
