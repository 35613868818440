// react ssr routes
import App from "./App";
import Error404 from "./Pages/Error404";

import "./sass/landing.scss";
import Landing from "./Pages/Promos/Landing";
import AltLanding from "./Pages/Promos/AltLanding";

export default [
  {
    ...App,
    routes: [
      {
        path: "/",
        ...Landing,
        exact: true,
      },
      {
        path: "/us",
        ...AltLanding,
        exact: true,
      },

      {
        component: Error404,
      },
    ],
  },
];
