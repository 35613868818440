export const AUTO_GENERATED_OFFER_LETTER_ATTACHMENT =
  "Auto-Generated Offer Letter";
export const AUTO_GENERATED_SALARY_BREAKUP_ATTACHMENT =
  "Auto-Generated Salary Breakup";

export const USER_TYPE_SUBEMPLOYER = "subemployer";
export const USER_TYPE_EMPLOYER = "employer";
export const USER_TYPE_CANDIDATE = "user";
export const USER_TYPE_SYS = "sys";

export const EVENT_OWNER_NAME_SYSTEM = "System";

export const MEMBER_TYPE_HR_MANAGER = "Hiring Manager";
export const MEMBER_TYPE_HR_ASSOCIATE = "HR Associate";
export const MEMBER_TYPE_RECRUITER = "Recruiter";
export const MEMBER_TYPE_INTERVIEWER = "Interviewer";
export const MEMBER_TYPE_HR_VENDOR = "Vendor";
export const MEMBER_TYPE_HR_TECH_INTERVIEWER = "Tech Interviewer";

export const VCV_ANSWER_STATUS_NOT_RECORDED = "vcv_answer_status_not_recorded";
export const VCV_ANSWER_STATUS_RECORDED = "vcv_answer_status_recorded";
export const VCV_ANSWER_STATUS_SKIPPED = "vcv_answer_status_skipped";
export const VCV_ANSWER_STATUS_FAILED = "vcv_answer_status_failed";
export const VCV_ANSWER_STATUS_UPLOADED = "vcv_answer_status_uploaded";

export const READABLE_MESSAGE_LENGTH = 180;
export const HIRESOME_CHAT_COLLECTIVE_TIMESTAMP = "chatTimestamp";

export const DEFAULT_MEDIA_THUMBNAIL_URL =
  "https://img.techpowerup.org/201114/unkno.png";

export const JOB_CHAT_MEDIA_TYPE_VIDEO = "video";
export const JOB_CHAT_MEDIA_TYPE_IMAGE = "image";

export const JOB_APPLICATION_STATUS_NOT_APPLIED = "not_applied";
export const JOB_APPLICATION_STATUS_ADDED = "added";
export const JOB_APPLICATION_STATUS_APPLIED = "applied";
export const JOB_APPLICATION_STATUS_APPLYING = "applying";
export const JOB_APPLICATION_STATUS_OPENED = "opened";
export const JOB_APPLICATION_STATUS_INVITED = "invited";
export const JOB_APPLICATION_STATUS_REJECTED = "rejected";
export const JOB_APPLICATION_STATUS_SHORTLISTED = "shortlisted";
export const JOB_APPLICATION_STATUS_INTERESTED = "interested";
export const JOB_APPLICATION_STATUS_SEEN = "seen";
export const JOB_APPLICATION_STATUS_UNSEEN = "unseen";
export const JOB_APPLICATION_STATUS_HIRED = "hired";
export const JOB_APPLICATION_STATUS_ONBOARDED = "onboarded";
export const JOB_APPLICATION_STATUS_NA = "na";

export const APPLIED_JOB_MESSAGE_STATUS_WAITING = "waiting";
export const APPLIED_JOB_MESSAGE_STATUS_FAILED = "failed";
export const APPLIED_JOB_MESSAGE_STATUS_SENT = "sent";
export const APPLIED_JOB_MESSAGE_STATUS_REC = "received";

export const EVENT_DATA_POINT_JOB_CREATE = "job_create";

export const EVENT_TYPE_DIRECT_MESSAGE = "direct_message";
export const EVENT_TYPE_APPLICATION_INVITE = "application_invite";
export const EVENT_TYPE_PROFILE_SHARING = "profile_sharing";
export const EVENT_TYPE_VIDEO_CV_INVITE = "video_cv_invite";
export const EVENT_TYPE_APPLICATION_STATUS_UPDATE =
  "EVENT_TYPE_APPLICATION_STATUS_UPDATE";
export const EVENT_TYPE_APPLICATION_REF_STATUS_UPDATE =
  "EVENT_TYPE_APPLICATION_REF_STATUS_UPDATE";
export const EVENT_TYPE_LABEL_TITLE = "EVENT_TYPE_LABEL_TITLE";
export const EVENT_TYPE_FILE_UPLOAD = "file_upload";
export const EVENT_TYPE_JOB_UPDATE = "job_update";
export const EVENT_TYPE_JOB_STATUS_UPDATE = "job_status_update";
export const EVENT_TYPE_MEETING = "meeting";
export const EVENT_TYPE_CANDIDATE_PROFILE_SHARE = "candidate_share";
export const EVENT_TYPE_CANDIDATE_UPDATE = "candidate_update";
export const EVENT_TYPE_CANDIDATE_COMMENT = "candidate_comment";
export const EVENT_TYPE_CANDIDATE_STAGE_UPDATE = "candidate_stage_update";
export const EVENT_TYPE_CANDIDATE_FILES_EXPORTED = "candidate_files_exported";
export const EVENT_TYPE_SUB_USER_UPDATE = "sub_user_update";
export const EVENT_TYPE_TEAM_UPDATE = "team_update";
export const EVENT_TYPE_OFFER_LETTER = "offer_letter_event";
export const EVENT_TYPE_OFFER_LETTER_UPDATE = "offer_letter_update_event";
export const EVENT_TYPE_OFFER_LETTER_APPROVAL = "offer_letter_approval_event";
export const EVENT_TYPE_OFFER_LETTER_ACCEPTED = "offer_letter_accepted_event";
export const EVENT_TYPE_APPOINTMENT_LETTER = "appointment_letter_event";
export const EVENT_TYPE_APPOINTMENT_LETTER_UPDATE =
  "appointment_letter_update_event";

export const CANDIDATE_DOCS_EXPORT_TIME_LIMIT_IN_MS = 3 * 24 * 3600 * 1000;
export const ONE_DAY_IN_MS = 24 * 3600 * 1000;

export const EVENT_TYPES_HUMAN_READABLE_MAP = {
  direct_message: "Direct Message",
  application_invite: "Application Invite",
  profile_sharing: "Profile Sharing",
  video_cv_invite: "Video Cv Invite",
  application_status_update: "Application Status Update",
  label_title: "Label Title",
  file_upload: "File Upload",
  job_update: "Job Update",
  meeting: "Meeting",
  candidate_update: "Candidate Update",
  candidate_comment: "Candidate Comment",
  candidate_stage_update: "Candidate Stage Update",
  candidate_status_update: "Candidate Status Update",
  candidate_files_exported: "Candidate Files Exported",
  sub_user_update: "Sub User Update",
  team_update: "Team Update",
  offer_letter: "Offer Letter",
  offer_letter_update: "Offer Letter Update",
  offer_letter_approval: "Offer Letter Approval",
  offer_letter_accepted: "Offer Letter Accepted",
  appointment_letter: "Appointment Letter",
  appointment_letter_update: "Appointment Letter Update",
};

export const EVENT_STATUS_DUPLICATE = "Duplicate";
export const EVENT_STATUS_UNIQUE = "Unique";
export const EVENT_STATUS_CANCELED = "Canceled";
export const EVENT_STATUS_PROCESSING = "Processing...";

export const EVENT_LABEL_ACTIVE = "active";
export const EVENT_LABEL_ACCENT = "accent";
export const EVENT_LABEL_DANGER = "danger";
export const EVENT_LABEL_SUCCESS = "success";

export const PROGRESS_STYLE_LOAD_PROGRESS = "load_progress";
export const PROGRESS_STYLE_NO_PROGRESS = "no_progress";

export const MAX_JOB_TITLE_LIMIT = 100;

export const TOTAL_PERSONALITY_ASSESSMENT_PREF_SCORE = 10;

export const peopleImgResSrcArr = [
  "https://i.ibb.co/hsvPBYq/1.jpg",
  "https://i.ibb.co/7jhxWQm/3.jpg",
  "https://i.ibb.co/LYtKz5j/4.jpg",
  "https://i.ibb.co/f4rZ5dP/5.jpg",
  "https://i.ibb.co/tB2Td5S/6.jpg",
  "https://i.ibb.co/qxYK6CX/7.jpg",
  "https://i.ibb.co/VMNPq21/8.jpg",
  "https://i.ibb.co/yQptN0W/9.jpg",
  "https://i.ibb.co/VwdtM4y/31.jpg",
  "https://i.ibb.co/CBFZF4N/10.jpg",
  "https://i.ibb.co/X7FwG1H/11.jpg",
  "https://i.ibb.co/x3g3Z4s/12.jpg",
  "https://i.ibb.co/yBm3sQm/13.jpg",
  "https://i.ibb.co/b6t5dnb/30.jpg",
  "https://i.ibb.co/9pLGtnx/14.jpg",
  "https://i.ibb.co/nrVK1Tj/15.jpg",
  "https://i.ibb.co/W3gfFJK/16.jpg",
  "https://i.ibb.co/Fsq14hm/17.jpg",
  "https://i.ibb.co/4WsHyxy/27.jpg",
  "https://i.ibb.co/wRnDTP1/18.jpg",
  "https://i.ibb.co/b20Bz7k/19.jpg",
  "https://i.ibb.co/bHMqk0F/25.jpg",
  "https://i.ibb.co/T41LrVD/20.jpg",
  "https://i.ibb.co/nRZcCp7/21.jpg",
  "https://i.ibb.co/XCnWCL8/23.jpg",
  "https://i.ibb.co/DGMLZgY/24.jpg",
  "https://i.ibb.co/XxsVZP2/26.jpg",
  "https://i.ibb.co/7GdT2ZT/28.jpg",
  "https://i.ibb.co/b6C7QC1/29.jpg",
  "https://i.ibb.co/chNwv0W/32.jpg",
];

export const CANDIDATE_BOT_ID = "candidateBot";

export const TAB_DISCOVER = "tab_discover";
export const TAB_ALL_VIDEOCV = "tab_all_videocvs";
export const TAB_SHORTLISTED = "tab_shortlisted";
export const TAB_REJECTED = "tab_rejected";
export const TAB_ATS = "tab_ats";
export const TAB_FILES = "tab_uploads";
export const TAB_ASSESSMENT = "tab_assessments";
export const TAB_STATS = "tab_stats";
export const TAB_DEFAULT_SELECTED = TAB_ATS;

export const ERROR_MESSAGE_NO_RESULTS =
  "No results found. Press enter to add email to the list.";
export const ERROR_MESSAGE_SOMETHING_WENT_WRONG = "Something went wrong. Retry";

export const FILE_STATUS_PROCESSING_FAILED = "processing_failed";
export const FILE_STATUS_FAILED = "failed";
export const FILE_STATUS_UPLOADING = "uploading";
export const FILE_STATUS_COMPLETE = "complete";

export const TASK_STATUS_COMPLETE = "complete";
export const TASK_STATUS_FAILED = "failed";
export const TASK_STATUS_PENDING = "pending";

export const TODO_TYPE_CANDIDATE = "Candidate";
export const TODO_TYPE_JOB_POST = "Job Post";
export const TODO_TYPE_OFFER = "Offer";
export const TODO_TYPE_FEEDBACK = "Feedback";
export const TODO_TYPE_ASSESSMENT = "Assessment";
export const TODO_TYPE_COLLECT_DOCS = "Collect Documents";
export const TODO_TYPE_OTHER = "Other";

export const TODO_TYPES_LIST = [
  TODO_TYPE_CANDIDATE,
  TODO_TYPE_JOB_POST,
  TODO_TYPE_FEEDBACK,
  TODO_TYPE_OFFER,
  TODO_TYPE_ASSESSMENT,
  TODO_TYPE_COLLECT_DOCS,
  TODO_TYPE_OTHER,
];

export const EMAIL_HISTORY_CONTEXT_GLOBAL = "EMAIL_HISTORY_CONTEXT_GLOBAL";
export const EMAIL_HISTORY_CONTEXT_DIRECT_EMAIL =
  "EMAIL_HISTORY_CONTEXT_DIRECT_EMAIL";
export const EMAIL_HISTORY_CONTEXT_SHARE_PROFILE =
  "EMAIL_HISTORY_CONTEXT_SHARE_PROFILE";
export const EMAIL_HISTORY_CONTEXT_INTERVIEWER =
  "EMAIL_HISTORY_CONTEXT_INTERVIEWER";
export const EMAIL_HISTORY_CONTEXT_OFFER_LETTER =
  "EMAIL_HISTORY_CONTEXT_OFFER_LETTER";
export const EMAIL_HISTORY_SUB_CONTEXT_TO = "EMAIL_HISTORY_SUB_CONTEXT_TO";
export const EMAIL_HISTORY_SUB_CONTEXT_CC = "EMAIL_HISTORY_SUB_CONTEXT_CC";
export const EMAIL_HISTORY_SUB_CONTEXT_BCC = "EMAIL_HISTORY_SUB_CONTEXT_BCC";

export const UTMParams = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_content",
  "utm_name",
  "utm_term",
  "utm_ref_id",
  "utm_ref_code",
  "utm_ref_type",
  "utm_job_id",
  "initial_utm_source",
  "initial_utm_medium",
  "initial_utm_campaign",
  "initial_utm_content",
  "initial_utm_name",
  "initial_utm_term",
  "initial_utm_ref_id",
  "initial_utm_ref_code",
  "initial_utm_job_id",
];

export const CUSTOM_FIELD_TYPE_APPLICANT_RESPONSE =
  "CUSTOM_FIELD_TYPE_APPLICANT_RESPONSE";

export const CUSTOM_FIELD_TYPE_JOB_FORM = "CUSTOM_FIELD_TYPE_JOB_FORM";

export const JOB_POST_CARD_KEYS = "JOB_POST_CARD_KEYS";
export const CONFIG_TYPE_VISIBLE_APPLICANT_RESPONSE_FIELDS = "JOB_FIELDS";
export const CONFIG_TYPE_JOB_POST_FORM_FIELDS = "JOB_POST_FORM_FIELDS";
export const CONFIG_TYPE_EDITABLE_SYSTEM_JOB_POST_FORM_FIELDS =
  "EDITABLE_SYSTEM_JOB_POST_FORM_FIELDS";
export const CONFIG_TYPE_MANDATORY_SYSTEM_JOB_POST_FORM_FIELDS =
  "MANDATORY_SYSTEM_JOB_POST_FORM_FIELDS";
export const CONFIG_TYPE_JOB_STATUS = "JOB_STATUS";
export const CONFIG_TYPE_ATS_STAGES = "ATS_STAGES";
export const CONFIG_TYPE_OFFER_FORM = "OFFER_FORM";
export const CONFIG_TYPE_EDITABLE_SYSTEM_FIELDS = "EDITABLE_SYSTEM_FIELDS";
export const CONFIG_TYPE_MANDATORY_SYSTEM_FIELDS = "MANDATORY_SYSTEM_FIELDS";
export const CONFIG_TYPE_OFFER_FIELDS = "OFFER_FIELDS";
export const CONFIG_TYPE_ATS_PROFILE_SHARING = "ATS_PROFILE_SHARING";
export const CONFIG_TYPE_INTERVIEW_PROFILE_SHARING =
  "INTERVIEW_PROFILE_SHARING";
export const CONFIG_TYPE_CANDIDATE_FUNNEL_HEADERS = "CANDIDATE_FUNNEL_HEADERS";
export const CONFIG_TYPE_ATS_VIEW = "ATS_VIEW";
export const CONFIG_TYPE_ATS_VIEW_HIDE_DUPLICATE_UPLOADS =
  "hideDuplicateUploads";
export const CONFIG_TYPE_ATS_VIEW_DOCS_ATTACH_JOB_FORM_ONLY =
  "DOCS_ATTACH_JOB_FORM_ONLY";

export const AUTOMATION_TRIGGER_CANDIDATE_IS_ADDED = "candidateIsAdded";
export const AUTOMATION_TRIGGER_CANDIDATE_IS_MOVED_TO_NEXT_STAGE =
  "candidateIsMovedToNextStage";
export const AUTOMATION_TRIGGER_CANDIDATE_STATUS_CHANGE =
  "candidateStatusChange";
export const AUTOMATION_TRIGGER_CANDIDATE_REF_STATUS_CHANGE =
  "candidateRefStatusChange";

export const AUTOMATION_TARGET_APPLICANT = "applicant";
export const AUTOMATION_TARGET_CANDIDATE = "candidate";
export const AUTOMATION_TARGET_JOB = "job";

export const AUTOMATION_TRIGGER_JOB_IS_ADDED = "jobIsAdded";
export const AUTOMATION_TRIGGER_JOB_STATUS_CHANGE = "jobStatusChange";

export const AUTOMATION_TRIGGER_INTERVIEW_CREATED = "interviewCreated";
export const AUTOMATION_TRIGGER_INTERVIEW_UPDATED = "interviewUpdated";
export const AUTOMATION_TRIGGER_INTERVIEW_CANCELED = "interviewCanceled";

export const AUTOMATION_TRIGGER_PROFILE_SHARED = "profileShared";
export const AUTOMATION_TRIGGER_FEEDBACK_ASSIGNED = "feedbackAssigned";

export const AUTOMATION_TRIGGER_OFFER_ACCEPTED = "offerAccepted";

export const CANDIDATE_AUTOMATIONS = [
  AUTOMATION_TRIGGER_CANDIDATE_IS_ADDED,
  AUTOMATION_TRIGGER_CANDIDATE_IS_MOVED_TO_NEXT_STAGE,
  AUTOMATION_TRIGGER_CANDIDATE_STATUS_CHANGE,
  AUTOMATION_TRIGGER_CANDIDATE_REF_STATUS_CHANGE,
];
export const JOB_AUTOMATIONS = [
  AUTOMATION_TRIGGER_JOB_IS_ADDED,
  AUTOMATION_TRIGGER_JOB_STATUS_CHANGE,
];

export const WEBHOOK_TYPE_GET = "get";
export const WEBHOOK_TYPE_POST = "post";
export const WEBHOOK_TYPE_PUT = "put";
export const WEBHOOK_TYPE_DELETE = "delete";

export const SCHEDULE_STATUS_UPCOMING = "Upcoming";
export const SCHEDULE_STATUS_COMPLETED = "Completed";
export const SCHEDULE_STATUS_CANCELED = "Canceled";

export const MEET_ACCESS_TYPE_ADMIN = "admin";
export const MEET_ACCESS_TYPE_GUEST = "guest";
export const MEET_ACCESS_TYPE_ORGANIZER = "organizer";
export const MEET_ACCESS_TYPE_INTERVIEWER = "interviewer";
export const MEET_ACCESS_TYPE_CANDIDATE = "candidate";

export const MEET_USER_STATUS_INVITED = "invited";
export const MEET_USER_STATUS_LOBBY = "lobby";
export const MEET_USER_STATUS_DENIED = "denied";
export const MEET_USER_STATUS_ACCEPTED = "accepted";

export const ATS_CANDIDATE_UPLOAD_ACTION_ADD = "addCandidate";
export const ATS_CANDIDATE_UPLOAD_ACTION_BULK = "bulkCandidateUpload";

export const AUTOMATION_TYPE_GLOBAL = "global";
export const AUTOMATION_TYPE_SYSTEM = "system";
export const AUTOMATION_TYPE_SCHEDULED = "scheduled";
export const AUTOMATION_TYPE_REPORT = "report";
export const AUTOMATION_TYPE_JOB = "job";

export const AUTOMATION_REPORT_CANDIDATE_SUMMARY = "candidates_summary";
export const AUTOMATION_REPORT_CANDIDATE_FUNNEL = "candidates_funnel";
export const AUTOMATION_REPORT_APPLICANT_SUMMARY = "applicants_summary";
export const AUTOMATION_REPORT_NEW_JOBS = "new_jobs";
export const AUTOMATION_REPORT_JOB_SUMMARY = "jobs_summary";
export const AUTOMATION_REPORT_BU_SUMMARY = "bu_summary";
export const AUTOMATION_REPORT_FUNCTIONAL_AREA_SUMMARY = "fa_summary";
export const AUTOMATION_REPORT_INTERVIEWS_REPORT = "completed_interviews";
export const AUTOMATION_REPORT_UPCOMING_INTERVIEWS = "upcoming_interviews";
export const AUTOMATION_REPORT_INTERVIEWERS = "interviewers";
export const AUTOMATION_REPORT_USER_PERFORMANCE = "users_performance";
export const AUTOMATION_REPORT_USER_SUMMARY = "users_summary";
export const AUTOMATION_REPORT_ASSESSMENT = "assessments_report";
export const AUTOMATION_REPORT_UPCOMING_ONBOARD = "upcoming_onboard";
export const AUTOMATION_REPORT_RECENT_ONBOARD = "recent_onboard";
export const AUTOMATION_REPORT_TEAMS = "teams_performance";
export const AUTOMATION_REPORT_TEAM_LEADER = "team_performance";

export const AUTOMATION_REPORT_HUMAN_READABLE_MAP = {
  [AUTOMATION_REPORT_CANDIDATE_SUMMARY]: "Candidates Report",
  [AUTOMATION_REPORT_CANDIDATE_FUNNEL]: "Candidates Funnel",
  [AUTOMATION_REPORT_APPLICANT_SUMMARY]: "Applicants Report",
  [AUTOMATION_REPORT_NEW_JOBS]: "New Jobs",
  [AUTOMATION_REPORT_JOB_SUMMARY]: "Jobs Report",
  [AUTOMATION_REPORT_INTERVIEWS_REPORT]: "Interviews Report",
  [AUTOMATION_REPORT_INTERVIEWERS]: "Interviewers Performance",
  [AUTOMATION_REPORT_USER_PERFORMANCE]: "Users Performance",
  [AUTOMATION_REPORT_ASSESSMENT]: "Exams Summary",
  [AUTOMATION_REPORT_UPCOMING_ONBOARD]: "Upcoming Hires",
  [AUTOMATION_REPORT_RECENT_ONBOARD]: "Recent Hires",
  [AUTOMATION_REPORT_TEAMS]: "Teams Performance",
};

export const SCORECARD_INPUT_TYPE_HIRE_NO_HIRE = "scorecard_input_hire_nohire";
export const SCORECARD_INPUT_TYPE_SHORTLIST_REJECT =
  "scorecard_input_shortlist_reject";
export const SCORECARD_INPUT_TYPE_REJECT_REASON =
  "scorecard_input_reject_reason";
export const SCORECARD_INPUT_TYPE_TEXT = "scorecard_input_text";
export const SCORECARD_INPUT_TYPE_TEXT_WITH_RATING =
  "scorecard_input_text_rating";
export const SCORECARD_INPUT_TYPE_RATING = "scorecard_input_rating";
export const AUTOMATION_REPORT_MULTICHOICE = "scorecard_input_multi";
export const AUTOMATION_REPORT_SINGLECHOICE = "scorecard_input_single";

export const RECURRENCE_RULE_DAILY = "daily";
export const RECURRENCE_RULE_WEEKLY = "weekly";
export const RECURRENCE_RULE_MONTHLY = "monthly";

export const ACTION_EMAIL_STAKEHOLDERS = "Email Stakeholders";
export const ACTION_NOTIFY_STAKEHOLDERS = "Notify Stakeholders";
export const ACTION_EMAIL_CANDIDATE_UNLAYER = "Send Email To Candidate";
export const ACTION_WEBHOOK = "Trigger Webhook";
export const ACTION_UPDATE_STAGE = "Update Stage";
export const ACTION_UPDATE_STATUS = "Update Status";
export const ACTION_UPDATE_REFERRAL_STATUS = "Update Referral Status";
export const ACTION_UPDATE_JOB_STATUS = "Update Job Status";

export const ACTION_EMAIL_CANDIDATE = "Email Candidate"; // legacy

export const CONDITION_STAGE = "stage";
export const CONDITION_STATUS = "status";
export const CONDITION_REFERRAL_STATUS = "ref_status";

export const CONDITION_JOB_STATUS = "job_status";
export const CONDITION_JOB_REFERRAL = "job_referral";

export const CONDITION_FEEDBACK_FILLED = "feedback_filled";

export const CONDITION_GROUP_CANDIDATE = "candidate";
export const CONDITION_GROUP_JOB = "job";
export const CONDITION_GROUP_FEEDBACK = "feedback";

export const CONDITION_TYPE_IS = "is";
export const CONDITION_TYPE_ISNT = "isnt";

export const CONDITION_MATCH_OR = "or";
export const CONDITION_MATCH_AND = "and";

export const COLUMN_CREATED_AT = "createdAt";
export const COLUMN_ID = "_id";
export const COLUMN_UPDATED_AT = "updatedAt";
export const COLUMN_COUNT = "count";
export const COLUMN_LATEST_ACTIVITY = "latestActivity";
export const COLUMN_LATEST_ACTIVITY_TIMESTAMP = "latestActivityTime";

export const ANALYTICS_VIEW_STATS = "Graphical";
export const ANALYTICS_VIEW_TABLE = "Tabular";

export const ANALYTICS_VIEW_TIME_PERIOD = "Time Period";

export const JOB_ANALYTICS_COLUMN_TOTAL_INTERVIEWS = "totalInterviews";
export const JOB_ANALYTICS_COLUMN_TOTAL_CANDIDATES = "candidatesCount";
export const JOB_ANALYTICS_COLUMN_JOB_STATUS = "status";
export const JOB_ANALYTICS_COLUMN_JOB_USERS = "access";
export const JOB_ANALYTICS_COLUMN_JOB_AGE = "age";
export const JOB_ANALYTICS_COLUMN_JOB_CLOSE_REASON = "closeReason";

export const BU_ANALYTICS_COLUMN_ACTIVE_POSITIONS = "Active Positions";
export const BU_ANALYTICS_COLUMN_CLOSED_POSITIONS = "Closed positions";
export const BU_ANALYTICS_COLUMN_JOB_AGE_3_DAYS = "Job age 3 days";
export const BU_ANALYTICS_COLUMN_JOB_AGE_7_DAYS = "Job age 7 days";
export const BU_ANALYTICS_COLUMN_JOB_AGE_15_DAYS = "Job age 15 days";
export const BU_ANALYTICS_COLUMN_JOB_AGE_30_DAYS = "Job age 30 days";
export const BU_ANALYTICS_COLUMN_JOB_AGE_45_DAYS = "Job age 45 days";
export const BU_ANALYTICS_COLUMN_JOB_AGE_60_DAYS = "Job age 60 days";

export const USER_ANALYTICS_COLUMN_NAME = "name";
export const USER_ANALYTICS_COLUMN_TYPE = "subUserType";
export const USER_ANALYTICS_COLUMN_ACTIVE_JOBS = "activeJobs";
export const USER_ANALYTICS_COLUMN_CANDIDATES_COUNT = "candidatesCount";
export const USER_ANALYTICS_COLUMN_INTERVIEWS_SCHEDULED = "interviewsScheduled";
export const USER_ANALYTICS_COLUMN_INTERVIEWS_COMPLETED = "interviewsCompleted";
export const USER_ANALYTICS_COLUMN_INTERVIEWS_CANCELED = "interviewsCanceled";
export const USER_ANALYTICS_COLUMN_INTERVIEWS_OFFERS_CREATED = "offersCreated";
export const USER_ANALYTICS_COLUMN_INTERVIEWS_OFFERS_REJECTED =
  "offersRejected";
export const USER_ANALYTICS_COLUMN_INTERVIEWS_OFFERS_ACCEPTED =
  "offersAccepted";

export const INTERVIEWER_ANALYTICS_COLUMN_NAME = "name";
export const INTERVIEWER_ANALYTICS_COLUMN_EMAIL = "email";
export const INTERVIEWER_ANALYTICS_COLUMN_INTERVIEWS_SCHEDULED =
  "totalInterviews";
export const INTERVIEWER_ANALYTICS_COLUMN_INTERVIEWS_CANCELED =
  "interviewsCanceled";
export const INTERVIEWER_ANALYTICS_COLUMN_INTERVIEWS_COMPLETED =
  "interviewsCompleted";
export const INTERVIEWER_ANALYTICS_COLUMN_INTERVIEWS_TIME_SPENT = "totalTime";
export const INTERVIEWER_ANALYTICS_COLUMN_VERDICT_SHORTLISTED =
  "verdictShortlisted";
export const INTERVIEWER_ANALYTICS_COLUMN_VERDICT_REJECTED = "verdictRejected";
export const INTERVIEWER_ANALYTICS_COLUMN_FEEDBACKS_ASSIGNED =
  "feedbacksAssigned";
export const INTERVIEWER_ANALYTICS_COLUMN_FEEDBACKS_SUBMITTED =
  "feedbacksSubmitted";
export const INTERVIEWER_ANALYTICS_COLUMN_FEEDBACKS_TAT = "feedbacksAvgTAT";
export const INTERVIEWER_ANALYTICS_COLUMN_LATEST_INTERVIEW = "latestInterview";
export const INTERVIEWER_ANALYTICS_COLUMN_LATEST_INTERVIEW_TIME =
  "latestInterviewSchedule";

export const INTERVIEW_ANALYTICS_COLUMN_ADMIN = "admin";
export const INTERVIEW_ANALYTICS_COLUMN_CANDIDATE_ID = "candidateId";
export const INTERVIEW_ANALYTICS_COLUMN_CREATOR = "creator";
export const INTERVIEW_ANALYTICS_COLUMN_GUESTS = "guests";
export const INTERVIEW_ANALYTICS_COLUMN_INTERVIEW_TITLE = "title";
export const INTERVIEW_ANALYTICS_COLUMN_INTERVIEW_START = "start";
export const INTERVIEW_ANALYTICS_COLUMN_INTERVIEW_END = "end";
export const INTERVIEW_ANALYTICS_COLUMN_INTERVIEW_STATUS = "status";
export const INTERVIEW_ANALYTICS_COLUMN_INTERVIEW_VERDICT = "verdict";
export const INTERVIEW_ANALYTICS_COLUMN_INTERVIEW_CANDIDATE_NAME = "name";
export const INTERVIEW_ANALYTICS_COLUMN_INTERVIEW_JOB_TITLE = "jobTitle";
export const INTERVIEW_ANALYTICS_COLUMN_INTERVIEWERS = "interviewers";
export const INTERVIEW_ANALYTICS_COLUMN_ORGANIZERS = "organizers";

export const CANDIDATE_ANALYTICS_COLUMN_REF = "ref";
export const CANDIDATE_ANALYTICS_COLUMN_JOB_ID = "jobId";
export const CANDIDATE_ANALYTICS_COLUMN_JOB_TITLE = "jobTitle";
export const CANDIDATE_ANALYTICS_COLUMN_JOB_STATUS = "status";
export const CANDIDATE_ANALYTICS_COLUMN_JOB_STAGE = "stage";
export const CANDIDATE_ANALYTICS_COLUMN_TOTAL_APPLICATIONS =
  "totalApplications";
export const CANDIDATE_ANALYTICS_COLUMN_INTERVIEW_ID = "interviewId";
export const CANDIDATE_ANALYTICS_COLUMN_LATEST_INTERVIEW = "latestInterview";
export const CANDIDATE_ANALYTICS_COLUMN_LATEST_INTERVIEW_START =
  "latestInterviewStart";
export const CANDIDATE_ANALYTICS_COLUMN_NAME = "name";
export const CANDIDATE_ANALYTICS_COLUMN_PHONE = "phone";
export const CANDIDATE_ANALYTICS_COLUMN_EMAIL = "email";

export const CANDIDATE_UTM_MEDIUM_APPLY_BUTTON = "apply_button";
export const CANDIDATE_UTM_MEDIUM_ATS_CANDIDATE_ADD_BUTTON =
  "ATS_CANDIDATE_ADD_BUTTON";
export const CANDIDATE_UTM_MEDIUM_ATS_FILE_UPLOADER_BUTTON =
  "ATS_FILE_UPLOADER_BUTTON";
export const CANDIDATE_UTM_MEDIUM_JOB_SHARE_POPUP = "job_share_popup";
export const CANDIDATE_UTM_MEDIUM_SIGNUP_BUTTON = "signup_button";

export const CANDIDATE_UTM_MEDIUM_MAP = {
  [CANDIDATE_UTM_MEDIUM_APPLY_BUTTON]: "Career Page Apply Button",
  [CANDIDATE_UTM_MEDIUM_ATS_CANDIDATE_ADD_BUTTON]: "ATS Add Candidate Button",
  [CANDIDATE_UTM_MEDIUM_ATS_FILE_UPLOADER_BUTTON]: "ATS Bulk Upload Button",
  [CANDIDATE_UTM_MEDIUM_SIGNUP_BUTTON]: "Signup Button",
};

export const CANDIDATE_UTM_SOURCE_HEADER = "header";
export const CANDIDATE_UTM_SOURCE_ATS_CANDIDATE_ADD = "ATS_CANDIDATE_ADD";
export const CANDIDATE_UTM_SOURCE_CAREER_PAGE = "job_board";
export const CANDIDATE_UTM_SOURCE_POPULAR_JOBS = "popular_jobs";
export const CANDIDATE_UTM_SOURCE_ATS_BULK_UPLOADER = "ATS_FILE_UPLOADER";
export const CANDIDATE_UTM_SOURCE_FACEBOOK = "facebook";
export const CANDIDATE_UTM_SOURCE_LINKEDIN = "linkedin";
export const CANDIDATE_UTM_SOURCE_TWITTER = "twitter";
export const CANDIDATE_UTM_SOURCE_DIRECT_LINK = "direct_link";

export const CANDIDATE_UTM_SOURCE_MAP = {
  [CANDIDATE_UTM_SOURCE_HEADER]: "Career Page Header",
  [CANDIDATE_UTM_SOURCE_ATS_CANDIDATE_ADD]: "Add Candidate",
  [CANDIDATE_UTM_SOURCE_CAREER_PAGE]: "Career Page",
  [CANDIDATE_UTM_SOURCE_POPULAR_JOBS]: "Popular Jobs",
  [CANDIDATE_UTM_SOURCE_ATS_BULK_UPLOADER]: "ATS Bulk Upload",
  [CANDIDATE_UTM_SOURCE_FACEBOOK]: "Facebook",
  [CANDIDATE_UTM_SOURCE_LINKEDIN]: "Linkedin",
  [CANDIDATE_UTM_SOURCE_TWITTER]: "Twitter",
  [CANDIDATE_UTM_SOURCE_DIRECT_LINK]: "Direct Link",
};

export const APPLICANT_ANALYTICS_TYPE_NAME = "name";
export const APPLICANT_ANALYTICS_TYPE_JOB_TITLE = "jobTitle";
export const CANDIDATE_ANALYTICS_TYPE_STAGES = "stage";
export const CANDIDATE_ANALYTICS_TYPE_STATUS = "status";
export const CANDIDATE_ANALYTICS_TYPE_MEDIUM = "utm_medium";
export const CANDIDATE_ANALYTICS_TYPE_SOURCE = "utm_source";
export const CANDIDATE_ANALYTICS_TYPE_POOL = "pool";
export const CANDIDATE_ANALYTICS_TYPE_REFS = "utm_ref_id";
export const CANDIDATE_ANALYTICS_TYPE_REFERRAL = "utm_ref_code";

export const ANALYTICS_SORT_TYPE_NATURAL = "Natural";
export const ANALYTICS_SORT_TYPE_ASCENDING = 1;
export const ANALYTICS_SORT_TYPE_DESCENDING = -1;
export const ANALYTICS_SORT_TYPE_MAP = {
  [ANALYTICS_SORT_TYPE_ASCENDING]: "Ascending",
  [ANALYTICS_SORT_TYPE_DESCENDING]: "Descending",
};

export const JOB_POSTING_STATUS_ACTIVE = "Active";
export const JOB_POSTING_STATUS_ON_HOLD = "On Hold";
export const JOB_POSTING_STATUS_CLOSED = "Closed";

export const JOB_POSTING_FILTER_MY_JOBS = "myJobs";
export const JOB_POSTING_FILTER_RECEIVING_APPLICATIONS = "isActive";
export const JOB_POSTING_FILTER_PUBLIC = "isVisible";
export const JOB_POSTING_FILTER_FEATURED = "isPopular";

export const JOB_POSTING_SORT_CREATED_ASC = "created_asc";
export const JOB_POSTING_SORT_CREATED_DESC = "created_desc";
export const JOB_POSTING_SORT_AGE_ASC = "age_asc";
export const JOB_POSTING_SORT_AGE_DESC = "age_desc";
export const JOB_POSTING_SORT_UPDATED_ASC = "updated_asc";
export const JOB_POSTING_SORT_UPDATED_DESC = "updated_desc";
export const JOB_POSTING_SORT_TITLE_ASC = "title_asc";
export const JOB_POSTING_SORT_TITLE_DESC = "title_desc";
export const JOB_POSTING_SORT_CANDIDATE_COUNT_ASC = "cand_count_asc";
export const JOB_POSTING_SORT_CANDIDATE_COUNT_DESC = "cand_count_desc";
export const JOB_POSTING_SORT_POSITIONS_ASC = "positions_asc";
export const JOB_POSTING_SORT_POSITIONS_DESC = "positions_desc";

export const JOB_POSTING_STATUS_ARR = [
  JOB_POSTING_STATUS_ACTIVE,
  JOB_POSTING_STATUS_ON_HOLD,
  JOB_POSTING_STATUS_CLOSED,
];

export const OFFER_APPROVER_AUTHORITY_APPROVER = "Approver";
export const OFFER_APPROVER_AUTHORITY_NOTIFIED = "Notify Only";

export const OFFER_APPROVER_STATUS_ADDED = "Added";
export const OFFER_APPROVER_STATUS_INVITED = "Invited";
export const OFFER_APPROVER_STATUS_APPROVED = "Approved";
export const OFFER_APPROVER_STATUS_REJECTED = "Rejected";
export const OFFER_APPROVER_STATUS_HIRED = "Hired";

export const OFFER_LETTER_STAGE_SALARY_BRACKET = "Salary Bracket";

export const APPOINTMENT_STATUS_ONBOARDED = "Onboarded";

export const OUTLOOK_MEETING_PROVIDER_TEAMS_BUSINESS = "teamsForBusiness";
export const OUTLOOK_MEETING_PROVIDER_SKYPE_BUSINESS = "skypeForBusiness";
export const OUTLOOK_MEETING_PROVIDER_SKYPE_CONSUMER = "skypeForConsumer";

export const OFFER_LETTER_STATUS_ACCEPTED = "accepted";
export const OFFER_LETTER_STATUS_REJECTED = "rejected";
export const OFFER_LETTER_STATUS_DISAPPROVED = "disapproved";
export const OFFER_LETTER_STATUS_APPROVED = "approved";
export const OFFER_LETTER_STATUS_APPROVAL_REJECTED = "approval rejected";
export const OFFER_LETTER_STATUS_APPROVAL_OVERRIDDEN = "overridden";
export const OFFER_LETTER_STATUS_SENT = "sent";
export const OFFER_LETTER_STATUS_WAITING = "waiting";
export const OFFER_LETTER_STATUS_DRAFT = "draft";

export const USER_INPUT_RECEIVED = "USER_INPUT_RECEIVED";

export const APPLICATION_SUCCESS_TEXT_VCV =
  "Congrats\uD83C\uDF89 your VideoCV has been received! You'll be notified of the updates right here!";
export const APPLICATION_SUCCESS_TEXT =
  "Congrats\uD83C\uDF89 your application has been received! You'll be notified of the updates right here!";

export const REPLY_TEMPLATE_TYPE_JOB_CLOSE_REASON = "JOB_CLOSE_REASON";
export const REPLY_TEMPLATE_TYPE_CANDIDATE_REJECT = "CANDIDATE_REJECT";
export const REPLY_TEMPLATE_TYPE_CANDIDATE_TEMPLATES = "CANDIDATE_TEMPLATES";

export const SALARY_BRACKET_FIELD_TYPE_ABSOLUTE = "abs";
export const SALARY_BRACKET_FIELD_TYPE_TEXT_NOTE = "text_note";
export const SALARY_BRACKET_FIELD_TYPE_HEADER = "header";
export const SALARY_BRACKET_FIELD_TYPE_PERCENTAGE = "percent";
export const SALARY_BRACKET_FIELD_TYPE_FORMULA = "formula";

export const SALARY_BRACKET_VISIBLE_IF_MANUAL = "Manual";
export const SALARY_BRACKET_VISIBLE_COND_GREATER_THAN = "Greater Than";
export const SALARY_BRACKET_VISIBLE_COND_GREATER_EQUAL_THAN =
  "Greater Than or Equal To";
export const SALARY_BRACKET_VISIBLE_COND_LESSER_THAN = "Lesser Than";
export const SALARY_BRACKET_VISIBLE_COND_LESSER_EQUAL_THAN =
  "Lesser Than or Equal To";
export const SALARY_BRACKET_VISIBLE_COND_EQUAL_TO = "Equal To";

export const SALARY_BRACKET_FIELD_DISBURSEMENT_TYPE_ANNUALLY = "Annually";
export const SALARY_BRACKET_FIELD_DISBURSEMENT_TYPE_MONTHLY = "Monthly";
export const SALARY_BRACKET_FIELD_DISBURSEMENT_TYPE_QUARTERLY = "Quarterly";
export const SALARY_BRACKET_FIELD_DISBURSEMENT_TYPE_SEMI_ANNUALLY =
  "Semi Annually";
export const SALARY_BRACKET_FIELD_DISBURSEMENT_TYPE_WEEKLY = "Weekly";
export const SALARY_BRACKET_FIELD_DISBURSEMENT_TYPE_BI_WEEKLY = "Bi Weekly";
export const SALARY_BRACKET_FIELD_DISBURSEMENT_TYPE_DAILY = "Daily";
export const SALARY_BRACKET_FIELD_DISBURSEMENT_TYPE_HOURLY = "Hourly";
export const SALARY_BRACKET_FIELD_DISBURSEMENT_TYPE_ADD_NEW = "Add New";

export const SYSTEM_NOTIFICATION_GROUP_JOBS = "Jobs";
export const SYSTEM_NOTIFICATION_GROUP_CANDIDATE = "Candidates";
export const SYSTEM_NOTIFICATION_GROUP_USERS = "Users";
export const SYSTEM_NOTIFICATION_GROUP_ASSESSMENT = "Assessments";
export const SYSTEM_NOTIFICATION_GROUP_INTERVIEWS = "Interviews";
export const SYSTEM_NOTIFICATION_GROUP_OFFERS = "Offers";
export const SYSTEM_NOTIFICATION_GROUP_OTHER = "Other";

export const SYSTEM_NOTIFICATION_HOOK_CANDIDATE_ARCHIVED = "Candidate Archived";
export const SYSTEM_NOTIFICATION_HOOK_CANDIDATE_CREATE = "Candidate Created";
export const SYSTEM_NOTIFICATION_HOOK_CANDIDATE_HIRED = "Candidate Hired";
export const SYSTEM_NOTIFICATION_HOOK_CANDIDATE_REJECTED = "Candidate Rejected";
export const SYSTEM_NOTIFICATION_HOOK_CANDIDATE_STAGE_UPDATED =
  "Candidate Stage Changed";
export const SYSTEM_NOTIFICATION_HOOK_CANDIDATE_STATUS_UPDATED =
  "Candidate Status Updated";
export const SYSTEM_NOTIFICATION_HOOK_CANDIDATE_SHORTLISTED =
  "Candidate Shortlisted";
export const SYSTEM_NOTIFICATION_HOOK_CANDIDATE_UPDATED =
  "Candidate Details Updated";
export const SYSTEM_NOTIFICATION_HOOK_CANDIDATE_FILES_EXPORTED =
  "Candidate Details Files Exported";

export const SYSTEM_NOTIFICATION_HOOK_TEAM_CREATE = "Team Created";

export const SYSTEM_NOTIFICATION_HOOK_INTERVIEW_CANCELED = "Interview Canceled";
export const SYSTEM_NOTIFICATION_HOOK_INTERVIEW_CREATED = "Interview Created";
export const SYSTEM_NOTIFICATION_HOOK_INTERVIEW_UPDATED = "Interview Updated";

export const SYSTEM_NOTIFICATION_HOOK_JOB_ARCHIVED = "Job Post Archived";
export const SYSTEM_NOTIFICATION_HOOK_JOB_CREATE = "Job Post Created";
export const SYSTEM_NOTIFICATION_HOOK_JOB_EXPIRED = "Job Post Expired";
export const SYSTEM_NOTIFICATION_HOOK_JOB_EXPIRY_EXTENDED =
  "Job Post Expiry Extended";
export const SYSTEM_NOTIFICATION_HOOK_JOB_EXPIRY_WARNING =
  "Job Post Expiry Warning";
export const SYSTEM_NOTIFICATION_HOOK_JOB_FILLED = "Job Post Vacancy Filled";
export const SYSTEM_NOTIFICATION_HOOK_JOB_UPDATED = "Job Post Updated";

export const SYSTEM_NOTIFICATION_HOOK_OFFER_ARCHIVED = "Offer Letter Archived";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_CREATE = "Offer Letter Created";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_EXPIRED = "Offer Letter Expired";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_EXPIRY_EXTENDED =
  "Offer Letter Expiry Extended";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_EXPIRY_WARNING =
  "Offer Letter Expiry Warning";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_STAGE_SALARY_BRACKET =
  "Offer Letter Stage: Salary Bracket";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_UPDATE = "Offer Letter Update";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_OVERRIDE = "Offer Letter Override";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_APPROVAL_REQUESTED =
  "Offer Letter Approval Requested";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_APPROVED = "Offer Letter Approved";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_APPROVED_ALL =
  "Offer Letter Approved All";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_APPROVAL_REJECTED =
  "Offer Letter Approval Rejected";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_SENT = "Offer Letter Sent";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_ACCEPTED = "Offer Letter Accepted";
export const SYSTEM_NOTIFICATION_HOOK_OFFER_REJECTED = "Offer Letter Rejected";

export const SYSTEM_NOTIFICATION_HOOK_OFFER_STAGES = [
  SYSTEM_NOTIFICATION_HOOK_OFFER_STAGE_SALARY_BRACKET,
];
export const OFFER_STAGE_TO_HOOK = {
  [OFFER_LETTER_STAGE_SALARY_BRACKET]:
    SYSTEM_NOTIFICATION_HOOK_OFFER_STAGE_SALARY_BRACKET,
};

export const QUIZ_TYPE_QUESTION = "question";
export const QUIZ_TYPE_ASSIGNMENT = "assignment";
export const QUIZ_TYPE_CODING = "code";
export const QUIZ_TYPE_PSYCHOMETRIC = "psychometric";

export const QUESTION_TYPE_TEXT = "text";
export const QUESTION_TYPE_IMAGE = "image";
export const QUESTION_TYPE_VIDEO = "video";
export const QUESTION_TYPE_AUDIO = "audio";
export const QUESTION_TYPE_PDF = "pdf";

export const QUESTION_DIFFICULTY_VERY_EASY = "Very-Easy";
export const QUESTION_DIFFICULTY_EASY = "Easy";
export const QUESTION_DIFFICULTY_EASY_MEDIUM = "Easy-Medium";
export const QUESTION_DIFFICULTY_MEDIUM = "Medium";
export const QUESTION_DIFFICULTY_MEDIUM_HARD = "Medium-Hard";
export const QUESTION_DIFFICULTY_HARD = "Hard";

export const QUIZ_ANSWER_TYPE_TEXT = "text";
export const QUIZ_ANSWER_TYPE_SINGLE_MCQ = "single_mcq";
export const QUIZ_ANSWER_TYPE_MULTIPLE_MCQ = "multiple_mcq";
export const QUIZ_ANSWER_TYPE_BOOLEAN = "boolean";

export const QUIZ_OPTION_TYPE_TEXT = "text";
export const QUIZ_OPTION_TYPE_IMAGE = "image";
export const QUIZ_OPTION_TYPE_VIDEO = "video";
export const QUIZ_OPTION_TYPE_AUDIO = "audio";

export const JOB_POST_FORM_INPUT_TYPE_JOB_TITLE = "jobTitle";
export const JOB_POST_FORM_INPUT_TYPE_JOB_DESCRIPTION = "jobDescription";
export const JOB_POST_FORM_INPUT_TYPE_JOB_SALARY_AMOUNT = "jobSalary";
export const JOB_POST_FORM_INPUT_TYPE_JOB_TYPE = "jobType";
export const JOB_POST_FORM_INPUT_TYPE_JOB_FUNCTIONAL_AREA = "jobFunctionalArea";
export const JOB_POST_FORM_INPUT_TYPE_JOB_INDUSTRY = "jobIndustry";
export const JOB_POST_FORM_INPUT_TYPE_JOB_QUESTIONS = "jobQuestions";
export const JOB_POST_FORM_INPUT_TYPE_JOB_LOCATION = "jobLocation";
export const JOB_POST_FORM_INPUT_TYPE_JOB_NOTIFICATION_EMAIL =
  "jobNotificationEmail";
export const JOB_POST_FORM_INPUT_TYPE_JOB_CLOSING_DATE = "jobClosingDate";
export const JOB_POST_FORM_INPUT_TYPE_JOB_SCORE_PREFERENCES =
  "scorePreferences";
export const JOB_POST_FORM_INPUT_TYPE_CHECKLIST = "checklist";
export const JOB_POST_FORM_INPUT_TYPE_SCORECARDS = "scorecards";
export const JOB_POST_FORM_INPUT_TYPE_CLIENTS = "clients";
export const JOB_POST_FORM_INPUT_TYPE_APPLICANTS_RESPONSE_FIELDS = "fields";
export const JOB_POST_FORM_INPUT_TYPE_SUBUSER_ACCESS = "access";
export const JOB_POST_FORM_INPUT_TYPE_SELECT = "select";
export const JOB_POST_FORM_INPUT_TYPE_CHECKBOX = "checkbox";
export const JOB_POST_FORM_INPUT_TYPE_POSITIVE_INTEGER = "Positive Integer";
export const JOB_POST_FORM_INPUT_TYPE_INTEGER = "Integer";
export const JOB_POST_FORM_INPUT_TYPE_NEGATIVE_INTEGER = "Negative Integer";
export const JOB_POST_FORM_INPUT_TYPE_SELECT_MULTIPLE = "Select Multiple";
export const JOB_POST_FORM_INPUT_TYPE_DATE = "Date";
export const JOB_POST_FORM_INPUT_TYPE_TEXT = "Text";
export const JOB_POST_FORM_INPUT_TYPE_MONEY_AMOUNT = "Money Amount";

export const KPI_TYPE_APPLICANT = "applicant_kpi";
export const KPI_TYPE_CANDIDATE = "candidate_kpi";
export const KPI_TYPE_JOB_POSTING = "job_posting_kpi";
export const KPI_TYPE_INTERVIEWS = "interviews_kpi";
export const KPI_TYPE_INTERVIEWERS = "interviewers_kpi";
export const KPI_TYPE_USERS = "users_kpi";

export const KPI_TYPE_MAP = {
  [KPI_TYPE_APPLICANT]: "Applicants KPI",
  [KPI_TYPE_JOB_POSTING]: "Job Posts KPI",
};

export const KPI_FOCUS_FUNNEL = "Funnel";
export const KPI_FOCUS_TAT = "TAT";

export const KPI_FUNNEL_FORMAT_NUM = "num";
export const KPI_FUNNEL_FORMAT_PERC = "perc";

export const KPI_FOCUS_MAP = {
  [KPI_FOCUS_FUNNEL]: KPI_FOCUS_FUNNEL,
  [KPI_FOCUS_TAT]: KPI_FOCUS_TAT,
};

export const REFERRAL_STATUS_ALL_REFERRALS = "All Referrals";
export const REFERRAL_STATUS_IN_PROCESS = "In-Process";
export const REFERRAL_STATUS_REJECTED = "Rejected";
export const REFERRAL_STATUS_HIRED = "Hired";
export const REFERRAL_STATUS_PROBATION_PASSED = "Probation Passed";
export const REFERRAL_STATUS_PAYMENT_INITIATED = "Payment Initiated";
export const REFERRAL_STATUS_PAYMENT_IN_PROGRESS = "Payment in Progress";
export const REFERRAL_STATUS_PAYMENT_CREDITED = "Payment Credited";

export const JOB_POST_FORM_INPUT_CUSTOM_TYPES = [
  JOB_POST_FORM_INPUT_TYPE_SELECT,
  JOB_POST_FORM_INPUT_TYPE_CHECKBOX,
  JOB_POST_FORM_INPUT_TYPE_POSITIVE_INTEGER,
  JOB_POST_FORM_INPUT_TYPE_INTEGER,
  JOB_POST_FORM_INPUT_TYPE_NEGATIVE_INTEGER,
  JOB_POST_FORM_INPUT_TYPE_SELECT_MULTIPLE,
  JOB_POST_FORM_INPUT_TYPE_DATE,
  JOB_POST_FORM_INPUT_TYPE_TEXT,
  JOB_POST_FORM_INPUT_TYPE_MONEY_AMOUNT,
];

export const MEMBER_TYPES = [
  MEMBER_TYPE_HR_MANAGER,
  MEMBER_TYPE_HR_ASSOCIATE,
  MEMBER_TYPE_RECRUITER,
  MEMBER_TYPE_INTERVIEWER,
  MEMBER_TYPE_HR_VENDOR,
  MEMBER_TYPE_HR_TECH_INTERVIEWER,
];

export const REFERRAL_STATUS_LIST = [
  REFERRAL_STATUS_ALL_REFERRALS,
  REFERRAL_STATUS_IN_PROCESS,
  REFERRAL_STATUS_REJECTED,
  REFERRAL_STATUS_HIRED,
  REFERRAL_STATUS_PROBATION_PASSED,
  REFERRAL_STATUS_PAYMENT_INITIATED,
  REFERRAL_STATUS_PAYMENT_IN_PROGRESS,
  REFERRAL_STATUS_PAYMENT_CREDITED,
];
