import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Footer from "../../Component/Footer/Footer";

import CasualPopup from "../../Component/utils/components/CasualPopup";

function computeHead() {
  return (
    <Helmet>
      <title>{`${process.env.REACT_APP_WINDOW_TITLE}`}</title>
      <meta
        property="og:title"
        content={`${process.env.REACT_APP_WINDOW_TITLE}`}
      />
      <meta charSet="utf-8" />
    </Helmet>
  );
}

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAnswer: "",
    };
    this.answers = [
      "&bull;&nbsp;A Cover video is a self recorded introductory video &#40;2-4 min&#41; where candidates introduce themselves freely talking about their background, highlighting achievements, strengths, weaknesses, their passion and mission in life.\n&bull;&nbsp;It helps employers to know their candidates better, their real personality, soft skills, communication and character in addition to their academics or work history.\n&bull;&nbsp;A Cover video is the candidate&rsquo;s first &lsquo;impression&rsquo; that can really positively influence their chances of getting hired!",
      "&bull;&nbsp;A VideoCV is a candidate&rsquo;s selfie video &#40;2-4 min&#41; in response to a fresh job opening where they can talk about their interest in the new position, their relevant experience, skills and ability.\n&bull;&nbsp;It&rsquo;s a great opportunity to express why they should be considered for the role and how they can create value for the employer.\n&bull;&nbsp;It&rsquo;s an awesome chance to confidently convey all that your text CV can&rsquo;t, and win the hiring manager&rsquo;s &#40;employer&#41; trust to definitely consider you!",
      `&bull;&nbsp;Candidates can make their profile for the employers by recording their Cover video &#40;introductory video&#41; and uploading their resume &#40;if you would like to&#41;\n&bull;&nbsp;They can apply for jobs by recording their VideoCV, in addition to their Cover video, OR can even apply with their Cover video as they see best.\n&bull;&nbsp;Both VideoCV and Cover video only enables the employer to assess them better and increases their chances of being shortlisted.\n&bull;&nbsp;Record, set and go! It barely takes 5 min to become ${process.env.REACT_APP_HOST_TITLE}!`,
      `&bull;&nbsp;Yes, very much and it will always be :&#41; ${process.env.REACT_APP_HOST_TITLE}! exists to empower candidates to determine their own self-worth by expressing who they really are.\n&bull;&nbsp;Use the platform to find your dream jobs, and let&rsquo;s do it with a swag!`,
      `&bull;&nbsp;On ${process.env.REACT_APP_HOST_TITLE}!, you decide who watches your videos. By default, all profiles are on private-mode &#40;i.e. no one can view them&#41;\n&bull;&nbsp;Only when a candidate applies for a new job can the employer view their profile, Cover video and Video CV\n&bull;&nbsp;Please don&rsquo;t worry, candidates can&rsquo;t view each other&rsquo;s profiles. So please express yourself freely.\n&bull;&nbsp;Candidates decide which of their VideoCVs they want the employer to view.\n&bull;&nbsp;By a click of a button, you can also make your profile public and use its link where ever you like!`,
      "&bull;&nbsp;No one has access to your profile. Only when a candidate applies for a new job can the employer view their profile, Cover video and VideoCV.\n&bull;&nbsp;You can change, edit or delete applications at any time",
    ];
    this.questions = [
      "What is a Cover video?",
      "What is a VideoCV?",
      "How does it work?",
      "Is it free?",
      "Who&rsquo;s watching my profile or videos?",
      "Who can access my profile?",
    ];
  }

  // refer to header.js for scroll button hiding
  render() {
    const { questions, answers } = this;
    const { selectedAnswer } = this.state;
    return (
      <>
        {computeHead()}
        <header className="hero">
          <div className="hero__main heading__jumbo">
            Dream by Design.
            <br />
            Work by Choice.
          </div>
        </header>
        {/* section 1 */}
        <section className="landing__section">
          <div
            style={{ marginBottom: "3rem", marginTop: "-1rem" }}
            className="landing__title heading__primary"
          >
            Be Hired For Being Awesome! Be&nbsp;
            {process.env.REACT_APP_HOST_TITLE}
          </div>
          <div className="landing__explainer">
            <div className="landing__explainer__text">
              <div className="landing__explainer__text--content--subtitle  heading__tertiary">
                <b>Reveal the real hero &#40;and that&rsquo;s YOU!&#41;</b>
              </div>
              <div className="landing__explainer__text--content--subtitle  heading__tertiary">
                For long it&rsquo;s been a struggle to stand out in the lakhs,
                sizing yourself in lifeless words on a pretentious piece of
                paper &#40;read CV&#41;.
              </div>

              <div className="landing__explainer__text--content--subtitle  heading__tertiary">
                No more gyan on paper!
              </div>
              <div className="landing__explainer__text--content--subtitle  heading__tertiary">
                Say what needs to be said, show them your true personality -
                <b>your live, real self!</b>
              </div>
            </div>
            <div
              style={{ width: "100%", maxWidth: "100%", textAlign: "center" }}
              className="landing__explainer__visual"
            >
              <iframe
                title="intro video"
                width="80%"
                height="550"
                autoPlay
                src="https://www.youtube-nocookie.com/embed/c9pwAyhMv5o?rel=0&amp;modestbranding=1&amp;controls=1&amp;showinfo=0&amp;autoplay=1&amp;mute=1"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </section>
        {/* section 1 ends */}
        {/* section 2 */}
        <div id="landing1">
          <div className="landing__section landing__section--alt">
            <div className="landing__flexRowContainer">
              <div className="landing__flexRowContainer--item">
                <p
                  style={{ textAlign: "center", color: "#e5e5e5" }}
                  className="heading__secondary"
                >
                  {process.env.REACT_APP_HOST_TITLE}
                  &nbsp;for candidates
                </p>
                <p
                  style={{ color: "#c3c3c3" }}
                  className="heading__tertiary landing__subtitle  "
                >
                  {process.env.REACT_APP_HOST_TITLE}
                  &nbsp; is the most empowering platform that allows candidates
                  to record short video resumes for employers expressing who
                  they really are and why they stand out from the rest. It also
                  facilitates live interviews with employers.
                </p>
              </div>
              <div className="landing__flexRowContainer--item">
                <p
                  style={{ textAlign: "center", color: "#e5e5e5" }}
                  className="heading__secondary "
                >
                  {process.env.REACT_APP_HOST_TITLE}
                  &nbsp;for employers
                </p>
                <p
                  style={{ color: "#c3c3c3" }}
                  className="heading__tertiary landing__subtitle  "
                >
                  {process.env.REACT_APP_HOST_TITLE}
                  &nbsp;enables employers meet the &lsquo;real candidate&rsquo;
                  beyond their CVs through their selfie video resumes and
                  interview them through the platform video conference. Thus,
                  making hiring easy and fun!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* section 2 ends */}
        {/* section 3 */}
        <div className="landing__section">
          <div className="landing__title heading__primary">
            3 steps to become&nbsp;
            {process.env.REACT_APP_HOST_TITLE}
          </div>
          <div className="landing__flexRowContainer">
            <div className="landing__flexRowContainer--item3">
              <div className="landing__steps">
                <div className="landing__steps--step">1</div>
              </div>
              <p style={{ textAlign: "center" }} className="heading__secondary">
                Apply with VideoCV
              </p>
              <p className="heading__tertiary landing__steps--step--subtitle">
                Apply for jobs by self recording VideoCV &#40;2-4 min&#41; to
                introduce yourself. Upload your text resume too &#40;if you want
                to&#41;
              </p>
            </div>
            <div className="landing__flexRowContainer--item3">
              <div className="landing__steps">
                <div className="landing__steps--step">2</div>
              </div>
              <p style={{ textAlign: "center" }} className="heading__secondary">
                Employers VideoCall You
              </p>
              <p className="heading__tertiary landing__steps--step--subtitle">
                Schedule time with employers for a video interview on the
                {process.env.REACT_APP_HOST_TITLE}
                &nbsp;platform. Zero travelling.
              </p>
            </div>
            <div className="landing__flexRowContainer--item3">
              <div className="landing__steps">
                <div className="landing__steps--step">3</div>
              </div>
              <p style={{ textAlign: "center" }} className="heading__secondary">
                You&rsquo;re&nbsp;
                {process.env.REACT_APP_HOST_TITLE}
              </p>
              <p className="heading__tertiary landing__steps--step--subtitle ">
                Find your dream job and don&rsquo;t worry it&rsquo;s all
                confidential and one-on-one. So express freely and boldly
                without being embarrassed!
              </p>
            </div>
          </div>
        </div>
        {/* section 3 ends */}
        {/* section 4 */}
        <section className="landing__section">
          <div className="landing__title heading__primary">
            Time to Tear-apart Text Resumes
          </div>
          <div className="landing__explainerMirrored">
            <div className="landing__explainerMirrored__visual">
              <iframe
                title="vcv video"
                width="100%"
                height="100%"
                autoPlay
                src="https://www.youtube-nocookie.com/embed/BAfgHTrdHVI?rel=0&amp;modestbranding=1&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;mute=1"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="landing__explainerMirrored__text">
              <div className="landing__explainerMirrored__text--content--subtitle  heading__tertiary">
                Video resumes help employers dynamically evaluate candidates for
                their real personality &#40;besides academics!&#41;
              </div>
              <div className="landing__explainerMirrored__text--content--subtitle  heading__tertiary">
                Schedule video interviews on the&nbsp;
                {process.env.REACT_APP_HOST_TITLE}
                !&nbsp;platform from the comforts of your office or your home
                &#40;or on the go&#41; whenever you want!
              </div>
              <div className="landing__explainerMirrored__text--content--subtitle  heading__tertiary">
                <b>
                  The employer wins, the deserving candidate wins - in style!
                </b>
              </div>
            </div>
          </div>
        </section>
        {/* section 4 ends */}
        {/* section 5 */}
        <section className="landing__section">
          <div className="landing__title  heading__primary">
            Your questions answered
          </div>
          <div className="landing__questionsContainer">
            {questions.map((q, i) => (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <div
                role="button"
                tabIndex="0"
                key={q}
                onKeyDown={({ key }) => {
                  if (key === "Enter")
                    this.setState({
                      selectedAnswer: answers[i],
                    });
                }}
                onClick={() => {
                  this.setState({
                    selectedAnswer: answers[i],
                  });
                }}
                className="heading__tertiary landing__questions--question"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: q }}
              />
            ))}
          </div>
        </section>
        {/* section 5 ends */}
        {/* section 6 */}
        <div className="landing__signupPoster" />
        {/* section 6 ends */}
        {selectedAnswer && (
          <CasualPopup
            closePopup={() => {
              this.setState({ selectedAnswer: null });
            }}
            content={selectedAnswer}
          />
        )}
        <Footer />
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  auth,
});
export default {
  component: connect(mapStateToProps)(Landing),
};
