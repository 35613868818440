import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import swishMessage from "./SwishMessage";
import getJobApplyLinkWithUtmParams from "../helpers/getJobApplyLinkWithUtmParams";

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    swishMessage("success", "Copied to clipboard");
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      swishMessage("success", "Copied to clipboard");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

const getTwitterLink = ({ jobTitle, jobUrl }, utmParams) => {
  const twitterUrl = "https://twitter.com/intent/tweet?url=";
  const textParamHolder = "&text=";
  const urlParam = getJobApplyLinkWithUtmParams(jobUrl, {
    ...utmParams,
    utm_source: "twitter",
  });
  const textParam = `${jobTitle} | Apply now`;
  const finalUrl = twitterUrl + urlParam + textParamHolder + textParam;
  return encodeURI(finalUrl);
};

const getLinkedinLink = ({ jobUrl }, utmParams) => {
  const linkedinUrl = "https://www.linkedin.com/shareArticle?mini=true&url=";
  const urlParam = getJobApplyLinkWithUtmParams(jobUrl, {
    ...utmParams,
    utm_source: "linkedin",
  });
  const finalUrl = linkedinUrl + urlParam;
  return encodeURI(finalUrl);
};

const getFacebookLink = ({ jobUrl }, utmParams) => {
  const facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=";
  const urlParam = getJobApplyLinkWithUtmParams(jobUrl, {
    ...utmParams,
    utm_source: "facebook",
  });
  const finalUrl = facebookUrl + urlParam;
  return encodeURI(finalUrl);
};

const getNakedLink = ({ jobUrl }, utmParams) => {
  return getJobApplyLinkWithUtmParams(jobUrl, {
    ...utmParams,
    utm_source: "direct_link",
  });
};

function generateShortUrl(job, utmParams, setShortUrl) {
  const jobApplyLink = getNakedLink(job, utmParams);
  axios
    .post("/api/ats/v1/generate_short_application_url", {
      jobApplyLink,
    })
    .then(({ data: { applyLink } }) => {
      setShortUrl(applyLink);
      copyTextToClipboard(applyLink);
    })
    .catch((err) => {
      console.log(err);
      swishMessage("failed", "Error getting short url");
    });
}

function renderLinkedinButton(job, utmParams) {
  const { jobUrl } = job;

  return (
    <a
      target="_blank"
      href={getLinkedinLink({ jobUrl }, utmParams)}
      className="util__noStyleLink popupNotification__jobSuccess__social--link"
      rel="noreferrer"
    >
      <img
        alt="linkedin"
        src="/images/linkedinIcon.png"
        className="popupNotification__jobSuccess__social--img"
      />
    </a>
  );
}

const ReferralSocial = function ReferralSocial({ job, utmParams }) {
  const [shortUrl, setShortUrl] = useState("");

  return (
    <div className="">
      <div className="popupNotification__jobSuccess__image">
        <img
          alt="success_placeholder"
          className="popupNotification__jobSuccess__image--image"
          src="/images/success_rocket.svg"
        />
      </div>
      <div className="popupNotification__jobSuccess--title heading__tertiary">
        Generate job link with your referral id!
      </div>
      <div className="popupNotification__jobSuccess__social">
        <a
          target="_blank"
          href={getTwitterLink(job, utmParams)}
          className="util__noStyleLink popupNotification__jobSuccess__social--link"
          rel="noreferrer"
        >
          <img
            alt="twitter"
            src="/images/twitterIcon.png"
            className="popupNotification__jobSuccess__social--img"
          />
        </a>
        {renderLinkedinButton(job, utmParams)}
        <a
          target="_blank"
          href={getFacebookLink(job, utmParams)}
          className="util__noStyleLink popupNotification__jobSuccess__social--link"
          rel="noreferrer"
        >
          <img
            alt="facebook"
            src="/images/facebookIcon.png"
            className="popupNotification__jobSuccess__social--img"
          />
        </a>
        <button
          target="_blank"
          type="button"
          onClick={() => {
            generateShortUrl(job, utmParams, setShortUrl);
          }}
          className="util__noStyleLink popupNotification__jobSuccess__social--link"
        >
          <img
            alt="direct"
            src="/images/linkIcon.svg"
            className="popupNotification__jobSuccess__social--img"
          />
        </button>
      </div>
      {shortUrl && (
        <div className="popupNotification__jobSuccess__redirect">
          <input className="form__input" value={shortUrl} />
        </div>
      )}
    </div>
  );
};

ReferralSocial.propTypes = {
  job: PropTypes.objectOf(PropTypes.any),
  utmParams: PropTypes.objectOf(PropTypes.any),
};
ReferralSocial.defaultProps = {
  job: {},
  utmParams: {},
};

export default ReferralSocial;
