import { combineReducers } from "redux";
import auth from "./authReducer";
import config from "./configReducer";
import currentCandidateInView from "./currentCandidateInViewReducer";
import currentJobPost from "./currentJobPostReducer";
import isDarkMode from "./darkModeReducer";
import incomingSocketTextMessage from "./incomingMessageReducer";
import job from "./jobReducer";
import employerJobs from "./jobPostReducer";
import socket from "./socketReducer";
import chats from "./chatReducer";
import chatsMeta from "./chatsMetaReducer";
import jobHistory from "./jobHistoryReducer";
import jobHistoryMeta from "./jobHistoryMetaReducer";
import { USER_LOGOUT } from "../Action/types";

const appReducer = combineReducers({
  auth,
  chatsMeta,
  config,
  chats,
  job,
  jobHistoryMeta,
  jobHistory,
  socket,
  isDarkMode,
  incomingSocketTextMessage,
  employerJobs,
  currentJobPost,
  currentCandidateInView,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(
      {
        auth: null,
        chatsMeta: [],
        chats: [],
        config: null,
        job: null,
        jobHistoryMeta: null,
        jobHistory: [],
        socket,
        isDarkMode,
        incomingSocketTextMessage: null,
        employerJobs: [],
        currentJobPost: null,
        currentCandidateInView: null,
      },
      action
    );
  }
  return appReducer(state, action);
};
export default rootReducer;
