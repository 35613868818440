import swishMessage from "../components/SwishMessage";

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    swishMessage("error", "Could not copy to clipboard");
  }
  navigator.clipboard.writeText(text).then(
    () => {
      swishMessage("success", "Copied to clipboard");
    },
    (err) => {
      console.log(err);
      swishMessage("error", "Could not copy to clipboard");
    }
  );
}

export default copyTextToClipboard;
