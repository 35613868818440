import reactLogger from "../Component/utils/helpers/reactLogger";
import { SET_CONFIG } from "./types";

export default () => async (dispatch, getState, axiosInstance) => {
  try {
    const {
      data: { config },
    } = await axiosInstance.get("/api/config/v1/get_all_config");
    dispatch({
      type: SET_CONFIG,
      payload: config,
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch({
        type: SET_CONFIG,
        payload: false,
      });
    } else {
      reactLogger(err, "ConfigActions", "Failed to fetch all config actions");
    }
  }
};
