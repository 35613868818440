import React from "react";
import PropTypes from "prop-types";

const fontSize = (size) => {
  if (size.toLowerCase() === "large") {
    return "2rem";
  }
  if (size === "medium") {
    return "1.2rem";
  }
  if (size.toLowerCase() === "small") {
    return ".8rem";
  }
  return "1.4rem";
};

const SimpleLineIcon = function ({
  className,
  name,
  color,
  size,
  style,
  ...rest
}) {
  return (
    <div
      className={`icon-${name} ${className}`}
      style={{
        color,
        fontSize: fontSize(size),
        ...style,
      }}
      {...rest}
    />
  );
};

SimpleLineIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.oneOf([
    "Large",
    "large",
    "Medium",
    "medium",
    "Small",
    "small",
  ]),
  style: PropTypes.object,
};

SimpleLineIcon.defaultProps = {
  className: "",
  color: null,
  size: "Medium",
  style: {},
};

export default SimpleLineIcon;
