import camelCaseToSnakeCase from "./camelCaseToSnakeCase";

export default (jobUrl, utmParams) => {
  if (jobUrl) {
    let url = `${process.env.REACT_APP_HOST}/apply/${jobUrl}`;
    if (utmParams && typeof utmParams === "object") {
      const utmParamObjectKeys = Object.keys(utmParams);
      utmParamObjectKeys.forEach((param, i) => {
        if (i === 0) url += "?";

        url += `${camelCaseToSnakeCase(param)}=${utmParams[param]}`;

        if (i !== utmParamObjectKeys.length - 1) url += "&";
      });
    }
    console.log({ url });
    return url;
  }
  return process.env.REACT_APP_HOST;
};
