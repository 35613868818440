import React, { Component } from "react";

export default class PopupNotification extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	removePopup = () => {
		setTimeout(() => {
			this.props.popupAutoCloseHandler();
		}, 4000);
	};

	render() {
		if (this.props.success)
			return (
				<div className={"popupNotification popupNotification__success"}>
					<div className="popupNotification__image">
						<svg
							version="1.1"
							id="Layer_1"
							xmlns="http://www.w3.org/2000/svg"
							x="0px"
							y="0px"
							viewBox="0 0 98.5 98.5"
							enable-background="new 0 0 98.5 98.5"
						>
							<path
								className="popupNotification__image--image"
								fill="none"
								strokeWidth="8"
								strokeMiterLimit="10"
								stroke="#3fe2cf"
								d="M81.7,17.8C73.5,9.3,62,4,49.2,4
	C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"
							/>
						</svg>
					</div>
					<div className="popupNotification--title heading__tertiary">
						Awesome!
					</div>
					<div className="popupNotification--subtitle">
						{this.props.popupMessage}
					</div>
					{this.removePopup()}
				</div>
			);
		else
			return (
				<div className={"popupNotification popupNotification__failed"}>
					<div className="popupNotification__image">
						<svg
							id="Layer_1"
							data-name="Layer 1"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 550 550"
						>
							<title>as</title>
							<path
								d="M308.25,84.25a224.29,224.29,0,1,1-87.58,17.68,223.62,223.62,0,0,1,87.58-17.68m0-8c-128.68,0-233,104.32-233,233s104.32,233,233,233,233-104.32,233-233-104.32-233-233-233Z"
								strokeWidth="18"
								strokeMiterLimit="10"
								fill="none"
								stroke="#fb8585"
							/>
							<line
								className="popupNotification__image--image"
								x1="388.37"
								y1="140.17"
								x2="217.63"
								y2="465.83"
								fill="none"
								stroke="#fb8585"
								strokeMiterLimit="10"
								strokeWidth="28"
							/>
							<line
								className="popupNotification__image--image"
								x1="394.92"
								y1="462.22"
								x2="211.08"
								y2="143.78"
								fill="none"
								stroke="#fb8585"
								strokeMiterLimit="10"
								strokeWidth="28"
							/>
						</svg>
					</div>
					<div className="popupNotification--title heading__tertiary">
						Failed!
					</div>
					<div className="popupNotification--subtitle">
						{this.props.popupMessage}
					</div>
					{this.removePopup()}
				</div>
			);
	}
}
