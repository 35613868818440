import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class DropDownNotificationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getNotificationClickLink = () => {
    if (this.props.notification.notificationRelevantLink) {
      return (
        <a className="util__noStyleLink" href={this.props.notification.notificationRelevantLink}>
          <div className={'dropDownNotifications__notification__container'}>
            <div className={'dropDownNotifications__notification'}>
              {this.props.notification.notificationText}
            </div>
            <div className="dropDownNotifications__notification__date">
              {new Date(this.props.notification.timeStamp).toLocaleDateString()}
            </div>
          </div>
        </a>
      );
    } else {
      return (
        <Link
          className="util__noStyleLink"
          to={{
            pathname: `/employer/job/${this.props.notification.notificationRelevantJobId}`
          }}
        >
          <div className={'dropDownNotifications__notification__container'}>
            <div className={'dropDownNotifications__notification'}>
              {this.props.notification.notificationText}
            </div>
            <div className="dropDownNotifications__notification__date">
              {new Date(this.props.notification.timeStamp).toLocaleDateString()}
            </div>
          </div>
        </Link>
      );
    }
  };
  render() {
    if (this.props.notification.notificationType === 'jobNotification')
      return this.getNotificationClickLink();
    else if (this.props.notification.notificationType === 'hiresomeNotification')
      return (
        <Link
          className="util__noStyleLink"
          to={{
            pathname: `/employer/job/${this.props.notification.notificationRelevantLink}`
          }}
        >
          <div className={'dropDownNotifications__notification__container'}>
            <div className={'dropDownNotifications__notification'}>
              {this.props.notification.notificationText}
            </div>
            <div className="dropDownNotifications__notification__date">
              {new Date(this.props.notification.timeStamp).toLocaleDateString()}
            </div>
          </div>
        </Link>
      );
    else
      return (
        <div className={'dropDownNotifications__notification__active'}>
          <div className="dropDownNotifications__notification__info">
            <div className="dropDownNotifications__notification__info__text">
              <div className="dropDownNotifications__notification__info__text--message">
                {this.props.notification.notificationText}
              </div>
            </div>
          </div>
          <div className="dropDownNotifications__notification__date">
            {new Date(this.props.notification.timeStamp).toLocaleDateString()}
          </div>
        </div>
      );
  }
}

export default DropDownNotificationComponent;
