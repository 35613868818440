import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import SimpleLineIcon from "../utils/components/SimpleLineIcons";
import isValidEmail from "../utils/helpers/isValidEmail";
import copyTextToClipboard from "../utils/helpers/copyToClipboard";
import reactLogger from "../utils/helpers/reactLogger";
import ReferralSocial from "../utils/components/ReferralSocial";

class ReferPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codeCopied: false,
      email: "",
      errorMessage: null,
      job: null,
      refCode: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.getJobId();
  }

  componentDidUpdate() {
    this.getJobId();
  }

  getJobId = () => {
    const { job, loading } = this.state;
    const splitter = window.location.pathname.split("/");
    if (splitter.length > 2) {
      const jobUrl = splitter[2];
      try {
        let jobId = "";
        for (let i = jobUrl.length - 1; i >= 0; i -= 1) {
          if (jobUrl.charAt(i) === "-") {
            jobId = jobUrl.slice(i + 1, jobUrl.length);
            break;
          } else if (i === 0) {
            jobId = jobUrl.slice(i, jobUrl.length);
            break;
          }
        }
        let compareJobId = "";
        if (job && job._id) {
          compareJobId = job._id.toString();
        }
        if (jobId !== compareJobId && !loading) {
          this.setState({ loading: true }, async () => {
            const res = await axios.get(`/api/jobs/v1/get_job?jobId=${jobId}`);
            this.setState({ job: res.data.jobPosting, loading: false });
          });
        }
      } catch (err) {
        console.log("err", err);
        reactLogger(err, "ApplyForJobPosting", "something has failed");
        this.setState({ job: false, loading: false });
      }
    }
  };

  createReferee = () => {
    const { email } = this.state;
    if (isValidEmail(email)) {
      this.setState({ errorMessage: "", loading: true }, async () => {
        try {
          const {
            data: { refCode },
          } = await axios.post("/api/referee/v1/create_referee", { email });
          this.setState({ refCode, loading: false });
        } catch (err) {
          this.setState({
            errorMessage: "Something went wrong. Retry.",
            loading: false,
          });
        }
      });
    } else
      this.setState({
        errorMessage: "Please enter a valid Email Id",
        loading: false,
      });
  };

  closePopup = () => {
    const { closePopup } = this.props;
    if (this.loginPopupChild) {
      this.loginPopupChild.classList.remove("anim__appearFromAboveLoginPopup");
      this.loginPopupChild.classList.add("anim__disappearFromAboveLoginPopup");
    }
    setTimeout(() => {
      if (this.loginPopupChild)
        this.loginPopupChild.classList.remove(
          "anim__disappearFromAboveLoginPopup"
        );
      closePopup();
    }, 730);
  };

  getSubmitClassName = () => {
    const { email } = this.state;
    if (isValidEmail(email)) {
      return "btn btn__wide btn__success";
    }
    return "btn btn__wide btn__successBorders";
  };

  renderPopup = () => {
    const { codeCopied, email, errorMessage, job, loading, refCode } =
      this.state;
    if (refCode) {
      return (
        <>
          <div className="referPopup__container__backdrop" />
          <div
            id="referPopupContainer"
            ref={(ref) => {
              this.loginPopupChild = ref;
            }}
            className="referPopup__container referPopup__container--expanded"
          >
            <div
              role="button"
              tabIndex={0}
              onKeyDown={({ key }) => {
                if (key === "Enter" || key === "Space") {
                  this.closePopup();
                }
              }}
              onClick={() => {
                this.closePopup();
              }}
              id="referPopupButtonClose"
              className="referPopup__button referPopup__button--close"
            >
              <SimpleLineIcon name="close" color="red" />
            </div>
            <div className="referPopup__code__container">
              <div className="referPopup__code__label">
                {codeCopied ? (
                  <p>Your referral code is copied to clipboard!</p>
                ) : (
                  <span>Your Referral Code</span>
                )}
                {!codeCopied && (
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (key === "Enter" || key === "Space") {
                        this.setState({ codeCopied: true });
                        copyTextToClipboard(refCode);
                      }
                    }}
                    onClick={() => {
                      this.setState({ codeCopied: true });
                      copyTextToClipboard(refCode);
                    }}
                  >
                    Copy&nbsp;
                    <SimpleLineIcon name="docs" title="copy" />
                  </div>
                )}
              </div>
              <div className="referPopup__code">{refCode}</div>
            </div>
            <div className="referPopup__social">
              <ReferralSocial
                job={job}
                utmParams={{ utm_ref_code: refCode, utm_job_id: job._id }}
              />
            </div>
            {loading && <div className="referPopup__loader" />}
          </div>
        </>
      );
    }
    return (
      <>
        <div className="referPopup__container__backdrop" />
        <div
          id="referPopupContainer"
          ref={(ref) => {
            this.loginPopupChild = ref;
          }}
          className="referPopup__container"
        >
          <div
            role="button"
            tabIndex={0}
            onKeyDown={({ key }) => {
              if (key === "Enter" || key === "Space") {
                this.closePopup();
              }
            }}
            onClick={() => {
              this.closePopup();
            }}
            id="referPopupButtonClose"
            className="referPopup__button referPopup__button--close"
          >
            <SimpleLineIcon name="close" color="red" />
          </div>
          <div className="referPopup--promo__container">
            <img
              className="referPopup--promo"
              alt="promo"
              src="https://i.imgur.com/Xqz2lCK.png"
            />
          </div>
          <div className="referPopup__form">
            <div className="input__control form__group">
              <input
                id="refEmailInput"
                className="input input--active util__wide"
                type="email"
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    this.handleLogin();
                  }
                }}
                name="email"
                placeholder="Email"
                value={email}
                onChange={({ target: { value } }) => {
                  this.setState({ email: value });
                }}
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="input__label" htmlFor="refEmailInput">
                Email
              </label>
            </div>
            <div className="referPopup__actions">
              {errorMessage && (
                <p className="util__errorMessage util__errorMessage--form">
                  {errorMessage}
                </p>
              )}
              <button
                type="button"
                onClick={this.createReferee}
                className={this.getSubmitClassName()}
              >
                Get Referral Code
              </button>
            </div>
          </div>
          {loading && <div className="referPopup__loader" />}
        </div>
      </>
    );
  };

  render() {
    const { job, loading } = this.state;
    if (job) {
      return this.renderPopup();
    }
    if (job === null || loading) {
      return (
        <>
          <div className="referPopup__container__backdrop" />
          <div
            id="referPopupContainer"
            ref={(ref) => {
              this.loginPopupChild = ref;
            }}
            className="referPopup__container appearFromAboveLoginPopup"
          >
            <div
              role="button"
              tabIndex={0}
              onKeyDown={({ key }) => {
                if (key === "Enter" || key === "Space") {
                  this.closePopup();
                }
              }}
              onClick={() => {
                this.closePopup();
              }}
              id="referPopupButtonClose"
              className="referPopup__button referPopup__button--close"
            >
              <SimpleLineIcon name="close" color="red" />
            </div>
            <div className="referPopup__loader" />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="referPopup__container__backdrop" />
        <div
          id="referPopupContainer"
          ref={(ref) => {
            this.loginPopupChild = ref;
          }}
          className="referPopup__container referPopup__container--expanded"
        >
          <div
            role="button"
            tabIndex={0}
            onKeyDown={({ key }) => {
              if (key === "Enter" || key === "Space") {
                this.closePopup();
              }
            }}
            onClick={() => {
              this.closePopup();
            }}
            id="referPopupButtonClose"
            className="referPopup__button referPopup__button--close"
          >
            <SimpleLineIcon name="close" color="red" />
          </div>

          <p className=" util__errorMessage util__errorMessage--form util__textAlign--center">
            There was a problem fetching job details. Retry!
          </p>
        </div>
      </>
    );
  }
}

ReferPopup.defaultProps = {
  closePopup: () => {},
};
ReferPopup.propTypes = {
  closePopup: PropTypes.func,
};

export default ReferPopup;
