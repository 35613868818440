import "url-polyfill";
import UTMStorage from "./utmStorage";
import { UTMParams } from "../../../../../../config/const";

function checkIfInitialParamsExist(params) {
  return Object.keys(params).find((k) => k.includes("initial"));
}

const storage = new UTMStorage();

class UTM {
  /**
   * Get utm params allowed by GA
   *
   * @return {Object}
   */
  static parse() {
    const urlSearch = new URL(window.location);
    const urlParams = new URLSearchParams(urlSearch.search);
    const parsedParams = {};
    UTMParams.forEach((key) => {
      const paramValue = urlParams.get(key);
      if (paramValue) {
        parsedParams[key] = paramValue;
      }
    });
    return parsedParams;
  }

  /**
   * Save UTM params in localStorage
   *
   * @param {Object} params
   * @return {Boolean}
   */
  static save(params) {
    if (!params || !UTMParams.some((key) => !!params[key])) {
      return false;
    }
    try {
      const paramsToSave = {};
      const initialParams = {};

      Object.assign(paramsToSave, params);

      if (storage.getItem("utmSavedParams")) {
        let existingParams = {};

        try {
          existingParams = JSON.parse(storage.getItem("utmSavedParams"));
        } catch (e) {
          existingParams = {};
        }

        if (checkIfInitialParamsExist(existingParams)) {
          Object.keys(existingParams).forEach((k) => {
            if (k.includes("initial_")) {
              initialParams[k] = existingParams[k];
            }
          });
        }
      } else {
        Object.keys(paramsToSave).forEach((k) => {
          initialParams[`initial_${k}`] = paramsToSave[k];
        });
      }

      Object.assign(paramsToSave, initialParams);

      storage.setItem("utmSavedParams", JSON.stringify(paramsToSave));
      return true;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Reads UTM params from localStorage
   *
   * @return {Object}
   */
  static get() {
    const savedParams = storage.getItem("utmSavedParams");
    if (savedParams) {
      return JSON.parse(savedParams);
    }
    return null;
  }
}

export default UTM;
