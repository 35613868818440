import React from "react";

const ButtonLoader = props => {
	return (
		<div
			style={{ width: props.size, height: props.size }}
			id="headerButtonLoader"
			className="util__buttonLoader"
		/>
	);
};
export default ButtonLoader;
