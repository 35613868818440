import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import reactLogger from "../utils/helpers/reactLogger";
import { MANAGE_JOBS, SEND_MESSAGES } from "../utils/helpers/memberPermissions";
import SimpleLineIcon from "../utils/components/SimpleLineIcons";
import swishMessage from "../utils/components/SwishMessage";
import hasPermissions from "../utils/helpers/hasPermissions";
import { USER_TYPE_SUBEMPLOYER } from "../../../../config/const";

export default class SubEmployerNavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      canRenderClient: false,
      profilePictureFile: null,
      profilePictureUrl: "",
    };
  }

  componentDidMount() {
    if (window.location.pathname === "/employer/dashboard")
      this.setState({ active: 0 });
    else if (
      window.location.pathname.includes("/employer/recruit") ||
      window.location.pathname.includes("/employer/job")
    )
      this.setState({ active: 1 });
    else if (window.location.pathname === "/employer/messages")
      this.setState({ active: 2 });
    else if (window.location.pathname === "/employer/calendar")
      this.setState({ active: 3 });
    else if (window.location.pathname.includes("/employer/corps"))
      this.setState({ active: 4 });
    else if (window.location.pathname === "/employer/clients")
      this.setState({ active: 5 });
    else if (window.location.pathname.includes("/employer/analytics"))
      this.setState({ active: 6 });
    else if (window.location.pathname.includes("/employer/settings"))
      this.setState({ active: -1 });
    else this.setState({ active: 0 });
    //
    this.setState({
      canRenderClient: true,
      profilePictureUrl: this.props.profilePictureUrl,
    });
  }

  fileSelectEvent = async (file) => {
    this.setState({ profilePictureFile: file });
    try {
      const url = "/api/usersettings/changepicture";
      const res = await axios.get(url);
      if (res) {
        if (res.data && res.data.s3SignedImageUploadUrl) {
          this.uploadImageFile(res.data.s3SignedImageUploadUrl);
        } else {
          throw new Error("Something went wrong");
        }
      }
    } catch (err) {
      reactLogger(
        err,
        "SubEmployerNavigationMenu",
        "Getting URL has failed",
        "profile_picutre_upload",
        true,
        "Profile picture file select failed"
      );
    }
  };

  onCameraError = (error) => {
    console.log(error);
    this.setState({
      showCamera: false,
    });
    swishMessage("danger", "Camera access failed");
  };

  onTakePhoto = (dataUri) => {
    const arr = dataUri.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], { type: mime });

    this.fileSelectEvent(blob);
    setTimeout(() => {
      this.setState({ showCamera: false });
    }, 1500);
  };

  uploadImageFile(signedUrl) {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedUrl, true);
    xhr.setRequestHeader("Content-Type", "image");
    xhr.onload = async () => {
      if (xhr.status === 200) {
        this.setState({
          profilePictureFile: null,
        });
        swishMessage("success", "Profile picture updated successfully");
        this.profilePicturePreview.style.backgroundImage = `url("${
          this.state.profilePictureUrl
        }?${new Date().getTime()}"), url("https://image.ibb.co/mcXULA/dum.png")`;
      } else {
        swishMessage("danger", "Profile picture update failed");
      }
    };
    xhr.onerror = () => {
      swishMessage("danger", "Profile picture update failed");
    };
    xhr.send(this.state.profilePictureFile);
  }

  renderNavigationMenu = () => {
    const { active, canRenderClient } = this.state;
    const { permissions } = this.props;
    return (
      <>
        <div className="header__controlpane__navigationMenu">
          <Link
            data-tooltip="Dashboard"
            data-background-color="black"
            className={
              active === 0
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/dashboard"
          >
            <SimpleLineIcon name="layers" />
          </Link>
          <Link
            data-tooltip="Recruit"
            data-background-color="black"
            className={
              active === 1
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/recruit/jobs"
          >
            <SimpleLineIcon name="briefcase" />
          </Link>
          {hasPermissions(
            permissions,
            [SEND_MESSAGES],
            USER_TYPE_SUBEMPLOYER
          ) && (
            <Link
              data-tooltip="Messages"
              data-background-color="black"
              className={
                active === 2
                  ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                  : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
              }
              to="/employer/messages"
            >
              <SimpleLineIcon name="speech" />
            </Link>
          )}
          <Link
            data-tooltip="Calendar"
            data-background-color="black"
            className={
              active === 3
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/calendar"
          >
            <SimpleLineIcon name="calendar" />
          </Link>
          <Link
            data-tooltip="Teams"
            data-background-color="black"
            className={
              active === 4
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/corps"
          >
            <SimpleLineIcon name="people" />
          </Link>
          <Link
            data-tooltip="Analytics"
            data-background-color="black"
            className={
              active === 6
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/analytics/reports"
          >
            <SimpleLineIcon name="graph" />
          </Link>
        </div>
        <div className="header__controlpane__footer">
          v&nbsp;
          {process.env.REACT_APP_VERSION}
        </div>
      </>
    );
  };

  renderMobileNavigationMenu = () => {
    return (
      <div className="header__navigationMenu__mobile">
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/dashboard"
        >
          <div
            id="nav__dashboard"
            className={
              this.state.active === 0
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            Dashboard
          </div>
        </Link>
        {this.renderPermittedMenuItem(MANAGE_JOBS) && (
          <Link
            className="header__navigationMenu__mobile--item util__noStyleLink"
            to="/employer/recruit/jobs"
          >
            <div
              id="nav__jobs"
              className={
                this.state.active === 1
                  ? "header__navigationMenu__mobile--item--active"
                  : "header__navigationMenu__mobile--item--inactive"
              }
            >
              My Job Postings
            </div>
          </Link>
        )}

        {/* <Link
        
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/calendar"
        >
          <div
            id="nav__conf"
            className={
              this.state.active === 3
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            My Calendar
          </div>
        </Link>
        <Link
        
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/subemployer/profile"
        >
          <div
            id="nav__conf"
            className={
              this.state.active === 4
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            My Profile
          </div>
        </Link>
        <Link
        
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/settings/account"
        >
          <div
            id="nav__conf"
            className={
              this.state.active === 6
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            Settings
          </div>
        </Link> */}
      </div>
    );
  };

  render() {
    const { mobile } = this.props;
    if (mobile) this.renderMobileNavigationMenu();
    return this.renderNavigationMenu();
  }
}
