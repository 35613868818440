import {
  ADD_NEW_CHAT,
  GET_CHATS,
  REFETCH_CHATS,
  UPDATE_CHATS,
} from "../Action/types";

// eslint-disable-next-line default-param-last
export default (state = null, action) => {
  switch (action.type) {
    case ADD_NEW_CHAT:
      if (state.findIndex((chat) => chat._id === action.payload._id) < 0) {
        state.push(action.payload);
      }
      return state;
    case UPDATE_CHATS:
      return action.payload;
    case GET_CHATS:
      if (Array.isArray(action.payload)) {
        if (Array.isArray(state)) {
          return [...state, ...action.payload.reverse()];
        }
        return action.payload;
      }
      if (Array.isArray(state)) {
        return state;
      }
      return action.payload;
    case REFETCH_CHATS:
      if (state === false) return null;
      return state;
    default:
      return state;
  }
};
