import React, { Component } from "react";
import { connect } from "react-redux";
import { renderRoutes } from "react-router-config";
import axios from "axios";
import PropTypes from "prop-types";
import io from "socket.io-client";
import * as actions from "./Action";
import { fetchUser } from "./Action/userAuthActions";
import fetchConfig from "./Action/configActions";
import reactLogger from "./Component/utils/helpers/reactLogger";
import mobileBrowserAddressBarSizeHandler from "./Component/utils/helpers/mobileBrowserAddressBarSizeHandler";
import UTM from "./Component/utils/vendors/UTM/UTM";

function employerUpdateMessageStatus(
  messageStatus,
  messageId,
  jobId,
  contactId
) {
  try {
    axios.post("/api/chat/v1/employer/message_status", {
      jobId,
      messageStatus,
      messageId,
      candidateId: contactId,
    });
  } catch (err) {
    console.log(err);
    reactLogger(err, "App.js", "failed to set message status");
  }
}

function candidateUpdateMessageStatus(
  messageStatus,
  messageId,
  conversationId,
  employerId
) {
  try {
    axios.post("/api/chat/v1/candidate/message_status", {
      conversationId,
      messageStatus,
      messageId,
      employerId,
    });
  } catch (err) {
    console.log(err);
    reactLogger(err, "App.js", "failed to set message status");
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socket: null,
    };
  }

  componentDidMount() {
    const { auth, config } = this.props;
    const { socket } = this.state;
    try {
      UTM.save(UTM.parse());
    } catch (err) {
      reactLogger(err, "App.js", "Error saving UTM");
    }
    if (auth === null) {
      fetchUser();
    }
    if (config === null) {
      fetchConfig();
    } else if (auth) {
      const { message } = auth;
      if (message) {
        if (message !== "signup success") {
          fetchUser();
        }
      }
      if (auth && !socket) {
        this.connectSocket();
      }
    }
    mobileBrowserAddressBarSizeHandler();
    // setDarkMode();
  }

  componentDidUpdate(prevProps) {
    const { auth, location } = this.props;
    const { location: prevLocation } = prevProps;
    const { socket } = this.state;

    if (auth && !socket) {
      this.connectSocket();
    }
    if (location !== prevLocation) {
      UTM.save(UTM.parse());
    }
  }

  componentDidCatch(err) {
    reactLogger(err, "App", "Component has caught an error");
  }

  connectSocket = () => {
    const socket = io(process.env.REACT_APP_HOST);
    const {
      auth,
      notifyOnIncomingSocketMessage,
      setSocket,
      updateChatsOnMessageReceived,
      updateUserOnMessageStatusReceived,
    } = this.props;
    const { _id, type } = auth;
    socket.on("connect", () => {
      console.log("connected to socket");
      setSocket(socket);
      socket.emit("room", {
        room: _id,
        type,
      });
    });
    socket.on("connect_error", (err) => {
      console.log("connect error", err);
    });
    socket.on("connect_timeout", () => {
      console.log("connection timed out");
    });
    socket.on("reconnect", (num) => {
      console.log("reconnecting try:", num);
    });
    socket.on("chatMessage", (socketMessage) => {
      console.log("received socket message");
      console.log({ socketMessage });
      updateChatsOnMessageReceived(
        socketMessage.message,
        socketMessage.conversationId,
        auth.type
      );
      notifyOnIncomingSocketMessage(socketMessage);
      if (type === "user") {
        candidateUpdateMessageStatus(
          "received",
          socketMessage.message._id.toString(),
          socketMessage.conversationId,
          socketMessage.message.senderId
        );
      } else {
        employerUpdateMessageStatus(
          "received",
          socketMessage.message._id.toString(),
          socketMessage.jobId,
          socketMessage.sender
        );
      }
    });
    socket.on("messageStatus", (socketMessage) => {
      console.log("receivedChatMessageStatus", socketMessage);
      // updateUserOnMessageStatusReceived(
      //   socketMessage.message_id,
      //   socketMessage.conversation_id,
      //   socketMessage.updatedStatus,
      //   auth
      // );
    });
    this.setState({ socket });
  };

  render() {
    const { route } = this.props;
    if (route) {
      const { routes } = route;
      if (routes)
        return (
          <>
            <div id="swishContainer" />
            {renderRoutes(routes)}
          </>
        );
    }
    return null;
  }
}
App.defaultProps = {
  auth: null,
  config: null,
  location: {},
  notifyOnIncomingSocketMessage: () => {},
  setSocket: () => {},
  route: {},
  updateChatsOnMessageReceived: () => {},
  updateUserOnMessageStatusReceived: () => {},
};
App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  auth: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  config: PropTypes.any,
  location: PropTypes.objectOf(PropTypes.any),
  notifyOnIncomingSocketMessage: PropTypes.func,
  setSocket: PropTypes.func,
  route: PropTypes.objectOf(PropTypes.any),
  updateChatsOnMessageReceived: PropTypes.func,
  updateUserOnMessageStatusReceived: PropTypes.func,
};

function mapStateToProps({ auth, config, socket }) {
  return { auth, config, socket };
}

export default {
  component: connect(mapStateToProps, actions)(App),
  loadData: (store) => {
    return [store.dispatch(fetchUser()), store.dispatch(fetchConfig())];
  },
};
