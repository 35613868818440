import { DARK_MODE_SETTING } from "../Action/types";

export default (state = null, action) => {

	switch (action.type) {

	case DARK_MODE_SETTING:
		if (!action.payload) {

			return false;

		}
		return action.payload;
	default:
		return state;

	}

};
