import React, { Component } from "react";
import { connect } from "react-redux";
import { signup } from "../../../Action/index";
import PasswordInput from "../../utils/components/PasswordInput";

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: "",
      passwordValue: "",
      nameValue: "",
    };
  }
  // getCountryCode = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position, err) => {
  //       if (err) {
  //         console.log(err);
  //       } else {
  //         console.log('position', position);
  //       }
  //     });
  //   }
  // };

  handleSignup = (e) => {
    const user = {
      email: this.state.emailValue,
      password: this.state.passwordValue,
      name: this.state.nameValue,
    };

    const emailCheck = performEmailCheck(this.state.emailValue);
    const passwordCheck = performPasswordCheck(this.state.passwordValue);
    const StringCheck = performStringCheck(this.state.nameValue);

    if (!emailCheck.success) {
      console.log("error", emailCheck.message);
      this.childEmail.focus();
      this.props.displayLoginError(emailCheck.message);
    } else if (!passwordCheck.success) {
      console.log("error", passwordCheck.message);
      this.childPassword.focus();
      this.props.displayLoginError(passwordCheck.message);
    } else if (!StringCheck.success) {
      console.log("error", StringCheck.message);
      this.childName.focus();
      this.props.displayLoginError(StringCheck.message);
    } else {
      this.props.startPopupLoading();
      console.log("Data type ok");
      console.log(user);
      this.props.removeLoginWindowError();
      this.props.signup(user);
    }
    if (e) e.preventDefault();
  };

  handleEmailChange = (e) => {
    this.setState({ emailValue: e.target.value });
  };

  handleNameChange = (e) => {
    this.setState({ nameValue: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ passwordValue: e.target.value });
  };

  componentWillReceiveProps(props) {
    console.log("received props");
    console.log(props);
    if (typeof props.auth !== "undefined" && props.auth !== null) {
      this.props.stopPopupLoading();
      console.log("lets check", props.auth.message);
      if (typeof props.auth.errorMessage !== "undefined") {
        console.log("signup failed");
        this.props.displayLoginError(props.auth.errorMessage);
      } else if (props.auth.message === "signup success") {
        console.log("signup success");
        this.props.moveToLogin(
          "Signup successful! please login.",
          this.state.emailValue
        );
      }
    }
  }

  componentDidMount() {
    if (this.childEmail) {
      this.childEmail.focus();
    }
    // this.getCountryCode();
  }

  render() {
    return (
      <div className="loginPopup__signupForm">
        <form onSubmit={this.handleSignup}>
          <div className="input__control">
            <input
              id="signupFormEmail"
              className="input input--active util__wide"
              type="name"
              name="email"
              placeholder="Email"
              ref={(ref) => (this.childEmail = ref)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.handleSignup();
                }
              }}
              value={this.state.emailValue}
              onChange={this.handleEmailChange}
            />
            <label className="input__label" htmlFor="signupFormEmail">
              Email
            </label>
          </div>
          <div className="input__control">
            <PasswordInput
              id="signupFormPassword"
              className="input input--active util__wide"
              type="password"
              name="pass"
              placeholder="Password"
              ref={(ref) => (this.childPassword = ref)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.handleSignup();
                }
              }}
              value={this.state.passwordValue}
              onChange={this.handlePasswordChange}
            />
          </div>
          <div className="input__control">
            <input
              id="signupFormName"
              className="input input--active util__wide"
              type="name"
              name="name"
              ref={(ref) => (this.childName = ref)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  this.handleSignup();
                }
              }}
              placeholder="Name"
              value={this.state.nameValue}
              onChange={this.handleNameChange}
            />
            <label className="input__label" htmlFor="signupFormName">
              Name
            </label>
          </div>

          <button
            onClick={this.handleSignup}
            id="submitS"
            className="btn btn__primary btn__active btn__wide loginPopup__action"
            type="button"
          >
            Candidate Signup
          </button>
        </form>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

function performEmailCheck(val) {
  if (typeof val !== "string" || val === null || typeof val === "undefined")
    return { success: false, message: "Email poorly formatted" };
  const re =
    /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const ans = re.test(String(val).toLowerCase());
  return { success: ans, message: "Email is poorly formatted" };
}

function performPasswordCheck(val) {
  if (typeof val !== "string" || val === null || typeof val === "undefined")
    return { success: false, message: "Password poorly formatted" };
  if (val.split("").length < 1)
    return { success: false, message: "Password field cannot be left Empty" };
  if (val.split("").length < 5)
    return { success: false, message: "Password too short" };
  return { success: true };
}

function performStringCheck(val) {
  if (typeof val !== "string" || val === null || typeof val === "undefined")
    return { success: false, message: "name can only contain alphabets" };
  if (val.length < 1)
    return { success: false, message: "Name cannot be left empty" };
  /* else if ()
    return { success: false, message: "Name can only be an alphabet" }; */ return {
    success: true,
  };
}

export default connect(mapStateToProps, { signup })(SignupForm);
