export default (
  memberPermissions,
  searchedPermissions,
  type = "subemployer",
  mustMatchAllPermissions = false
) => {
  if (type === "employer") {
    return true;
  }
  let searchedPermissionArr = searchedPermissions;
  if (typeof searchedPermissions === "string") {
    searchedPermissionArr = [searchedPermissions];
  }
  if (
    Array.isArray(memberPermissions) &&
    Array.isArray(searchedPermissionArr)
  ) {
    let hasMatchedAllPersmissions = false;
    let hasMatchedSomePermissions = searchedPermissionArr.length === 0;
    searchedPermissionArr.some((perm) => {
      if (memberPermissions.includes(perm)) {
        hasMatchedAllPersmissions = true;
        if (!hasMatchedSomePermissions) {
          hasMatchedSomePermissions = true;
        }
        return false;
      }
    });
    let hasMatchedPersmissions = hasMatchedAllPersmissions;
    if (mustMatchAllPermissions) {
      hasMatchedPersmissions = hasMatchedSomePermissions;
    }
    if (hasMatchedPersmissions) {
      return true;
    }
  }
  return false;
};
