import { ADD_JOB, CLEAR_JOBS } from "../Action/types";

export default (state = [], action) => {
  switch (action.type) {
    case ADD_JOB:
      const jobIndex = state.findIndex(
        (jb) => jb.jobPost._id === action.payload.jobPost._id
      );
      if (jobIndex < 0) {
        state.push(action.payload);
      } else if (
        state[jobIndex] &&
        !state[jobIndex].candidates &&
        action.payload.jobPost.candidates
      ) {
        state[jobIndex].candidates = action.payload.jobPost.candidates;
      } else if (
        state[jobIndex] &&
        state[jobIndex].candidates &&
        Array.isArray(state[jobIndex].candidates) &&
        action.payload.jobPost.candidates &&
        Array.isArray(action.payload.jobPost.candidates) &&
        action.payload.jobPost.candidates.length !==
          state[jobIndex].candidates.length
      ) {
        state[jobIndex].candidates = action.payload.jobPost.candidates;
      }
      return state;
    case CLEAR_JOBS:
      return [];
    default:
      return state;
  }
};
