import {
  MEMBER_TYPE_HR_ASSOCIATE,
  MEMBER_TYPE_HR_TECH_INTERVIEWER,
  MEMBER_TYPE_HR_VENDOR,
  MEMBER_TYPE_INTERVIEWER,
  MEMBER_TYPE_RECRUITER,
} from "../../../../../config/const";

export const MANAGE_JOBS = "Manage Jobs";
export const ALL_JOBS_ACCESS = "Can Access All Jobs";
export const CHANGE_JOB_STATUS = "Change Job Status";
export const MANAGE_APPLICANTS = "Manage Applicants";
export const CANDIDATE_POOL = "Can View Candidate Pool";
export const MANAGE_REFERRALS = "Manage Referral Status";
export const CAN_ACCESS_ALL_CANDIDATES = "Can Access All Candidates In A Job";
export const CAN_CREATE_OFFER_LETTERS = "Can Create Offer Letters";
export const CAN_OVERRIDE_OFFER_LETTER = "Can Override Offer Letters";
export const SEND_MESSAGES = "Send Messages";
export const SEND_EMAILS = "Send Emails";
export const POST_JOBS = "Post Jobs";
export const EDIT_JOBS = "Edit Jobs";
export const DOWNLOAD_DATA = "Download Data";
export const UPLOAD_DATA = "Upload Data";
export const MANAGE_ASSESSMENTS = "Manage Assessments";
export const MANAGE_COMPANY_PROFILE = "Manage Company Profile";
export const MANAGE_SYSTEM_SETTINGS = "Manage System Settings";
export const SCHEDULE_INTERVIEWS = "Schedule Interviews";
export const ANALYTICS_ACCESS = "Analytics Access";
export const CREATE_MEMBERS = "Create Members";
export const EDIT_MEMBERS = "Edit Members";
export const CREATE_TEAMS = "Create Teams";
export const EDIT_TEAMS = "Edit Teams";
export const CREATE_CLIENTS = "Create Business Units";
export const EDIT_CLIENTS = "Edit Business Units";

// eslint-disable-next-line import/no-mutable-exports
const permissionsArr = [
  CANDIDATE_POOL,
  CAN_CREATE_OFFER_LETTERS,
  CAN_ACCESS_ALL_CANDIDATES,
  MANAGE_REFERRALS,
  POST_JOBS,
  EDIT_JOBS,
  MANAGE_JOBS,
  ALL_JOBS_ACCESS,
  MANAGE_APPLICANTS,
  CAN_OVERRIDE_OFFER_LETTER,
  DOWNLOAD_DATA,
  UPLOAD_DATA,
  MANAGE_ASSESSMENTS,
  CREATE_MEMBERS,
  EDIT_MEMBERS,
  MANAGE_COMPANY_PROFILE,
  MANAGE_SYSTEM_SETTINGS,
  SCHEDULE_INTERVIEWS,
  SEND_MESSAGES,
  CREATE_TEAMS,
  EDIT_TEAMS,
  CREATE_CLIENTS,
  EDIT_CLIENTS,
  ANALYTICS_ACCESS,
];
export const permissionsRender = [
  {
    title: "Jobs",
    permissions: [
      {
        permission: POST_JOBS,
        explanation: "This user would be able to post jobs on the platform",
      },
      {
        permission: MANAGE_JOBS,
        explanation:
          "This user would be able to edit jobs they have been assigned on the platform",
      },
      {
        permission: CHANGE_JOB_STATUS,
        explanation:
          "This user would be able to change job post status to on-hold/closed",
      },
      {
        permission: ALL_JOBS_ACCESS,
        explanation: "This user would be able to view all jobs on the platform",
      },
    ],
  },
  {
    title: "Candidates",
    permissions: [
      {
        permission: CAN_ACCESS_ALL_CANDIDATES,
        explanation:
          "This user would be able to view all candidates that have applied for a particular job post on the platform",
      },
      {
        permission: MANAGE_APPLICANTS,
        explanation:
          "This user would be able to manage applicants they have been assigned on the platform",
      },
      {
        permission: CANDIDATE_POOL,
        explanation:
          "This user would be able to view all candidates on the platform",
      },
      {
        permission: MANAGE_REFERRALS,
        explanation: "This user would be able to manage referral status",
      },
      {
        permission: CAN_OVERRIDE_OFFER_LETTER,
        explanation:
          "This user would be able to view and edit all offer letters on the platform",
      },
    ],
  },
  {
    title: "Communication",
    permissions: [
      {
        permission: SEND_MESSAGES,
        explanation:
          "This user would be able to send in-app messages to the candidates",
      },
      {
        permission: SEND_EMAILS,
        explanation:
          "This user would be able to send emails to the candidate(s)",
      },
    ],
  },
  {
    title: "Interviews",
    permissions: [
      {
        permission: SCHEDULE_INTERVIEWS,
        explanation: "Allows user to schedule interviews on the platform",
      },
    ],
  },
  {
    title: "Assessments",
    permissions: [
      {
        permission: MANAGE_ASSESSMENTS,
        explanation:
          "Can send assessments to the candidates and view their scores",
      },
    ],
  },
  {
    title: "Teams and Members",
    permissions: [
      {
        permission: CREATE_MEMBERS,
        explanation: "This user would be able to create and edit new users",
      },
      {
        permission: EDIT_MEMBERS,
        explanation: "This user would be able to edit all platform users",
      },
      {
        permission: CREATE_TEAMS,
        explanation: "This user would be able to create and edit new teams",
      },
      {
        permission: EDIT_TEAMS,
        explanation: "This user would be able to edit all teams",
      },
      {
        permission: CREATE_CLIENTS,
        explanation:
          "This user would be able to create and edit new business units",
      },
      {
        permission: EDIT_CLIENTS,
        explanation: "This user would be able to edit all business units",
      },
    ],
  },
  {
    title: "Data",
    permissions: [
      {
        permission: DOWNLOAD_DATA,
        explanation:
          "Allows this user to download data e.g. reports, resumes, documents etc.",
      },
      {
        permission: UPLOAD_DATA,
        explanation:
          "Allows user to upload candidate resumes and other documents",
      },
      {
        permission: MANAGE_COMPANY_PROFILE,
        explanation:
          "This user would be able to edit company logo and other public information",
      },
      {
        permission: MANAGE_SYSTEM_SETTINGS,
        explanation:
          "This user would be able to edit ATS functions and settings",
      },
      {
        permission: ANALYTICS_ACCESS,
        explanation: "Allow user access to all platform analytics and reports",
      },
    ],
  },
];

export const DEFAULT_RECRUITER_PERMISSIONS = [
  POST_JOBS,
  MANAGE_JOBS,
  MANAGE_APPLICANTS,
  CANDIDATE_POOL,
  SEND_MESSAGES,
  SEND_EMAILS,
  MANAGE_ASSESSMENTS,
  UPLOAD_DATA,
  SCHEDULE_INTERVIEWS,
];
export const DEFAULT_HR_ASSOCIATE_PERMISSIONS = [
  POST_JOBS,
  MANAGE_JOBS,
  MANAGE_REFERRALS,
  ALL_JOBS_ACCESS,
  MANAGE_APPLICANTS,
  CAN_ACCESS_ALL_CANDIDATES,
  DOWNLOAD_DATA,
  CREATE_MEMBERS,
  EDIT_MEMBERS,
  CREATE_TEAMS,
  EDIT_TEAMS,
  CREATE_CLIENTS,
  EDIT_CLIENTS,
  ANALYTICS_ACCESS,
  MANAGE_COMPANY_PROFILE,
];

export const DEFAULT_VENDOR_PERMISSIONS = [UPLOAD_DATA];
export const DEFAULT_INTERVIEWER_PERMISSIONS = [SCHEDULE_INTERVIEWS];
export const DEFAULT_TECH_INTERVIEWER_PERMISSIONS = [SCHEDULE_INTERVIEWS];

export function getAutoUserPermissions(val) {
  let autoPerms = [];
  if (val) {
    switch (val) {
      case MEMBER_TYPE_RECRUITER:
        autoPerms = DEFAULT_RECRUITER_PERMISSIONS;
        break;
      case MEMBER_TYPE_HR_ASSOCIATE:
        autoPerms = DEFAULT_HR_ASSOCIATE_PERMISSIONS;
        break;
      case MEMBER_TYPE_HR_VENDOR:
        autoPerms = DEFAULT_VENDOR_PERMISSIONS;
        break;
      case MEMBER_TYPE_HR_TECH_INTERVIEWER:
        autoPerms = DEFAULT_TECH_INTERVIEWER_PERMISSIONS;
        break;
      case MEMBER_TYPE_INTERVIEWER:
        autoPerms = DEFAULT_INTERVIEWER_PERMISSIONS;
        break;
      default:
        autoPerms = [];
    }
  }
  return autoPerms;
}

export default permissionsArr;
