import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import reactLogger from "../utils/helpers/reactLogger";
import {
  MANAGE_JOBS,
  SCHEDULE_INTERVIEWS,
  SEND_MESSAGES,
} from "../utils/helpers/memberPermissions";
import SimpleLineIcon from "../utils/components/SimpleLineIcons";
import swishMessage from "../utils/components/SwishMessage";
import hasPermissions from "../utils/helpers/hasPermissions";
import { USER_TYPE_SUBEMPLOYER } from "../../../../config/const";

export default class SubEmployerNavigationMenuMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      showCamera: false,
      profilePictureFile: null,
      profilePictureUrl: "",
    };
  }

  componentDidMount() {
    if (window.location.pathname === "/employer/dashboard")
      this.setState({ active: 0 });
    else if (window.location.pathname.includes("/employer/recruit/jobs"))
      this.setState({ active: 1 });
    else if (
      window.location.pathname === "/employer/messages" ||
      window.location.pathname === "/subemployer/messages"
    )
      this.setState({ active: 2 });
    else if (
      window.location.pathname === "/employer/calendar" ||
      window.location.pathname === "/subemployer/calendar"
    )
      this.setState({ active: 3 });
    else if (
      window.location.pathname === "/employer/profile" ||
      window.location.pathname === "/subemployer/profile"
    )
      this.setState({ active: 4 });
    else if (window.location.pathname === "/employer/corps")
      this.setState({ active: 5 });
    else if (window.location.pathname.includes("/employer/settings"))
      this.setState({ active: -1 });
    else this.setState({ active: 0 });
    //
    if (this.props.auth !== null) {
      this.setState({
        profilePictureUrl: this.props.profilePictureUrl,
      });
    }
  }

  fileSelectEvent = async (file) => {
    this.setState({ profilePictureFile: file });
    try {
      const url = "/api/usersettings/changepicture";
      const res = await axios.get(url);
      if (res) {
        if (res.data && res.data.s3SignedImageUploadUrl) {
          this.uploadImageFile(res.data.s3SignedImageUploadUrl);
        } else {
          throw new Error("Something went wrong");
        }
      }
    } catch (err) {
      reactLogger(
        err,
        "SubEmployerNavigationMenuMobile",
        "Getting URL has failed"
      );
      this.setState({
        profilePictureFile: null,
        showCamera: false,
      });
      swishMessage("danger", "Profile picture update failed");
    }
  };

  uploadImageFile(signedUrl) {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedUrl, true);
    xhr.setRequestHeader("Content-Type", "image");
    xhr.onload = async () => {
      if (xhr.status === 200) {
        this.setState({
          profilePictureFile: null,
        });
        swishMessage("success", "Profile picture updated successfully");
        this.profilePicturePreview.style.backgroundImage = `url("${
          this.state.profilePictureUrl
        }?${new Date().getTime()}"), url("https://image.ibb.co/mcXULA/dum.png")`;
      } else {
        swishMessage("danger", "Profile picture update failed");
      }
    };
    xhr.onerror = () => {
      swishMessage("danger", "Profile picture update failed");
    };
    xhr.send(this.state.profilePictureFile);
  }

  onTakePhoto = (dataUri) => {
    const arr = dataUri.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], { type: mime });

    this.fileSelectEvent(blob);
    setTimeout(() => {
      this.setState({ showCamera: false });
    }, 1500);
  };

  onCameraError = (error) => {
    this.setState({
      success: false,
      showCamera: false,
      showCamera: false,
      popupVisible: true,
      popupMessage: "Failed to access camera.",
    });
  };

  renderPermittedMenuItem = (requiredPermission) => {
    if (this.props && Array.isArray(this.props.permissions)) {
      return this.props.permissions.includes(requiredPermission);
    }
  };

  renderNavigationMenu = () => {
    return (
      <div className="header__controlpane__navigationMenu">
        <Link
          className={
            this.state.active === 0
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/employer/dashboard"
        >
          <SimpleLineIcon
            name="layers"
            color={this.state.active === 0 ? "#3ce2cf" : "#777"}
          />
          <div style={{ marginLeft: "3rem" }}>Dashboard</div>
        </Link>
        {this.renderPermittedMenuItem(MANAGE_JOBS) && (
          <Link
            className={
              this.state.active === 1
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/recruit/jobs"
          >
            <SimpleLineIcon
              name="energy"
              color={this.state.active === 1 ? "#3ce2cf" : "#777"}
            />
            <div style={{ marginLeft: "3rem" }}>My Job Postings</div>
          </Link>
        )}
        {this.renderPermittedMenuItem(SEND_MESSAGES) && (
          <Link
            className={
              this.state.active === 2
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/messages"
          >
            <SimpleLineIcon
              name="energy"
              color={this.state.active === 2 ? "#3ce2cf" : "#777"}
            />
            <div style={{ marginLeft: "3rem" }}>Messages</div>
          </Link>
        )}
        {this.renderPermittedMenuItem(SCHEDULE_INTERVIEWS) && (
          <Link
            className={
              this.state.active === 3
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/employer/calendar"
          >
            <SimpleLineIcon
              name="calendar"
              color={this.state.active === 3 ? "#3ce2cf" : "#777"}
            />
            <div style={{ marginLeft: "3rem" }}>My Calendar</div>
          </Link>
        )}
        {/* <Link
          className={
            this.state.active === 4
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/subemployer/profile"
        >
          <SimpleLineIcon
            name="user"
            color={this.state.active === 4 ? "#3ce2cf" : "#777"}
          />
          <div style={{ marginLeft: "3rem" }}>My Profile</div>
        </Link>
        <Link
          className={
            this.state.active === 4
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/employer/profile"
        >
          <SimpleLineIcon
            name="user"
            color={this.state.active === 4 ? "#3ce2cf" : "#777"}
          />
          <div style={{ marginLeft: "3rem" }}>My Profile</div>
        </Link> */}
      </div>
    );
  };

  renderMobileNavigationMenu = () => {
    const { permissions } = this.props;
    return (
      <div className="header__navigationMenu__mobile">
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/dashboard"
        >
          <div
            id="nav__dashboard"
            className={
              this.state.active === 0
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="compass" />
            <div style={{ marginLeft: "2rem" }}>Dashboard</div>
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/recruit/jobs"
        >
          <div
            id="nav__jobs"
            className={
              this.state.active === 1
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="briefcase" />
            <div style={{ marginLeft: "2rem" }}>Recruit</div>
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/employees/list"
        >
          <div
            id="nav__jobs"
            className={
              this.state.active === 2
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="people" />
            <div style={{ marginLeft: "2rem" }}>Employees</div>
          </div>
        </Link>
        {hasPermissions(
          permissions,
          [SEND_MESSAGES],
          USER_TYPE_SUBEMPLOYER
        ) && (
          <Link
            className="header__navigationMenu__mobile--item util__noStyleLink"
            to="/employer/messages"
          >
            <div
              id="nav__profile"
              className={
                this.state.active === 3
                  ? "header__navigationMenu__mobile--item--active"
                  : "header__navigationMenu__mobile--item--inactive"
              }
            >
              <SimpleLineIcon name="speech" />
              <div style={{ marginLeft: "2rem" }}>Messages</div>
            </div>
          </Link>
        )}
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/calendar"
        >
          <div
            id="nav__conf"
            className={
              this.state.active === 4
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="calendar" />
            <div style={{ marginLeft: "2rem" }}>My Calendar</div>
          </div>
        </Link>

        {/* <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/calendar"
        >
          <div
            id="nav__conf"
            className={
              this.state.active === 3
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            My Calendar
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/subemployer/profile"
        >
          <div
            id="nav__conf"
            className={
              this.state.active === 4
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            My Profile
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/subemployer/settings/account"
        >
          <div
            id="nav__conf"
            className={
              this.state.active === 6
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            Settings
          </div>
        </Link> */}
      </div>
    );
  };

  renderSettingsMenu = () => {
    return (
      <>
        {this.state.showCamera && (
          <div className="settings__camera">
            <Camera
              onTakePhoto={(dataUri) => {
                this.onTakePhoto(dataUri);
              }}
              onCameraError={(error) => {
                this.onCameraError(error);
              }}
              idealFacingMode={FACING_MODES.USER}
              idealResolution={{ width: 640, height: 480 }}
              imageType={IMAGE_TYPES.JPG}
              imageCompression={0.97}
              isMaxResolution={false}
              isImageMirror={false}
              isDisplayStartCameraError
              sizeFactor={1}
            />
            <div
              className="settings__camera--close heading__tertiary"
              onClick={() => {
                this.setState({ showCamera: false });
              }}
            />
          </div>
        )}
        <div className="settings__navigator__shrunk">
          <div className="settings__navigator--item">
            <div
              alt="avatar"
              ref={(ref) => (this.profilePicturePreview = ref)}
              className="settings__navigator--item--sub settings__navigator--item--img"
              style={{
                backgroundImage: `url("${this.props.profilePictureUrl}"), url("https://i.ibb.co/qy1mkfK/Recruitment-Icon.png")`,
              }}
            />
            <div
              className={
                this.state.profilePictureFile === null
                  ? "btn btn__active btn__primary settings__navigator--item--sub"
                  : "btn btn__primary settings__navigator--item--sub settings__navigator--item--btn"
              }
            >
              <input
                type="file"
                name="avatar"
                accept="image/*"
                id="subAvatarSelectMobile"
                onChange={(e) => this.fileSelectEvent(e.target.files[0])}
                style={{ display: "none" }}
              />
              <label className="util__cursorPointer" htmlFor="avatar">
                Upload picture
              </label>
            </div>
            <div
              onClick={() => {
                this.setState({ showCamera: true });
              }}
              className="settings__navigator--item--sub btn btn__transparent btn__primaryBorders"
            >
              Take photo
            </div>
          </div>
          <Link
            className="util__noStyleLink"
            to="/subemployer/settings/account"
          >
            <div
              className={
                this.state.active === 6
                  ? "settings__navigator--item settings__navigator--item--active"
                  : "settings__navigator--item settings__navigator--item--inactive"
              }
            >
              Account settings
            </div>
          </Link>
          <Link
            className="util__noStyleLink"
            to="/subemployer/settings/password"
          >
            <div
              className={
                this.state.active === 7
                  ? "settings__navigator--item settings__navigator--item--active"
                  : "settings__navigator--item settings__navigator--item--inactive"
              }
            >
              Password
            </div>
          </Link>
        </div>
      </>
    );
  };

  render() {
    if (this.props.mobile)
      return this.state.active >= 6
        ? this.renderSettingsMenu()
        : this.renderMobileNavigationMenu();
    return this.state.active >= 6
      ? this.renderSettingsMenu()
      : this.renderNavigationMenu();
  }
}
