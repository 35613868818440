import { SET_CURRENT_SOCKET, SET_CURRENT_SOCKET_UNDEF } from "./types";
export const setSocket = socket => {
	return dispatch => {
		if (socket === null) {
			dispatch({
				type: SET_CURRENT_SOCKET_UNDEF,
				payload: false
			});
		} else {
			dispatch({
				type: SET_CURRENT_SOCKET,
				payload: socket
			});
		}
	};
};
