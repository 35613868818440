import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import LoginGoogle from "./LoginGoogle";
import { login, fetchUser } from "../../../Action/index";
import PasswordInput from "../../utils/components/PasswordInput";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: this.props.signupEmailValue
        ? this.props.signupEmailValue
        : "",
      passwordValue: "",
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin(e) {
    const user = {
      email: this.state.emailValue,
      password: this.state.passwordValue,
    };

    const PasswordCheck = performPasswordCheck(this.state.passwordValue);
    const EmailCheck = performEmailCheck(this.state.emailValue);

    if (!EmailCheck.success) {
      console.log("error", EmailCheck.message);
      this.markInputError(this.childEmailInput);
      this.props.displayLoginError(EmailCheck.message);
    } else if (!PasswordCheck.success) {
      console.log("error", PasswordCheck.message);
      this.markInputError(this.childPasswordInput);
      this.props.displayLoginError(PasswordCheck.message);
    } else {
      this.props.startPopupLoading();
      console.log("Data types ok");
      this.props.removeLoginWindowError();
      console.log(user);
      this.props.login(user);
    }

    if (e) {
      e.preventDefault();
    }
  }

  markInputError(child) {
    child.focus();
  }

  handleEmailChange(e) {
    this.setState({ emailValue: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ passwordValue: e.target.value });
  }

  componentWillReceiveProps(props) {
    console.log("received props");
    console.log(props);
    if (typeof props.auth !== "undefined" && props.auth !== null) {
      if (this.props.stopPopupLoading) this.props.stopPopupLoading();
      if (typeof props.auth.errorMessage !== "undefined") {
        if (this.props.displayLoginError)
          this.props.displayLoginError(props.auth.errorMessage);
      } else if (props.auth.message === "login success") {
        this.props.fetchUser();
        if (this.props.displayLoginSuccess)
          this.props.displayLoginSuccess("Login successful.");
        if (this.props.onLoginSuccess) this.props.onLoginSuccess();
      }
    }
  }

  componentDidMount() {
    if (this.props.passwordFocus && this.childPasswordInput) {
      this.childPasswordInput.focus();
    } else if (this.childEmailInput) {
      this.childEmailInput.focus();
    }
  }

  render() {
    return (
      <div className="loginPopup__loginForm">
        <form onSubmit={this.handleLogin}>
          <div className="input__control">
            <input
              id="loginFormEmail"
              className="input input--active util__wide"
              type="name"
              ref={(input) => {
                this.childEmailInput = input;
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  this.handleLogin();
                }
              }}
              name="email"
              placeholder="Email"
              value={this.state.emailValue}
              onChange={this.handleEmailChange}
            />
            <label className="input__label" htmlFor="loginFormEmail">
              Email
            </label>
          </div>
          <div className="input__control">
            <PasswordInput
              id="loginFormPassword"
              className="input input--inactive util__wide"
              type="password"
              name="pass"
              placeholder="Password"
              ref={(input) => {
                this.childPasswordInput = input;
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  this.handleLogin();
                }
              }}
              value={this.state.passwordValue}
              onChange={this.handlePasswordChange}
            />
          </div>
          <button
            onClick={this.handleLogin}
            id="submitL"
            className="btn btn__primary btn__active btn__wide loginPopup__action"
            type="button"
          >
            Candidate Login
          </button>
        </form>
        <a
          className="loginPopup__loginForm--forgotPassword"
          href="/changePassword?type=user"
        >
          Forgot password?
        </a>
        <hr className="loginPopup__or--left" />

        <LoginGoogle />
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  console.log(auth);
  return { auth };
}

function performPasswordCheck(val) {
  if (typeof val !== "string" || val === null || typeof val === "undefined")
    return { success: false, message: "Password poorly formatted" };
  if (val.split("").length < 1)
    return { success: false, message: "Password field cannot be left Empty" };
  if (val.split("").length < 5)
    return { success: false, message: "Password is too short" };
  return { success: true };
}
function performEmailCheck(val) {
  if (typeof val !== "string" || val === null || typeof val === "undefined")
    return { success: false };
  const re =
    /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const k = re.test(String(val).toLowerCase());
  if (k === true) return { success: true };
  return {
    success: false,
    message: "Email is poorly formatted.",
  };
}

export default connect(mapStateToProps, { login, fetchUser })(LoginForm);
