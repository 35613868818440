import { INCOMING_MESSAGE } from './types';

export const notifyOnIncomingSocketMessage = incomingMessage => async (
  dispatch,
  getState,
  axiosInstance
) => {
  dispatch({
    payload: incomingMessage,
    type: INCOMING_MESSAGE
  });
};
