import {
	CURRENT_CANDIDATE_IN_VIEW,
	CURRENT_CANDIDATE_IN_VIEW_UNDEF
} from "../Action/types";

export default (state = null, action) => {
	switch (action.type) {
		case CURRENT_CANDIDATE_IN_VIEW:
			return action.payload;
		case CURRENT_CANDIDATE_IN_VIEW_UNDEF:
			return action.payload;
		default:
			return state;
	}
};
