import {
  INC_CURRENT_CHAT_PAGE,
  FETCHING_MORE_CHATS,
  FETCHING_MORE_CHATS_FAILED,
  CURRENT_CHAT_PAGE_LAST,
  ADD_NEW_CHAT,
  REFETCH_CHATS,
  GET_CHATS,
  UPDATE_CHATS,
} from "./types";
import reactLogger from "../Component/utils/helpers/reactLogger";

export const updateChatsOnMessageReceived =
  (message, chatId, userType) => async (dispatch, getState) => {
    try {
      const { chats } = getState();
      if (userType === "user") {
        // eslint-disable-next-line array-callback-return
        chats.some((chat) => {
          if (chat._id === chatId) {
            const isMessageExisiting = chat.messages.findIndex(
              (msg) => msg._id === message._id
            );
            if (isMessageExisiting < 0) {
              chat.messages.push(message);
            }
            return true;
          }
        });
        dispatch({
          payload: chats,
          type: UPDATE_CHATS,
        });
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          payload: false,
          type: FETCH_USER_FAILED,
        });
      } else {
        console.log("err", err);
      }
    }
  };

export const addNewChat = (newChat) => async (dispatch) => {
  try {
    dispatch({ type: ADD_NEW_CHAT, payload: newChat });
  } catch (err) {
    reactLogger(err, "chatActions", "Redux: failed to add job");
  }
};
export const fetchChats = () => async (dispatch, getState, axiosInstance) => {
  try {
    dispatch({
      type: FETCHING_MORE_CHATS,
    });
    dispatch({
      type: REFETCH_CHATS,
    });
    const { chatsMeta } = getState();
    const res = await axiosInstance.get(
      `/api/chat/v1/get_chats?currentPage=${chatsMeta.currentPage}`
    );
    dispatch({
      type: GET_CHATS,
      payload: res.data.chats,
    });
    if (res.data.chats.length < process.env.GET_CHATS_PER_PAGE_LIMIT) {
      dispatch({
        type: CURRENT_CHAT_PAGE_LAST,
      });
    } else {
      dispatch({
        type: INC_CURRENT_CHAT_PAGE,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_CHATS,
      payload: false,
    });
    dispatch({
      type: FETCHING_MORE_CHATS_FAILED,
    });
    reactLogger(err, "chatActions", "Redux: getting all chats has failed");
  }
};
