import {
  FETCH_USER,
  UPDATE_USER,
  FETCH_USER_FAILED,
  USER_LOGIN,
  USER_LOGIN_FAILED,
  USER_LOGOUT,
  USER_SIGNUP,
} from "./types";
import reactLogger from "../Component/utils/helpers/reactLogger";
import UTM from "../Component/utils/vendors/UTM/UTM";

export const fetchUser = () => async (dispatch, getState, axiosInstance) => {
  try {
    const res = await axiosInstance.get("/api/current_user", {
      Headers: { "Cache-Control": "no-cache" },
    });
    dispatch({
      payload: res.data,
      type: FETCH_USER,
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch({
        payload: false,
        type: FETCH_USER_FAILED,
      });
    } else {
      console.log("err", err);
    }
  }
};

export const updateUserJobHistory =
  (jobId, user) => async (dispatch, getState, axiosInstance) => {
    console.log("message started");
    try {
      if (user && jobId) {
        if (!user.jobHistory || !Array.isArray(user.jobHistory)) {
          user.jobHistory = [];
        }
        if (user.jobHistory.includes(jobId)) {
          user.jobHistory.some((job, i) => {
            if (job === jobId) {
              user.jobHistory.splice(i, 1);
            }
          });
          user.jobHistory.push(jobId);
        } else {
          if (user.jobHistory.length > 14) {
            user.jobHistory.shift();
          }
          user.jobHistory.push(jobId);
        }
        dispatch({
          payload: user,
          type: UPDATE_USER,
        });
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          payload: false,
          type: FETCH_USER_FAILED,
        });
      } else {
        console.log("err", err);
      }
    }
  };
export const updateUserOnMessageStatusReceived =
  (messageId, contactId, updatedStatus, currentUserData) =>
  async (dispatch, getState, axiosInstance) => {
    console.log("message started");
    try {
      if (currentUserData.type === "user") {
        console.log("user status message");
        currentUserData.appliedJobs.some((appliedJob) => {
          console.log(contactId, appliedJob._id);
          if (appliedJob._id === contactId) {
            appliedJob.messages.messages.some((msg) => {
              if (msg._id === messageId) {
                msg.status = updatedStatus;
                return true;
              }
            });
            return true;
          }
        });
      } else {
        console.log("employer status message");
        currentUserData.messages.some((conversation) => {
          console.log(contactId, conversation.contactId);
          if (conversation.contactId === contactId) {
            conversation.messages.some((msg) => {
              if (msg._id === messageId) {
                msg.status = updatedStatus;
                return true;
              }
            });
            return true;
          }
        });
      }
      dispatch({
        payload: currentUserData,
        type: UPDATE_USER,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          payload: false,
          type: FETCH_USER_FAILED,
        });
      } else {
        console.log("err", err);
      }
    }
  };
export const updateAppliedJobStateAndStatus =
  (appliedJobId, state, status, currentUserData) =>
  async (dispatch, getState, axiosInstance) => {
    try {
      currentUserData.appliedJobs.some((appliedJob) => {
        console.log(appliedJobId, appliedJob._id);
        console.log(appliedJob.state, state);
        if (appliedJob._id === appliedJobId) {
          appliedJob.state = state;
          if (status) appliedJob.status = status;

          return true;
        }
      });

      dispatch({
        payload: currentUserData,
        type: UPDATE_USER,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          payload: false,
          type: FETCH_USER_FAILED,
        });
      } else {
        console.log("err", err);
      }
    }
  };
export const updateUserMessageRedux =
  (message, appliedJobId, currentUserData) =>
  async (dispatch, getState, axiosInstance) => {
    console.log("message started");
    try {
      if (currentUserData.type === "user") {
        console.log("user message");
        currentUserData.appliedJobs.some((appliedJob) => {
          if (appliedJob._id === appliedJobId) {
            if (
              appliedJob.messages.messages.findIndex(
                (msg) => msg._id === message._id
              ) < 0
            )
              appliedJob.messages.messages.push(message);
            return true;
          }
        });
      }
      dispatch({
        payload: currentUserData,
        type: UPDATE_USER,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          payload: false,
          type: FETCH_USER_FAILED,
        });
      } else {
        console.log("err", err);
      }
    }
  };
export const updateUserOnAddAppliedJob =
  (appliedJob, vcv, currentUserData) =>
  async (dispatch, getState, axiosInstance) => {
    console.log("appliedJob started");
    try {
      if (
        currentUserData.type === "user" &&
        currentUserData.appliedJobs.findIndex(
          (apJob) => apJob._id === appliedJob._id
        ) < 0
      ) {
        currentUserData.appliedJobs.push(appliedJob);
      }
      if (
        currentUserData.type === "user" &&
        currentUserData.vcv.findIndex(
          (vcvHolder) => vcvHolder._id === vcv._id
        ) < 0
      ) {
        currentUserData.vcv.push(vcv);
      }
      dispatch({
        payload: currentUserData,
        type: UPDATE_USER,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          payload: false,
          type: FETCH_USER_FAILED,
        });
      } else {
        console.log("err", err);
      }
    }
  };
export const toggleAppliedJobShouldCelebrate =
  (appliedJobId, currentUserData) =>
  async (dispatch, getState, axiosInstance) => {
    console.log("appliedJob toggling");
    try {
      const apIndex = currentUserData.appliedJobs.findIndex(
        (apJob) => apJob._id === appliedJobId
      );
      if (currentUserData.type === "user" && apIndex > -1) {
        currentUserData.appliedJobs[apIndex].shouldCelebrate = false;
      }
      dispatch({
        payload: currentUserData,
        type: UPDATE_USER,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          payload: false,
          type: FETCH_USER_FAILED,
        });
      } else {
        console.log("err", err);
      }
    }
  };
export const updateUserProperty =
  (propertyName, propertyValue, currentUserData) =>
  async (dispatch, getState, axiosInstance) => {
    console.log("updating user property");
    try {
      if (currentUserData.type === "user") {
        currentUserData[propertyName] = propertyValue;
      }
      dispatch({
        payload: currentUserData,
        type: UPDATE_USER,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          payload: false,
          type: FETCH_USER_FAILED,
        });
      } else {
        console.log("err", err);
      }
    }
  };
export const updateTagsAndCountry =
  (currentUserData, tags, countryCode) =>
  async (dispatch, getState, axiosInstance) => {
    console.log("updating user property");
    try {
      if (currentUserData.type === "user") {
        currentUserData.tags = tags;
        currentUserData.countryCode = countryCode;
      }
      dispatch({
        payload: currentUserData,
        type: UPDATE_USER,
      });
    } catch (err) {
      console.log("err", err);
    }
  };
export const updateVcvTotalExperience =
  (vcvId, years, months, currentUserData) =>
  async (dispatch, getState, axiosInstance) => {
    console.log("updating user vcv property");
    try {
      if (currentUserData.type === "user") {
        const vcvIndex = currentUserData.vcv.findIndex(
          (vcv) => vcv._id === vcvId
        );
        currentUserData.vcv[vcvIndex].totalExperience = {};
        currentUserData.vcv[vcvIndex].totalExperience.years = years;
        currentUserData.vcv[vcvIndex].totalExperience.months = months;
      }
      dispatch({
        payload: currentUserData,
        type: UPDATE_USER,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          payload: false,
          type: FETCH_USER_FAILED,
        });
      } else {
        console.log("err", err);
      }
    }
  };
export const updateAppliedJobVcvId =
  (appliedJobId, selectedVcvId, currentUserData) =>
  async (dispatch, getState, axiosInstance) => {
    console.log("updating user vcv property");
    try {
      if (currentUserData.type === "user") {
        const appliedJobIndex = currentUserData.appliedJob.findIndex(
          (appliedJob) => appliedJob._id === appliedJobId
        );
        if (appliedJobIndex > -1)
          currentUserData.appliedJob[appliedJobIndex].vcvId = selectedVcvId;
      }
      dispatch({
        payload: currentUserData,
        type: UPDATE_USER,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          payload: false,
          type: FETCH_USER_FAILED,
        });
      } else {
        console.log("err", err);
      }
    }
  };
export const updateVcvKey =
  (vcvId, propertyName, propertyValue, currentUserData) =>
  async (dispatch, getState, axiosInstance) => {
    console.log("updating user vcv property");
    try {
      if (currentUserData.type === "user") {
        const vcvIndex = currentUserData.vcv.findIndex(
          (vcv) => vcv._id === vcvId
        );
        currentUserData.vcv[vcvIndex][propertyName] = propertyValue;
      }
      dispatch({
        payload: currentUserData,
        type: UPDATE_USER,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch({
          payload: false,
          type: FETCH_USER_FAILED,
        });
      } else {
        console.log("err", err);
      }
    }
  };
export const signup = (values) => async (dispatch, getState, axiosInstance) => {
  try {
    let postParams = values;
    try {
      const utmValues = UTM.get();
      console.log({ utmValues });
      if (utmValues) {
        postParams = { ...postParams, ...utmValues };
      }
      console.log({ values });
    } catch (err) {
      console.log(err);
    }
    await axiosInstance.post("/api/signup", postParams);
    dispatch({
      payload: { message: "signup success" },
      type: USER_SIGNUP,
    });
  } catch (err) {
    console.log(err.response);
    dispatch({
      payload: err.response.data,
      type: USER_SIGNUP,
    });
    reactLogger(err, "userAuthActions", "signup's axios has failed");
  }
};
export const employerSignup =
  (values) => async (dispatch, getState, axiosInstance) => {
    try {
      await axiosInstance.post("/api/employer/signup", values);

      dispatch({
        payload: { message: "signup success" },
        type: USER_SIGNUP,
      });
    } catch (err) {
      reactLogger(err, "userAuthActions", "employer's signup's axios failed");
      console.log(err.response);
      dispatch({
        payload: err.response.data,
        type: USER_SIGNUP,
      });
    }
  };
export const employerLogin =
  (values) => async (dispatch, getState, axiosInstance) => {
    try {
      const res = await axiosInstance.post("/api/employer/login", values);
      dispatch({
        payload: res.data,
        type: USER_LOGIN,
      });
    } catch (err) {
      if (err.response && err.response.status !== 401) {
        reactLogger(err, "userAuthActions", "employer's login's axios failed");
      }
      dispatch({
        payload: err.response.data,
        type: USER_LOGIN_FAILED,
      });
    }
  };
export const loginGoogle = () => async (dispatch, getState, axiosInstance) => {
  try {
    const res = await axiosInstance.get("/api/auth/google");
    dispatch({
      payload: res.data,
      type: USER_LOGIN,
    });
  } catch (err) {
    reactLogger(err, "userAuthActions", "google's auth's axios failed");
    if (err.response.status === 401) {
      dispatch({
        payload: "google_login_failed",
        type: USER_LOGIN_FAILED,
      });
    }
  }
};
export const login = (values) => async (dispatch, getState, axiosInstance) => {
  try {
    const res = await axiosInstance.post("/api/login", values);
    dispatch({
      payload: res.data,
      type: USER_LOGIN,
    });
  } catch (err) {
    if (err.response && err.response.status !== 401) {
      reactLogger(err, "userAuthActions", "login's axios has failed");
    }
    dispatch({
      payload: err.response.data,
      type: USER_LOGIN_FAILED,
    });
  }
};
export const jwtUserLogin =
  (token) => async (dispatch, getState, axiosInstance) => {
    try {
      const res = await axiosInstance.post(
        "/api/magic_login",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log({ res });
      dispatch({
        payload: res.data,
        type: USER_LOGIN,
      });
    } catch (err) {
      if (err.response && err.response.status !== 401) {
        reactLogger(err, "userAuthActions", "login's axios has failed");
      }
      dispatch({
        payload: err.response.data,
        type: USER_LOGIN_FAILED,
      });
    }
  };
export const logoutUser = () => async (dispatch, getState, axiosInstance) => {
  try {
    await axiosInstance.get("/api/logout");
    dispatch({
      payload: false,
      type: USER_LOGOUT,
    });
  } catch (err) {
    reactLogger(err, "userAuthActions", "logout's axios has failed");
    console.log(err);
    dispatch({
      payload: false,
      type: USER_LOGOUT,
    });
  }
};
