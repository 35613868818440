import React, { Component } from "react";

import axios from "axios";
import { PropTypes } from "prop-types";
import DropDownNotificationComponent from "./DropDownNotificationComponent";
import SimpleLineIcon from "../../utils/components/SimpleLineIcons";
import reactLogger from "../../utils/helpers/reactLogger";
import ErrorPlaceHolderScreen from "../../utils/components/ErrorPlaceHolderScreen";
import ButtonLoader from "../../utils/components/ButtonLoader";

class EmployerSideNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: null,
      currentPage: 0,
    };
  }

  componentDidMount() {
    this.fetchNotifications();
  }

  componentDidCatch(err) {
    reactLogger(
      err,
      "EmployerSideNotifications.js",
      "Component caught an error"
    );
  }

  fetchNotifications = async () => {
    const { currentPage } = this.state;
    try {
      const {
        data: { notifications },
      } = await axios.get(
        `/api/notifications/v1/get_notifications?current_page=${currentPage}`
      );
      this.setState({ notifications });
    } catch (err) {
      reactLogger(
        err,
        "EmployerSideNotifications",
        "Error fetching notification"
      );
      this.setState({ notifications: false });
    }
  };

  renderNotifications = () => {
    const { notifications } = this.state;
    if (notifications === null) return <ButtonLoader size="3rem" />;
    if (notifications === false)
      return (
        <ErrorPlaceHolderScreen
          errorMessage="Error fetching notifications"
          onClickRetry={this.fetchNotifications}
        />
      );
    if (notifications.length > 0)
      return notifications.map((notification) => (
        <DropDownNotificationComponent
          key={notification._id}
          notification={notification}
        />
      ));
    return (
      <div className="dropDownNotifications__noMessages">
        You do not have any notifications
      </div>
    );
  };

  render() {
    const { mobile, closePopup, darkMode } = this.props;
    if (mobile) {
      return (
        <div className="dropDownNotifications">
          <div className="dropDownNotifications__header">
            <SimpleLineIcon onClick={closePopup} name="arrow-right" />
          </div>
          <div className="dropDownNotifications__title">Notifications</div>
          <div className="dropDownNotifications__container">
            {this.renderNotifications()}
          </div>
        </div>
      );
    }
    return (
      <div className="header__controlpane__sideNotifications">
        <div className="header__controlpane__sideNotifications--title">
          <SimpleLineIcon
            style={{ cursor: "pointer", marginRight: "1.5rem" }}
            onClick={closePopup}
            color={darkMode ? "#f1f1f1" : "#000000"}
            name="arrow-left"
          />
          Notifications
        </div>
        <div className="header__controlpane__sideNotifications__container">
          {this.renderNotifications()}
        </div>
      </div>
    );
  }
}
EmployerSideNotification.propTypes = {
  closePopup: PropTypes.func,
  darkMode: PropTypes.bool,
  mobile: PropTypes.bool,
};
EmployerSideNotification.defaultProps = {
  closePopup: () => {},
  darkMode: false,
  mobile: false,
};

export default EmployerSideNotification;
