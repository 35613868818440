import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import { renderRoutes } from "react-router-config";
import reducers from "./Reducer";
import reactLogger from "./Component/utils/helpers/reactLogger";
import LandingRoutes from "./LandingRoutes";

const baseURL = `${process.env.REACT_APP_HOST}`;
const axiosInstance = axios.create({
  baseURL,
});

try {
  console.log(process.env.NODE_ENV);
  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
  }
} catch (err) {
  reactLogger(err, "SSRClient", "Can't set console log for production");
}

const store = createStore(
  reducers,
  JSON.parse(window.INITIAL_STATE),
  applyMiddleware(thunk.withExtraArgument(axiosInstance))
);

ReactDOM.hydrate(
  <Provider store={store}>
    <BrowserRouter>
      <div>{renderRoutes(LandingRoutes)}</div>
    </BrowserRouter>
  </Provider>,

  document.querySelector("#root")
);
