import {
  INC_CURRENT_JOB_HISTORY_PAGE,
  FETCHING_MORE_JOB_HISTORY,
  FETCHING_MORE_JOB_HISTORY_FAILED,
  CURRENT_JOB_HISTORY_PAGE_LAST,
  ADD_NEW_JOB_HISTORY,
  REFETCH_JOB_HISTORY,
  GET_JOB_HISTORY,
} from "./types";
import reactLogger from "../Component/utils/helpers/reactLogger";

export const addNewJobHistory = (newJobHistory) => async (dispatch) => {
  try {
    dispatch({ type: ADD_NEW_JOB_HISTORY, payload: newJobHistory });
  } catch (err) {
    reactLogger(err, "JOB_HISTORYActions", "Redux: failed to add job");
  }
};
export const fetchJobHistory =
  () => async (dispatch, getState, axiosInstance) => {
    try {
      dispatch({
        type: FETCHING_MORE_JOB_HISTORY,
      });
      dispatch({
        type: REFETCH_JOB_HISTORY,
      });
      const { jobHistoryMeta } = getState();
      const res = await axiosInstance.get(
        `/api/user/v1/get_job_history?currentPage=${jobHistoryMeta.currentPage}`
      );
      dispatch({
        type: GET_JOB_HISTORY,
        payload: res.data.jobHistory,
      });
      if (
        res.data.jobHistory.length <
        process.env.REACT_APP_GET_JOB_HISTORY_PER_PAGE_LIMIT
      ) {
        dispatch({
          type: CURRENT_JOB_HISTORY_PAGE_LAST,
        });
      } else {
        dispatch({
          type: INC_CURRENT_JOB_HISTORY_PAGE,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_JOB_HISTORY,
        payload: false,
      });
      dispatch({
        type: FETCHING_MORE_JOB_HISTORY_FAILED,
      });
      reactLogger(
        err,
        "jobHistoryActions",
        "Redux: getting all JobHistory has failed"
      );
    }
  };
