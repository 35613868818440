/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable func-style */
/* eslint-disable require-jsdoc */
import Platform from "react-platform-js";
import swishMessage from "../components/SwishMessage";

function findUserInfoNoLib() {
  let M = "";
  if (navigator) {
    const ua = navigator.userAgent;
    let tem;
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return `IE ${tem[1] || ""}`;
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) {
        return tem.slice(1).join(" ").replace("OPR", "Opera");
      }
    }
    tem = ua.match(/version\/(\d+)/);
    if (tem !== null) {
      M.splice(1, 1, tem[1]);
    }
    M = M.join(" ");
    M += " ";
    if (navigator.userAgentData) M += navigator.userAgentData.platform;
  }
  return M;
}
function getUserInfo() {
  if (navigator) {
    try {
      const platformInfo = {
        OS: Platform.OS,
        OSVersion: Platform.OSVersion,
        Browser: Platform.Browser,
        BrowserVersion: Platform.BrowserVersion,
        Engine: Platform.Engine,
        CPU: Platform.CPU,
        DeviceType: Platform.DeviceType,
        DeviceModel: Platform.DeviceModel,
        DeviceVendor: Platform.DeviceVendor,
        UA: Platform.UA,
      };
      return JSON.parse(
        JSON.stringify(platformInfo, Reflect.ownKeys(platformInfo))
      );
    } catch (err) {
      console.log(err);
      return findUserInfoNoLib();
    }
  }
  return "";
}
const reactLogger = (
  log,
  filename = "not_provided",
  message = "not_provided",
  action = "not_provided",
  shouldSwish = false,
  swishMessageText = "",
  logLevel = "error"
) => {
  if (shouldSwish) {
    swishMessage(logLevel, swishMessageText);
  }
  let convertedObj = log;
  try {
    convertedObj = JSON.parse(JSON.stringify(log, Reflect.ownKeys(log)));
  } catch (err) {
    console.log(err);
  }
  try {
    if (!process.env.PORT) {
      const userPlatform = getUserInfo();
      const url = window.location.href;
      fetch("/api/save_app_log", {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          action,
          log: convertedObj,
          filename,
          message,
          logLevel,
          url,
          userPlatform,
        }),
      });
    }
  } catch (err) {
    console.log(err);
  }
  console.log(log);
};

export default reactLogger;
