import PropTypes from "prop-types";
import React, { Component } from "react";

export default class CasualPopup extends Component {
  handleClickOutside = (event) => {
    const { closePopup } = this.props;
    if (this.childCasualPopup && this.childCasualPopup.contains(event.target)) {
      closePopup();
    }
  };

  render() {
    const { content } = this.props;
    return (
      <div
        role="button"
        tabIndex="0"
        onKeyDown={({ key }) => {
          if (key === "Enter") this.handleClickOutside();
        }}
        ref={(ref) => {
          this.childCasualPopup = ref;
        }}
        onClick={this.handleClickOutside}
        className="casualPopup__back"
      >
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
          className="casualPopup util__contemporaryShadow heading__tertiary"
        />
      </div>
    );
  }
}
CasualPopup.defaultProps = {
  content: "",
  closePopup: () => {},
};
CasualPopup.propTypes = {
  content: PropTypes.string,
  closePopup: PropTypes.func,
};
