import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import reactLogger from "../utils/helpers/reactLogger";
import swishMessage from "../utils/components/SwishMessage";
import SimpleLineIcon from "../utils/components/SimpleLineIcons";

export default class EmployerNavigationMenuMobile extends Component {
  constructor(props) {
    super(props);
    const { profilePictureUrl } = this.props;
    this.state = {
      active: 0,
      showCamera: false,
      profilePictureFile: null,
      profilePictureUrl: `${profilePictureUrl}?lastmod=${Date.now()}`,
      isUploadingProfilePicture: false,
    };
  }

  componentDidMount() {
    if (window.location.pathname === "/employer/dashboard")
      this.setState({ active: 0 });
    else if (
      window.location.pathname.includes("/employer/recruit/jobs") ||
      window.location.pathname.includes("/employer/job")
    )
      this.setState({ active: 1 });
    else if (window.location.pathname.includes("/employer/employees"))
      this.setState({ active: 2 });
    else if (window.location.pathname === "/employer/messages")
      this.setState({ active: 3 });
    else if (window.location.pathname === "/employer/calendar")
      this.setState({ active: 4 });
    else if (window.location.pathname === "/employer/profile")
      this.setState({ active: 5 });
    else if (window.location.pathname.includes("/employer/corps"))
      this.setState({ active: 6 });
    else if (window.location.pathname === "/employer/settings/account")
      this.setState({ active: -1 });
    else if (window.location.pathname === "/employer/settings/password")
      this.setState({ active: -2 });
    else this.setState({ active: 0 });
    //
  }

  onTakePhoto = (dataUri) => {
    const arr = dataUri.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], { type: mime });

    this.fileSelectEvent(blob);
    setTimeout(() => {
      this.setState({ showCamera: false });
    }, 1500);
  };

  onCameraError = (error) => {
    console.log(error);
    this.setState({
      showCamera: false,
    });
    swishMessage("error", "Failed to access camera");
  };

  fileSelectEvent = (file) => {
    this.setState(
      {
        profilePictureFile: file,
        isUploadingProfilePicture: true,
      },
      async () => {
        console.log(this.state.isUploadingProfilePicture);
        try {
          const url = "/api/usersettings/changepicture";
          const res = await axios.get(url);
          if (res) {
            if (res.data && res.data.s3SignedImageUploadUrl) {
              this.uploadImageFile(res.data.s3SignedImageUploadUrl);
            } else {
              throw new Error("Something went wrong");
            }
          }
        } catch (err) {
          console.log(err);
          reactLogger(
            err,
            "EmployerNavigationMenuMobile",
            "Getting URL has failed"
          );
          this.setState({
            profilePictureFile: null,
            showCamera: false,
            isUploadingProfilePicture: false,
          });
          swishMessage("error", "Profile picture upload failed.");
        }
      }
    );
  };

  uploadImageFile(signedUrl) {
    const xhr = new XMLHttpRequest();
    const { profilePictureUrl } = this.props;
    xhr.open("PUT", signedUrl, true);
    xhr.setRequestHeader("Content-Type", "image");
    xhr.onload = async () => {
      if (xhr.status === 200) {
        console.log("uploaded");
        this.setState({
          profilePictureFile: null,
          isUploadingProfilePicture: false,
          profilePictureUrl: `${profilePictureUrl}?lastmod=${Date.now()}`,
        });
        swishMessage("success", "Profile picture updated successfuly.");
      }
    };
    xhr.onerror = () => {
      swishMessage("error", "Profile picture upload failed.");
    };
    xhr.send(this.state.profilePictureFile);
  }

  renderNavigationMenu = () => {
    return (
      <div className="header__controlpane__navigationMenu">
        <Link
          className={
            this.state.active === 0
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/employer/dashboard"
        >
          <SimpleLineIcon name="compass" />
          <div style={{ marginLeft: "3rem" }}>Dashboard</div>
        </Link>
        <Link
          className={
            this.state.active === 1
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/employer/recruit/jobs"
        >
          <SimpleLineIcon name="briefcase" />
          <div style={{ marginLeft: "3rem" }}>My Job Postings</div>
        </Link>
        <Link
          className={
            this.state.active === 2
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/employer/employees/list"
        >
          <SimpleLineIcon name="people" />
          <div style={{ marginLeft: "3rem" }}>Employees</div>
        </Link>
        <Link
          onClick={this.props.messagesSeenHandler}
          className={
            this.state.active === 3
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/employer/messages"
        >
          <SimpleLineIcon name="speech" />
          <div style={{ marginLeft: "3rem" }}>
            Messages
            {this.props.messagesSeen ? (
              ""
            ) : (
              <span className="dropDownNotifications__unreadMarker" />
            )}
          </div>
        </Link>
        <Link
          className={
            this.state.active === 4
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/employer/calendar"
        >
          <SimpleLineIcon name="calendar" />
          <div style={{ marginLeft: "3rem" }}>My Calendar</div>
        </Link>

        <Link
          className={
            this.state.active === 5
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/employer/corps"
        >
          <SimpleLineIcon name="organization" />
          <div style={{ marginLeft: "3rem" }}>Teams</div>
        </Link>
        <Link
          className={
            this.state.active === 6
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/employer/analytics"
        >
          <SimpleLineIcon name="graph" />
          <div style={{ marginLeft: "3rem" }}>Analytics</div>
        </Link>
      </div>
    );
  };

  renderMobileNavigationMenu = () => {
    return (
      <div className="header__navigationMenu__mobile">
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/dashboard"
        >
          <div
            id="nav__dashboard"
            className={
              this.state.active === 0
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="compass" />
            <div style={{ marginLeft: "2rem" }}>Dashboard</div>
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/recruit/jobs"
        >
          <div
            id="nav__jobs"
            className={
              this.state.active === 1
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="briefcase" />
            <div style={{ marginLeft: "2rem" }}>Recruit</div>
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/employees/list"
        >
          <div
            id="nav__jobs"
            className={
              this.state.active === 2
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="people" />
            <div style={{ marginLeft: "2rem" }}>Employees</div>
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/messages"
        >
          <div
            id="nav__profile"
            className={
              this.state.active === 3
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="speech" />
            <div style={{ marginLeft: "2rem" }}>Messages</div>
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/employer/calendar"
        >
          <div
            id="nav__conf"
            className={
              this.state.active === 4
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon name="calendar" />
            <div style={{ marginLeft: "2rem" }}>My Calendar</div>
          </div>
        </Link>
      </div>
    );
  };

  getUploadProfilePictureButton = () => {
    const { isUploadingProfilePicture } = this.state;
    console.log({ isUploadingProfilePicture });
    let render = (
      <div className="btn btn__active btn__primary settings__navigator--item--sub">
        <input
          type="file"
          name="avatar"
          accept="image/*"
          id="avatarSelectMobile"
          onChange={(e) => {
            this.fileSelectEvent(e.target.files[0]);
            e.preventDefault();
          }}
          style={{ display: "none" }}
        />
        <label className="util__cursorPointer" htmlFor="avatar">
          Upload picture
        </label>
      </div>
    );
    if (isUploadingProfilePicture) {
      render = (
        <div className="btn btn__waiting settings__navigator--item--sub">
          Uploading
        </div>
      );
    }
    console.log({ render });
    return render;
  };

  renderSettingsMenu = () => {
    const { profilePictureUrl } = this.state;
    return (
      <>
        {this.state.showCamera && (
          <div className="settings__camera">
            <Camera
              onTakePhoto={(dataUri) => {
                this.onTakePhoto(dataUri);
              }}
              onCameraError={(error) => {
                this.onCameraError(error);
              }}
              idealFacingMode={FACING_MODES.USER}
              idealResolution={{ width: 640, height: 480 }}
              imageType={IMAGE_TYPES.JPG}
              imageCompression={0.97}
              isMaxResolution={false}
              isImageMirror={false}
              isDisplayStartCameraError
              sizeFactor={1}
            />
            <div
              className="settings__camera--close heading__tertiary"
              onClick={() => {
                this.setState({ showCamera: false });
              }}
            />
          </div>
        )}
        <div className="settings__navigator__shrunk">
          <div className="settings__navigator--item">
            <div
              alt="avatar"
              ref={(ref) => (this.profilePicturePreview = ref)}
              className="settings__navigator--item--sub settings__navigator--item--img"
              style={{
                backgroundImage: `url("${profilePictureUrl}"), url("https://i.ibb.co/qy1mkfK/Recruitment-Icon.png")`,
              }}
            />
            {this.getUploadProfilePictureButton()}

            <div
              onClick={() => {
                this.setState({ showCamera: true });
              }}
              className="settings__navigator--item--sub btn btn__transparent btn__primaryBorders"
            >
              Take photo
            </div>
          </div>
          <Link className="util__noStyleLink" to="/employer/settings/account">
            <div
              className={
                this.state.active === -1
                  ? "settings__navigator--item settings__navigator--item--active"
                  : "settings__navigator--item settings__navigator--item--inactive"
              }
            >
              Account settings
            </div>
          </Link>
          <Link className="util__noStyleLink" to="/employer/settings/password">
            <div
              className={
                this.state.active === -2
                  ? "settings__navigator--item settings__navigator--item--active"
                  : "settings__navigator--item settings__navigator--item--inactive"
              }
            >
              Password
            </div>
          </Link>
        </div>
      </>
    );
  };

  render() {
    if (this.props.mobile)
      return this.state.active < 0
        ? this.renderSettingsMenu()
        : this.renderMobileNavigationMenu();
    return this.state.active < 0
      ? this.renderSettingsMenu()
      : this.renderNavigationMenu();
  }
}
