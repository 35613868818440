import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class EmployerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.childEmployerMenu &&
      !this.childEmployerMenu.contains(event.target)
    ) {
      this.props.closePopup();
    }
  };

  render() {
    if (this.props.mobile)
      return (
        <div
          style={{ width: "100%" }}
          className="header__userMenu__container__mobile"
        >
          <button
            onClick={() => {
              this.setState({ logoutLoading: true });
              this.props.logout();
            }}
            id="userMenuButtonLoader"
            className={
              this.state.logoutLoading
                ? "btn btn__wide btn__primary btn__loading"
                : "btn btn__wide btn__primary"
            }
          >
            Logout
          </button>
        </div>
      );
    return (
      <div ref={(ref) => (this.childEmployerMenu = ref)} className="">
        <div className="header__userMenu--item">
          <div className="header__userMenu--item">
            {this.props.user.companyName}
          </div>
          <div className="header__userMenu--item ">
            <Link
              className="util__noStyleLink util__colorDark"
              to="/employer/profile"
            >
              View Profile
            </Link>
          </div>
          <div className="header__userMenu--item ">
            <Link
              className="util__noStyleLink util__colorDark"
              to="/employer/settings"
            >
              Settings
            </Link>
          </div>

          <button
            onClick={() => {
              this.setState({ logoutLoading: true });
              this.props.logout();
            }}
            id="userMenuButtonLoader"
            className={
              this.state.logoutLoading
                ? "btn btn__primary btn__loading"
                : "btn btn__primary"
            }
          >
            Logout
          </button>
        </div>
      </div>
    );
  }
}
