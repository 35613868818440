import React from "react";
import PropTypes from "prop-types";

const ErrorPlaceHolderScreen = function ErrorPlaceHolderScreen({
  actionButtonClassName,
  actionButtonLabel,
  bgSrc,
  errorMessage,
  onClickRetry,
  width,
}) {
  return (
    <div className="errorPlaceHolderScreen__container">
      <img
        alt="error-img-placeholder"
        src={bgSrc || "/images/teams_bg_error.svg"}
        style={width ? { width } : ""}
        className="errorPlaceHolderScreen--img"
      />
      <div className="errorPlaceHolderScreen__error">
        <p className="errorPlaceHolderScreen__error--msg">{errorMessage}</p>
        {onClickRetry !== null && (
          <button
            className={actionButtonClassName}
            type="button"
            onClick={onClickRetry}
          >
            {actionButtonLabel}
          </button>
        )}
      </div>
    </div>
  );
};

ErrorPlaceHolderScreen.defaultProps = {
  actionButtonClassName: "btn btn__link",
  actionButtonLabel: "Retry",
  bgSrc: null,
  onClickRetry: null,
  errorMessage: "Something went wrong",
  width: "50%",
};
ErrorPlaceHolderScreen.propTypes = {
  actionButtonClassName: PropTypes.string,
  actionButtonLabel: PropTypes.string,
  bgSrc: PropTypes.string,
  onClickRetry: PropTypes.func,
  errorMessage: PropTypes.string,
  width: PropTypes.string,
};
export default ErrorPlaceHolderScreen;
