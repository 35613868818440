import React, { useState } from "react";
import PropTypes from "prop-types";
import SimpleLineIcon from "./SimpleLineIcons";

const PasswordInput = function PasswordInput({
  domId,
  className,
  type,
  name,
  placeholder,
  hasLabel,
  value,
  onChange,
  labelText,
  labelPosBefore,
  onKeyDown,
}) {
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <div className="input__control">
      {hasLabel && labelPosBefore && (
        <label className="input__label" htmlFor={domId}>
          {labelText}
        </label>
      )}
      <input
        id={domId}
        className={className}
        onKeyDown={onKeyDown}
        type={passwordShown ? "text" : type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {hasLabel && !labelPosBefore && (
        <label className="input__label" htmlFor={domId}>
          {labelText}
        </label>
      )}
      <div className="input__password__visibilityToggle">
        <SimpleLineIcon
          color="inherit"
          onClick={() => setPasswordShown(!passwordShown)}
          name="eye"
        />
        {!passwordShown && (
          <div className="input__password__visibilityToggle--cut" />
        )}
      </div>
    </div>
  );
};

PasswordInput.propTypes = {
  domId: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  hasLabel: PropTypes.bool,
  labelText: PropTypes.string,
  labelPosBefore: PropTypes.bool,
};
PasswordInput.defaultProps = {
  domId: "passwordInput",
  className: "",
  type: "password",
  name: "password",
  placeholder: "Password",
  value: "",
  onChange: () => {},
  onKeyDown: () => {},
  hasLabel: false,
  labelText: "Password",
  labelPosBefore: false,
};

export default PasswordInput;
