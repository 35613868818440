import React, { Component } from "react";
import qs from "qs";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { fetchUser, logoutUser } from "../../Action";
import LoginPopup from "./LoginPopup";
import EmployerMenu from "./EmployerMenu";
import ButtonLoader from "../utils/components/ButtonLoader";
import getPlaceholderUrlByGender from "../utils/helpers/getPlaceholderUrlByGender";
import NavigationMenu from "./NavigationMenu";
import NavigationMenuMobile from "./NavigationMenuMobile";
import ToolsMenu from "./ToolsMenu";
import EmployerToolsMenu from "./EmployerToolsMenu";
import EmployerNavigationMenu from "./EmployerNavigationMenu";
import EmployerNavigationMenuMobile from "./EmployerNavigationMenuMobile";
import SubEmployerNavigationMenu from "./SubEmployerNavigationMenu";
import SubEmployerNavigationMenuMobile from "./SubEmployerNavigationMenuMobile";
import reactLogger from "../utils/helpers/reactLogger";
import SimpleLineIcon from "../utils/components/SimpleLineIcons";
import ReferPopup from "./ReferPopup";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginPopupVisible: false,
      openPopupWithSignup: false,
      redirectPath: null,
      successText: "",
      userMenuVisible: false,
    };
    this.host = "/";
    this.openLoginPopup = this.openLoginPopup.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.openSignupPopup = this.openSignupPopup.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.moveToLogin = this.moveToLogin.bind(this);
  }

  async componentDidMount() {
    const {
      fetchUser: propsFetchUser,
      location,
      noButtons,
      openLoginPopup,
    } = this.props;
    propsFetchUser();
    if (openLoginPopup) this.openLoginPopup();
    if (!noButtons) {
      // this.handleWindowScroll();
    }
    if (location && location.search) {
      const values = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      if (values && values.redir) {
        this.setState({ redirectPath: values.redir });
      }
    }
  }

  componentDidUpdate() {
    const { auth } = this.props;
    if (window && auth) {
      const { location } = window;
      let { redirectPath } = this.state;
      if (location.pathname === this.host) {
        if (auth.type && !redirectPath) {
          if (auth.type === "user") {
            redirectPath = "/dashboard";
          } else if (auth.type === "employer" || auth.type === "subemployer") {
            redirectPath = "/employer/dashboard";
          }
        }
      }
      if (redirectPath) {
        location.href = redirectPath;
      }
    }
  }

  openLoginPopup() {
    this.setState({ loginPopupVisible: true, openPopupWithSignup: false });
  }

  openReferralPopup() {
    this.setState({ referPopupVisible: true });
  }

  toggleUserMenu() {
    if (!this.state.userMenuVisible) {
      this.setState({ userMenuVisible: true });
    } else {
      this.setState({ userMenuVisible: false });
    }
  }

  openSignupPopup() {
    this.setState({ loginPopupVisible: true, openPopupWithSignup: true });
  }

  handleLogout() {
    this.props.logoutUser();
  }

  moveToLogin(successText) {
    this.setState({ successText });
  }

  handleScrollButtonScroll = () => {
    const scrollDistance =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollDistance > 50) {
      if (document.getElementById("landing__scrollButton"))
        document
          .getElementById("landing__scrollButton")
          .classList.add("util__displayNone");
    } else if (document.getElementById("landing__scrollButton"))
      document
        .getElementById("landing__scrollButton")
        .classList.remove("util__displayNone");
  };

  headerScrollChangeHandler = () => {
    const scrollDistance =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollDistance > 350) {
      if (document.getElementById("header__landing"))
        document
          .getElementById("header__landing")
          .classList.add("header--scrolled");
      if (document.getElementById("header__landing--alt"))
        document
          .getElementById("header__landing--alt")
          .classList.add("header--scrolled--material");
    } else {
      if (document.getElementById("header__landing"))
        document
          .getElementById("header__landing")
          .classList.remove("header--scrolled");
      if (document.getElementById("header__landing--alt"))
        document
          .getElementById("header__landing--alt")
          .classList.remove("header--scrolled--material");
    }
  };

  handleWindowScroll = () => {
    window.onscroll = () => {
      this.headerScrollChangeHandler();
      this.handleScrollButtonScroll();
    };
  };

  renderLoader() {
    if (this.props.employerLanding)
      return (
        <div
          id="header__landing"
          style={!this.props.blocked ? { position: "unset" } : {}}
          className="header"
        >
          <Link to="/" className="header__logo">
            <img
              className="header__logo--full"
              alt="logo"
              src="/images/logoEmployer.png"
            />
            <img
              alt="logo"
              className="header__logo--short"
              src={process.env.REACT_APP_LOGO_MOBILE}
            />
          </Link>
          <div className="">
            <ButtonLoader size="3rem" />
          </div>
        </div>
      );
    if (this.props.noButtons)
      return (
        <div
          id="header__landing"
          style={
            !this.props.blocked
              ? { position: "unset", flexDirection: "row-reverse" }
              : { flexDirection: "row-reverse" }
          }
          className="header"
        >
          <Link to="/" className="header__logo">
            <img
              className="header__logo--full"
              alt="logo"
              src="/images/logoEmployer.png"
            />
            <img
              alt="logo"
              className="header__logo--short"
              src={process.env.REACT_APP_LOGO_MOBILE}
            />
          </Link>
          <div className="">
            <ButtonLoader size="3rem" />
          </div>
        </div>
      );
    return (
      <div
        id={this.props.landing ? "header__landing" : ""}
        className={this.props.landing ? "header" : "header header--dark"}
      >
        <Link to="/" className="header__logo">
          <img
            className="header__logo--full"
            alt="logo"
            src={process.env.REACT_APP_LOGO_LARGE}
          />
          <img
            alt="logo"
            className="header__logo--short"
            src={process.env.REACT_APP_LOGO_MOBILE}
          />
        </Link>
        <div className="header__mobileLoader">
          <ButtonLoader size="3rem" />
        </div>
        <div className="header__employer">
          <a
            href="/employer/login"
            className="header__employer--link util__noStyleLink"
          >
            Employer Login
          </a>
        </div>
        <div id="Options" className="header__buttons">
          <div style={{ marginLeft: "2rem" }} className="">
            <ButtonLoader size="3rem" />
          </div>
        </div>
      </div>
    );
  }

  renderLoggedOutUserHeader() {
    const { onlyUser, referralButtonVisible } = this.props;
    if (this.props.employerLanding) {
      // this.handleWindowScroll();
      return (
        <div
          id="header__landing"
          style={!this.props.blocked ? { position: "unset" } : {}}
          className="header"
        >
          <Link to="/" className="header__logo">
            <img
              className="header__logo--full"
              alt="logo"
              src="/images/logoEmployer.png"
            />
            <img
              alt="logo"
              className="header__logo--short"
              src={process.env.REACT_APP_LOGO_MOBILE}
            />
          </Link>
          <div className="header__burgerMenu">
            <label
              className="header__burgerMenu--check--label"
              htmlFor="header__burgerMenu--check"
            />
          </div>
          <input type="checkbox" id="header__burgerMenu--check" />
          <div className="header__burgerMenu__links">
            <div className="header__burgerMenu__links__header" />
            <div className="header__burgerMenu__links__linksContainer">
              <a
                style={{ display: "block", width: "100%" }}
                href="/employer/login"
                className="btn btn__primaryBorders btn__transparent util__noStyleLink util__colorLight header__burgerMenu__links--link"
              >
                Employer Login
              </a>
              <button
                onClick={() => {
                  if (document.getElementById("employerLandingSignup")) {
                    window.scroll({
                      behavior: "smooth",
                      left: 0,
                      top:
                        document.getElementById("employerLandingSignup")
                          .offsetTop - 100,
                    });
                  }
                }}
                style={{ width: "100%" }}
                id="headerButtonSignup"
                type="button"
                className="btn btn__primary header__burgerMenu__links--link"
              >
                Signup
              </button>
            </div>
          </div>
          <div
            id="Options"
            style={{ borderLeft: "none" }}
            className="header__buttons"
          >
            <a
              style={{ display: "block" }}
              href="/employer/login"
              className="btn btn__primaryBorders util__noStyleLink util__colorLight btn__transparent header__buttons--employerLogin"
            >
              Login
            </a>
            <button
              onClick={() => {
                if (document.getElementById("employerLandingSignup")) {
                  window.scroll({
                    behavior: "smooth",
                    left: 0,
                    top:
                      document.getElementById("employerLandingSignup")
                        .offsetTop - 100,
                  });
                }
              }}
              id="headerButtonSignup"
              type="button"
              className="btn btn__primary"
            >
              Signup
            </button>
          </div>
        </div>
      );
    }
    if (this.props.noButtons)
      return (
        <div
          id="header__landing"
          style={
            !this.props.blocked
              ? { position: "unset", flexDirection: "row-reverse" }
              : { flexDirection: "row-reverse" }
          }
          className="header"
        >
          <Link to="/" className="header__logo">
            <img
              className="header__logo--full"
              alt="logo"
              src="/images/logoEmployer.png"
            />
            <img
              alt="logo"
              className="header__logo--short"
              src={process.env.REACT_APP_LOGO_MOBILE}
            />
          </Link>
          <div className="header__burgerMenu">
            <label
              className="header__burgerMenu--check--label"
              htmlFor="header__burgerMenu--check"
            />
          </div>
          <input type="checkbox" id="header__burgerMenu--check" />
          <div className="header__burgerMenu__links">
            <div className="header__burgerMenu__links__header" />
          </div>
        </div>
      );
    if (this.props.employerLandingAlt)
      return (
        <div
          id="header__landing--alt"
          style={!this.props.blocked ? { position: "unset" } : {}}
          className="header header--landing--alt"
        >
          <div className="header__content">
            <Link to="/us" className="header__logo">
              <img
                className="header__logo--full"
                alt="logo"
                style={{ height: "9rem" }}
                src="https://i.ibb.co/hm0scxb/logoDark.png"
              />
              <img
                alt="logo"
                className="header__logo--short"
                src={process.env.REACT_APP_LOGO_MOBILE_DARK}
              />
            </Link>
          </div>
        </div>
      );
    if (this.props.isFreeFloat) {
      return (
        <>
          <div className="header header--floating">
            <div className="header__burgerMenu">
              <label
                className="header__burgerMenu--check--label"
                htmlFor="header__burgerMenu--check"
              />
            </div>
            <input type="checkbox" id="header__burgerMenu--check" />
            <div className="header__burgerMenu__links">
              <div className="header__burgerMenu__links__linksContainer">
                {!onlyUser && (
                  <a
                    style={{ display: "block" }}
                    href="/employer/login"
                    className="header__text header__text--light header__burgerMenu__links--link"
                  >
                    Employer Login
                  </a>
                )}
                {referralButtonVisible && (
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={({ key }) => {
                      if (key === "Enter" || key === "Space") {
                        this.openReferralPopup();
                      }
                    }}
                    onClick={() => {
                      this.openReferralPopup();
                    }}
                    className="header__text header__text--light header__burgerMenu__links--link"
                  >
                    Refer Candidate
                  </div>
                )}
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={({ key }) => {
                    if (key === "Enter" || key === "Space") {
                      this.openLoginPopup();
                    }
                  }}
                  onClick={() => {
                    this.openLoginPopup();
                  }}
                  className="header__text header__text--light header__burgerMenu__links--link"
                >
                  Candidate Login
                </div>
                <button
                  onClick={this.openSignupPopup}
                  type="button"
                  className="btn btn__primary btn__active header__burgerMenu__links--link header__text"
                >
                  Signup
                </button>
              </div>
            </div>
            <div className="header__employer">
              {!onlyUser && (
                <a
                  href="/employer/login"
                  className="header__employer--link util__noStyleLink"
                >
                  Employer Login
                </a>
              )}
              {referralButtonVisible && (
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={({ key }) => {
                    if (key === "Enter" || key === "Space") {
                      this.openReferralPopup();
                    }
                  }}
                  onClick={() => {
                    this.openReferralPopup();
                  }}
                  className="header__text header__text--light"
                >
                  Refer Candidate
                </div>
              )}
            </div>
            <div id="Options" className="header__buttons">
              <div
                id="headerButtonLogin"
                onClick={this.openLoginPopup}
                className="header__text header__text--light"
              >
                Candidate Login
              </div>
              <button
                id="headerButtonSignup"
                onClick={this.openSignupPopup}
                type="button"
                className="btn btn__hoverPrimary"
              >
                Signup
              </button>
            </div>
          </div>
          {this.state.loginPopupVisible && (
            <LoginPopup
              ref={(ref) => (this.loginPopupChild = ref)}
              closePopup={() => this.setState({ loginPopupVisible: false })}
              successText={this.state.successText}
              openPopupWithSignup={this.state.openPopupWithSignup}
            />
          )}
          {this.state.referPopupVisible && (
            <ReferPopup
              closePopup={() => this.setState({ referPopupVisible: false })}
            />
          )}
        </>
      );
    }
    // this.handleWindowScroll();

    return (
      <>
        <div
          id={this.props.landing ? "header__landing" : ""}
          className={this.props.landing ? "header" : "header header--dark"}
        >
          <Link to="/" className="header__logo">
            <img
              className="header__logo--full"
              alt="logo"
              src={process.env.REACT_APP_LOGO_LARGE}
            />
            <img
              alt="logo"
              className="header__logo--short"
              src={process.env.REACT_APP_LOGO_MOBILE}
            />
          </Link>
          <div className="header__burgerMenu">
            <label
              className="header__burgerMenu--check--label"
              htmlFor="header__burgerMenu--check"
            />
          </div>
          <input type="checkbox" id="header__burgerMenu--check" />
          <div className="header__burgerMenu__links">
            <div className="header__burgerMenu__links__header" />
            <div className="header__burgerMenu__links__linksContainer">
              {!onlyUser && (
                <a
                  style={{ display: "flex" }}
                  href="/employer/login"
                  className="header__burgerMenu__links--link"
                >
                  <SimpleLineIcon
                    style={{ marginRight: "2rem" }}
                    name="briefcase"
                    color="#ffffff"
                  />
                  Employer Login
                </a>
              )}
              <div
                onClick={this.openLoginPopup}
                className="header__burgerMenu__links--link"
              >
                <SimpleLineIcon
                  style={{ marginRight: "2rem" }}
                  name="login"
                  color="#ffffff"
                />
                Candidate Login
              </div>
              <button
                onClick={this.openSignupPopup}
                type="button"
                className="btn btn__wide btn__primary btn__active header__burgerMenu__links--link"
              >
                Signup
              </button>
            </div>
          </div>
          <div className="header__employer">
            {!onlyUser && (
              <a
                href="/employer/login"
                className="header__employer--link util__noStyleLink"
              >
                Employer Login
              </a>
            )}
          </div>
          <div id="Options" className="header__buttons">
            <div
              tabIndex={0}
              role="button"
              onKeyDown={({ key }) => {
                if (key === " ") {
                  this.openLoginPopup();
                }
              }}
              id="headerButtonLogin"
              onClick={this.openLoginPopup}
              className="header__text header__text--light"
            >
              Candidate Login
            </div>
            <button
              id="headerButtonSignup"
              onClick={this.openSignupPopup}
              type="button"
              className="btn btn__hoverPrimary"
            >
              Signup
            </button>
          </div>
        </div>
        {this.state.loginPopupVisible && (
          <LoginPopup
            ref={(ref) => (this.loginPopupChild = ref)}
            closePopup={() => this.setState({ loginPopupVisible: false })}
            successText={this.state.successText}
            openPopupWithSignup={this.state.openPopupWithSignup}
          />
        )}
      </>
    );
  }

  renderLoggedInUserHeader() {
    /* Due to change in design employer header serves as employer control(left-side) pane */
    return (
      <>
        <div className="header__mobile__employer">
          <div className="header__burgerMenu">
            <label
              className="header__burgerMenu--check--label"
              htmlFor="header__burgerMenu--check"
            >
              <span />
              <span />
            </label>
          </div>
          <input type="checkbox" id="header__burgerMenu--check" />
          <div className="header__burgerMenu__links">
            <ToolsMenu
              mobile
              isBackButtonVisible={this.props.isBackButtonVisible}
              notificationSeen={this.props.auth.notificationSeen}
              notificationSeenHandler={async () => {
                try {
                  await axios.post("/api/employer/notificationSeen");
                  this.props.fetchUser();
                } catch (err) {
                  reactLogger(
                    err,
                    "Header",
                    "Posting of Notification Seen has failed"
                  );
                }
              }}
            />
            <div className="header__burgerMenu__links__linksContainer">
              <div
                style={{ width: "100%" }}
                className="header__burgerMenu__links--link"
              >
                <NavigationMenuMobile
                  mobile
                  shrunk={this.props.shrunk}
                  profilePictureUrl={this.props.auth.profilePictureUrl}
                  active={this.props.active}
                />
              </div>
              <div
                style={{ width: "100%" }}
                className="header__burgerMenu__links--link"
              >
                <EmployerMenu
                  mobile
                  user={this.props.auth}
                  logout={() => this.handleLogout()}
                  closePopup={() => {
                    this.setState({ userMenuVisible: false });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          id="header"
          className="header__controlpane header__controlpane__expanded"
        >
          <ToolsMenu
            shrunk={this.props.shrunk}
            notificationSeen={this.props.auth.notificationSeen}
            notificationSeenHandler={async () => {
              try {
                await axios.post("/api/user/notificationSeen");
                this.props.fetchUser();
              } catch (err) {
                reactLogger(
                  err,
                  "Header",
                  "Posting of Notification Seen has failed"
                );
              }
            }}
          />
          {!this.props.shrunk && (
            <div className="header__controlpane__info">
              <div
                className="header__controlpane__info--img"
                alt="avatar"
                style={{
                  backgroundImage: `url("${
                    this.props.auth.profilePictureUrl
                  }"), url("${getPlaceholderUrlByGender(
                    this.props.auth.gender
                  )}")`,
                }}
              />
              <div className="header__controlpane__info--name">
                {this.props.auth.name}
              </div>
            </div>
          )}
          <NavigationMenu
            shrunk={this.props.shrunk}
            profilePictureUrl={this.props.auth.profilePictureUrl}
            isEmployee={this.props.auth.isEmployee}
            active={this.props.active}
          />
        </div>
      </>
    );
  }

  renderLoggedInSubEmployerHeader() {
    const { permissions } = this.props.auth;
    /* Due to change in design employer header serves as employer control(left-side) pane */
    return (
      <>
        <div className="header__mobile__employer">
          <div className="header__burgerMenu">
            <label
              className="header__burgerMenu--check--label"
              htmlFor="header__burgerMenu--check"
            >
              <span />
              <span />
            </label>
          </div>
          <input type="checkbox" id="header__burgerMenu--check" />
          <div className="header__burgerMenu__links">
            <EmployerToolsMenu mobile />
            <div className="header__burgerMenu__links__linksContainer">
              <div className="header__burgerMenu__links--link">
                <SubEmployerNavigationMenuMobile
                  profilePictureUrl={this.props.auth.profilePictureUrl}
                  permissions={this.props.auth.permissions}
                  mobile
                  active={this.props.active}
                />
              </div>
              <div className="header__burgerMenu__links--link">
                <EmployerMenu
                  mobile
                  user={this.props.auth}
                  logout={() => this.handleLogout()}
                  closePopup={() => {
                    this.setState({ userMenuVisible: false });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="header" className="header__controlpane">
          <EmployerToolsMenu />
          <SubEmployerNavigationMenu
            shrunk={this.props.shrunk}
            permissions={permissions}
            profilePictureUrl={this.props.auth.profilePictureUrl}
            messagesSeen={this.props.auth.messagesSeen}
            messagesSeenHandler={async () => {
              try {
                await axios.post("/api/employer/messagesSeen");
                this.props.fetchUser();
              } catch (err) {
                reactLogger(
                  err,
                  "Header",
                  "Posting of Messeges Seen has failed"
                );
              }
            }}
            active={this.props.active}
          />
        </div>
      </>
    );
  }

  renderLoggedInEmployerHeader() {
    /* Due to change in design employer header serves as employer control(left-side) pane */
    const { active, auth, fetchUser: fetchUserProps, shrunk } = this.props;
    return (
      <>
        <div className="header__mobile__employer">
          <div className="header__burgerMenu">
            <label
              className="header__burgerMenu--check--label"
              htmlFor="header__burgerMenu--check"
            >
              <span />
              <span />
            </label>
          </div>
          <input type="checkbox" id="header__burgerMenu--check" />
          <div className="header__burgerMenu__links">
            <EmployerToolsMenu mobile active={active} />
            <div className="header__burgerMenu__links__linksContainer">
              <div className="header__burgerMenu__links--link">
                <EmployerNavigationMenuMobile
                  profilePictureUrl={auth.profilePictureUrl}
                  mobile
                />
              </div>
              <div className="header__burgerMenu__links--link">
                <EmployerMenu
                  mobile
                  user={auth}
                  logout={() => this.handleLogout()}
                  closePopup={() => {
                    this.setState({ userMenuVisible: false });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="header" className="header__controlpane">
          <EmployerToolsMenu />
          <EmployerNavigationMenu
            shrunk={shrunk}
            profilePictureUrl={auth.profilePictureUrl}
            messagesSeen={auth.messagesSeen}
            messagesSeenHandler={async () => {
              try {
                await axios.post("/api/employer/messagesSeen");
                fetchUserProps();
              } catch (err) {
                reactLogger(
                  err,
                  "Header",
                  "Posting of Messeges Seen has failed"
                );
              }
            }}
            active={active}
          />
        </div>
      </>
    );
  }

  render() {
    const { auth } = this.props;
    if (typeof auth === "undefined" || auth === null) {
      return this.renderLoader();
    }
    if (typeof auth.email !== "undefined") {
      if (auth.type === "user") return this.renderLoggedInUserHeader();
      if (auth.type === "subemployer")
        return this.renderLoggedInSubEmployerHeader();
      if (auth.type === "employer") return this.renderLoggedInEmployerHeader();
    }
    return this.renderLoggedOutUserHeader();
  }
}
function mapStateToProps({ auth }) {
  return { auth };
}

Header.defaultProps = {
  active: false,
  // eslint-disable-next-line react/forbid-prop-types
  auth: null,
  blocked: false,
  employerLanding: false,
  employerLandingAlt: false,
  fetchUser: () => {},
  isBackButtonVisible: false,
  isFreeFloat: false,
  landing: false,
  location: {},
  logoutUser: () => {},
  noButtons: false,
  openLoginPopup: false,
  shrunk: false,
};
Header.propTypes = {
  active: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  auth: PropTypes.any,
  blocked: PropTypes.bool,
  employerLanding: PropTypes.bool,
  employerLandingAlt: PropTypes.bool,
  fetchUser: PropTypes.func,
  isBackButtonVisible: PropTypes.bool,
  isFreeFloat: PropTypes.bool,
  landing: PropTypes.bool,
  location: PropTypes.objectOf(PropTypes.any),
  logoutUser: PropTypes.func,
  noButtons: PropTypes.bool,
  openLoginPopup: PropTypes.bool,
  shrunk: PropTypes.bool,
};

export default connect(mapStateToProps, { logoutUser, fetchUser }, null, {
  withRef: true,
})(Header);
