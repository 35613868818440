import { CURRENT_JOB_POST, CURRENT_JOB_POST_UNDEF } from "../Action/types";

export default (state = null, action) => {	
	switch (action.type) {
		case CURRENT_JOB_POST:
			return action.payload;
		case CURRENT_JOB_POST_UNDEF:
			return action.payload;
		default:
			return state;
	}
};
