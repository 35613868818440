import getInitialMode from "../Component/utils/helpers/getInitialMode";
import { DARK_MODE_SETTING } from "./types";

export const setDarkMode = (mode) => (dispatch) => {
  let darkModePref = false;
  if (typeof mode === "boolean") {
    darkModePref = mode;
  } else darkModePref = getInitialMode();
  if (localStorage) {
    try {
      localStorage.setItem("dark", JSON.stringify(darkModePref));
    } catch (err) {
      console.log("gerror in setting darkmode", err);
    }
    if (darkModePref) {
      document.body.classList.add("darkmode");
    } else {
      document.body.classList.remove("darkmode");
    }
    dispatch({
      payload: darkModePref,
      type: DARK_MODE_SETTING,
    });
  }
};
