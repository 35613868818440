import getPrefColorScheme from "./getPrefColorScheme";

export default function getInitialMode() {
  let isReturningUser = false;
  let savedMode = false;
  try {
    if (localStorage) {
      isReturningUser = "dark" in localStorage;
      savedMode =
        JSON.parse(localStorage.getItem("dark")) == "true" ||
        JSON.parse(localStorage.getItem("dark")) == true;
    }
  } catch (err) {
    console.log(err);
  }
  const userPrefersDark = getPrefColorScheme();
  // if mode was saved --> dark / light
  if (isReturningUser) {
    return savedMode;
    // if preferred color scheme is dark --> dark
  }
  if (userPrefersDark) {
    return true;
    // otherwise --> light
  }
  return savedMode || false;
}
