import {
  FETCH_USER,
  UPDATE_USER,
  FETCH_USER_FAILED,
  USER_LOGIN,
  USER_LOGIN_FAILED,
  USER_LOGOUT,
  USER_SIGNUP,
} from "../Action/types";

export default (state = null, action) => {
  switch (action.type) {
    case FETCH_USER:
      if (action.payload === "") {
        return false;
      }
      return { ...state, ...action.payload };
    case UPDATE_USER:
      if (action.payload === "") {
        return false;
      }
      return { ...action.payload, timeStamp: Date.now() };
    case FETCH_USER_FAILED:
      return action.payload;
    case USER_SIGNUP:
      if (action.payload === "" || typeof action.payload === "undefined") {
        return false;
      }
      return { ...action.payload, timeStamp: Date.now() };
    case USER_LOGIN:
      if (action.payload === "" || typeof action.payload === "undefined") {
        return false;
      }
      return { ...action.payload, timeStamp: Date.now() };
    case USER_LOGOUT:
      if (window) window.location.href = "/";
      return false;
    case USER_LOGIN_FAILED:
      return { ...action.payload, timeStamp: Date.now() };
    default:
      return state;
  }
};
