import {
  INC_CURRENT_CHAT_PAGE,
  CURRENT_CHAT_PAGE_LAST,
  DEC_CURRENT_CHAT_PAGE,
  FETCHING_MORE_CHATS,
  FETCHING_MORE_CHATS_FAILED,
} from "../Action/types";

export default (
  // eslint-disable-next-line default-param-last
  state = { currentPage: 1, hasFetchedAllChats: false },
  action
) => {
  switch (action.type) {
    case INC_CURRENT_CHAT_PAGE:
      return {
        currentPage: state.currentPage + 1,
        hasFetchedAllChats: false,
      };
    case CURRENT_CHAT_PAGE_LAST:
      return {
        currentPage: state.currentPage + 1,
        hasFetchedAllChats: true,
      };
    case FETCHING_MORE_CHATS:
      return { currentPage: state.currentPage, hasFetchedAllChats: null };
    case FETCHING_MORE_CHATS_FAILED:
      return {
        currentPage: state.currentPage,
        hasFetchedAllChats: "failed",
      };
    case DEC_CURRENT_CHAT_PAGE:
      return {
        currentPage: state.currentPage + 1,
        hasFetchedAllChats: false,
      };
    default:
      return state;
  }
};
