import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import EmployerSideNotifications from "./DropDownNotifications/EmployerSideNotifications";
import SimpleLineIcon from "../utils/components/SimpleLineIcons";

class ToolsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messagesVisible: false,
      notificationsVisible: false,
    };
  }

  getLatestJob() {
    return this.props.auth.jobPostings.length > 0
      ? this.props.auth.jobPostings[this.props.auth.jobPostings.length - 1]
      : null; // change
  }

  invertMessageVisibility = () => {
    this.setState({ messagesVisible: !this.state.messagesVisible });
  };

  invertNotificationVisibility = () => {
    this.setState({ notificationsVisible: !this.state.notificationsVisible });
  };

  renderUnseenNotifications = () => {
    return this.props.notificationSeen ? (
      <div
        onClick={() => this.invertNotificationVisibility()}
        className="header__toolsMenu--item"
      >
        <SimpleLineIcon name="bell" color="#f1f1f1" />
      </div>
    ) : (
      <div
        onClick={() => {
          this.invertNotificationVisibility();
          this.props.notificationSeenHandler();
        }}
        className="header__toolsMenu--item"
      >
        <SimpleLineIcon name="bell" color="#f1f1f1" />
        <span className="dropDownNotifications__unreadMarker" />
      </div>
    );
  };

  renderSettings = () => {
    return this.props.messagesSeen ? (
      <div
        onClick={() => this.invertMessageVisibility()}
        className="header__toolsMenu--item"
      >
        <SimpleLineIcon name="settings" color="#f1f1f1" />
      </div>
    ) : (
      <div
        onClick={() => {
          this.invertMessageVisibility();
          this.props.messagesSeenHandler();
        }}
        className="header__toolsMenu--item"
      >
        <SimpleLineIcon name="settings" color="#f1f1f1" />
        <span className="dropDownNotifications__unreadMarker" />
      </div>
    );
  };

  render() {
    if (this.props.mobile)
      return (
        <div className="header__controlpane__toolsMenu__user">
          {this.props.isBackButtonVisible && (
            <Link className="util__noStyleLink" to="/dashboard">
              <SimpleLineIcon name="arrow-left" color="#f1f1f1" />
            </Link>
          )}
          {this.state.notificationsVisible ? (
            <>
              <div
                onClick={() => this.invertNotificationVisibility()}
                className="header__controlpane__toolsMenu--item"
              >
                <SimpleLineIcon
                  color="white"
                  name="icon-bell"
                  color="#f1f1f1"
                />
              </div>
              <EmployerSideNotifications
                darkMode={false}
                closePopup={() =>
                  this.setState({ notificationsVisible: false })
                }
              />
            </>
          ) : (
            this.renderUnseenNotifications()
          )}
        </div>
      );
    return (
      <div className="header__controlpane__toolsMenu__user">
        {this.props.shrunk ? (
          <Link className="util__noStyleLink" to="/dashboard">
            <SimpleLineIcon name="arrow-left" color="#f1f1f1" />
          </Link>
        ) : (
          <Link className="util__noStyleLink" to="/settings/account">
            <SimpleLineIcon name="settings" color="#f1f1f1" />
          </Link>
        )}

        {this.state.notificationsVisible ? (
          <>
            <div
              onClick={() => this.invertNotificationVisibility()}
              className="header__controlpane__toolsMenu--item"
            >
              <SimpleLineIcon name="icon-bell" color="#f1f1f1" />
            </div>
            <EmployerSideNotifications
              darkMode={this.props.isDarkMode}
              closePopup={() => this.setState({ notificationsVisible: false })}
            />
          </>
        ) : (
          this.renderUnseenNotifications()
        )}
      </div>
    );
  }
}
function mapStateToProps({ isDarkMode }) {
  return { isDarkMode };
}
export default connect(mapStateToProps)(ToolsMenu);
