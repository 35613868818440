import { ADD_JOB, CLEAR_JOBS } from "./types";

export const addEmployerJob = (jobPost) => {
  return (dispatch) => {
    dispatch({
      type: ADD_JOB,
      payload: jobPost,
    });
  };
};
export const clearJobPosts = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_JOBS,
      payload: [],
    });
  };
};
