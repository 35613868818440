import { SET_CURRENT_SOCKET, SET_CURRENT_SOCKET_UNDEF } from "../Action/types";

export default (state = null, action) => {
	switch (action.type) {
		case SET_CURRENT_SOCKET:
			return action.payload;
		case SET_CURRENT_SOCKET_UNDEF:
			return action.payload;
		default:
			return state;
	}
};
