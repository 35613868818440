import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import reactLogger from "../utils/helpers/reactLogger";
import PopupNotification from "../utils/components/PopupNotification";
import getPlaceholderUrlByGender from "../utils/helpers/getPlaceholderUrlByGender";
import SimpleLineIcon from "../utils/components/SimpleLineIcons";
import swishMessage from "../utils/components/SwishMessage";

export default class NavigationMenu extends Component {
  constructor(props) {
    super(props);
    const { active } = this.props;
    this.state = {
      active,
      showCamera: false,
      profilePictureFile: null,
      profilePictureUrl: "",
    };
  }

  componentDidUpdate(prevProps) {
    const { active: prevActive } = prevProps;
    const { active: stateActive } = this.state;
    const { active } = this.props;
    if (active !== prevActive || active !== stateActive) {
      this.setState({ active });
    }
    if (
      this.state.profilePictureUrl === "" &&
      this.props.profilePictureUrl !== null
    ) {
      if (!this.state.edited) {
        this.setState({
          profilePictureUrl: this.props.profilePictureUrl,
        });
      }
    }
  }

  fileSelectEvent = async (file) => {
    this.setState({ profilePictureFile: file });
    try {
      const url = "/api/usersettings/changepicture";
      const res = await axios.get(url);
      if (res) {
        if (res.data && res.data.s3SignedImageUploadUrl) {
          this.uploadImageFile(res.data.s3SignedImageUploadUrl);
        } else {
          throw new Error("Something went wrong");
        }
      }
    } catch (err) {
      reactLogger(err, "NavigationMenu", "Getting URL has failed");
      this.setState({
        profilePictureFile: null,
        showCamera: false,
      });
      swishMessage("error", "Profile picture file select error");
    }
  };

  uploadImageFile(signedUrl) {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedUrl, true);
    xhr.setRequestHeader("Content-Type", "image");
    xhr.onload = async () => {
      if (xhr.status === 200) {
        console.log("uploaded");
        this.setState({
          profilePictureFile: null,
        });
        swishMessage("success", "Profile picture updated successfully");
        this.profilePicturePreview.style.backgroundImage = `url("${
          this.state.profilePictureUrl
        }?${new Date().getTime()}"), url("${getPlaceholderUrlByGender(
          this.props.auth.gender
        )}")`;
      } else {
        swishMessage("error", "Profile picture upload failed");
      }
    };
    xhr.onerror = () => {
      swishMessage("error", "Profile picture upload failed");
    };
    xhr.send(this.state.profilePictureFile);
  }

  onTakePhoto = (dataUri) => {
    const arr = dataUri.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], { type: mime });

    this.fileSelectEvent(blob);
    setTimeout(() => {
      this.setState({ showCamera: false });
    }, 1500);
  };

  onCameraError = (error) => {
    console.log(error);
    this.setState({
      showCamera: false,
    });
    swishMessage("error", "Failed to access camera");
  };

  componentDidMount() {
    if (window.location.pathname === "/dashboard") this.setState({ active: 0 });
    else if (window.location.pathname.includes("/jobs"))
      this.setState({ active: 1 });
    else if (window.location.pathname === "/profile")
      this.setState({ active: 2 });
    else if (window.location.pathname.includes("/payroll"))
      this.setState({ active: 3 });
    else this.setState({ active: 0 });
    //
    if (this.props.auth !== null) {
      this.setState({
        profilePictureUrl: this.props.profilePictureUrl,
      });
    }
  }

  renderNavigationMenu = () => {
    const { isEmployee } = this.props;
    return (
      <div className="header__controlpane__navigationMenu">
        <Link
          className={
            this.state.active === 0
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/dashboard"
        >
          <SimpleLineIcon
            name="layers"
            color={this.state.active === 0 ? "#3ce2cf" : "#777"}
          />
          <div style={{ marginLeft: "3rem" }}>Dashboard</div>
        </Link>
        <Link
          className={
            this.state.active === 1
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/jobs"
        >
          <SimpleLineIcon
            name="magnifier"
            color={this.state.active === 1 ? "#3ce2cf" : "#777"}
          />
          <div style={{ marginLeft: "3rem" }}>Search Jobs</div>
        </Link>

        <Link
          className={
            this.state.active === 2
              ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
              : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
          }
          to="/profile"
        >
          <SimpleLineIcon
            name="user"
            color={this.state.active === 2 ? "#3ce2cf" : "#777"}
          />
          <div style={{ marginLeft: "3rem" }}>My Profile</div>
        </Link>
        {isEmployee && (
          <Link
            className={
              this.state.active === 3
                ? "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--active util__noStyleLink"
                : "header__controlpane__navigationMenu--item header__controlpane__navigationMenu--item--inactive util__noStyleLink"
            }
            to="/payroll/timesheet"
          >
            <SimpleLineIcon
              name="docs"
              color={this.state.active === 3 ? "#3ce2cf" : "#777"}
            />
            <div style={{ marginLeft: "3rem" }}>Payroll</div>
          </Link>
        )}
      </div>
    );
  };

  renderMobileNavigationMenu = () => {
    return (
      <div className="header__navigationMenu__mobile">
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/dashboard"
        >
          <div
            id="nav__dashboard"
            className={
              this.state.active === 0
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon
              name="layers"
              style={{ marginRight: "3rem" }}
              color={this.state.active === 0 ? "#3ce2cf" : "#777"}
            />
            Dashboard
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/jobs"
        >
          <div
            id="nav__jobs"
            className={
              this.state.active === 1
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon
              style={{ marginRight: "3rem" }}
              name="magnifier"
              color={this.state.active === 1 ? "#3ce2cf" : "#777"}
            />
            Search Jobs
          </div>
        </Link>
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/profile"
        >
          <div
            id="nav__conf"
            className={
              this.state.active === 2
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon
              style={{ marginRight: "3rem" }}
              name="user"
              color={this.state.active === 2 ? "#3ce2cf" : "#777"}
            />
            My Profile
          </div>
        </Link>
        {process.env.REACT_APP_HAS_PAYROLL_ENABLED && (
          <Link
            className="header__navigationMenu__mobile--item util__noStyleLink"
            to="/payroll/timesheet"
          >
            <div
              id="nav__conf"
              className={
                this.state.active === 3
                  ? "header__navigationMenu__mobile--item--active"
                  : "header__navigationMenu__mobile--item--inactive"
              }
            >
              <SimpleLineIcon
                style={{ marginRight: "3rem" }}
                name="user"
                color={this.state.active === 3 ? "#3ce2cf" : "#777"}
              />
              payroll
            </div>
          </Link>
        )}
        <Link
          className="header__navigationMenu__mobile--item util__noStyleLink"
          to="/settings/account"
        >
          <div
            id="nav__conf"
            className={
              this.state.active === 4
                ? "header__navigationMenu__mobile--item--active"
                : "header__navigationMenu__mobile--item--inactive"
            }
          >
            <SimpleLineIcon
              style={{ marginRight: "3rem" }}
              name="settings"
              color={this.state.active === 4 ? "#3ce2cf" : "#777"}
            />
            Settings
          </div>
        </Link>
      </div>
    );
  };

  renderSettingsMenu = () => {
    return (
      <>
        {this.state.showCamera && (
          <div className="settings__camera">
            <Camera
              onTakePhoto={(dataUri) => {
                this.onTakePhoto(dataUri);
              }}
              onCameraError={(error) => {
                this.onCameraError(error);
              }}
              idealFacingMode={FACING_MODES.USER}
              idealResolution={{ width: 640, height: 480 }}
              imageType={IMAGE_TYPES.JPG}
              imageCompression={0.97}
              isMaxResolution={false}
              isImageMirror={false}
              isDisplayStartCameraError
              sizeFactor={1}
            />
            <div
              className="settings__camera--close heading__tertiary"
              onClick={() => {
                this.setState({ showCamera: false });
              }}
            />
          </div>
        )}
        <div className="settings__navigator__shrunk">
          <div className="settings__navigator--item">
            <div
              alt="avatar"
              ref={(ref) => (this.profilePicturePreview = ref)}
              className="settings__navigator--item--sub settings__navigator--item--img"
              style={{
                backgroundImage: `url("${this.props.profilePictureUrl}"), url("https://i.ibb.co/qy1mkfK/Recruitment-Icon.png")`,
              }}
            />
            <div
              className={
                this.state.profilePictureFile === null
                  ? "btn btn__active btn__primary settings__navigator--item--sub"
                  : "btn btn__primary settings__navigator--item--sub settings__navigator--item--btn"
              }
            >
              <input
                type="file"
                name="avatar"
                accept="image/*"
                id="avatar"
                onChange={(e) => this.fileSelectEvent(e.target.files[0])}
                style={{ display: "none" }}
              />
              <label className="util__cursorPointer" htmlFor="avatar">
                Upload picture
              </label>
            </div>
            <div
              onClick={() => {
                this.setState({ showCamera: true });
              }}
              className="settings__navigator--item--sub btn btn__transparent btn__primaryBorders"
            >
              Take photo
            </div>
          </div>
          <Link className="util__noStyleLink" to="/settings/account">
            <div
              className={
                +this.state.active === 6
                  ? "settings__navigator--item settings__navigator--item--active"
                  : "settings__navigator--item settings__navigator--item--inactive"
              }
            >
              Account settings
            </div>
          </Link>
          <Link className="util__noStyleLink" to="/settings/password">
            <div
              className={
                +this.state.active === 7
                  ? "settings__navigator--item settings__navigator--item--active"
                  : "settings__navigator--item settings__navigator--item--inactive"
              }
            >
              Password
            </div>
          </Link>
          {/* <Link className="util__noStyleLink" to="/settings/jobs">
            <div
              className={
                +this.state.active === 8
                  ? "settings__navigator--item settings__navigator--item--active"
                  : "settings__navigator--item settings__navigator--item--inactive"
              }
            >
              Job Preferences
            </div>
          </Link> */}
        </div>
      </>
    );
  };

  render() {
    if (this.props.mobile)
      return +this.state.active > 5
        ? this.renderSettingsMenu()
        : this.renderMobileNavigationMenu();
    return +this.state.active > 5
      ? this.renderSettingsMenu()
      : this.renderNavigationMenu();
  }
}
