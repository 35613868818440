const icons = {
  error: "https://i.ibb.co/MfRKRDp/Error.png",
  info: "https://i.ibb.co/6gZ8BrQ/info.png",
  success: "https://i.ibb.co/mNQPLRL/Success.png",
  warn: "https://i.ibb.co/h1VSfJk/Warn.png",
};
const simpleIcons = {
  error: "icon-exclamation",
  info: "icon-info",
  success: "icon-check",
  warn: "icon-exclamation",
};
const disappearTimeout = 5000;
// eslint-disable-next-line default-param-last
const swishMessage = (type = "warn", text, subtext) => {
  try {
    const container = document.getElementById("swishContainer");
    const elem = document.createElement("div");
    elem.classList.add("swishMessage");
    elem.classList.add(`swishMessage__${type}`);

    const iconElem = document.createElement("div");
    iconElem.classList.add(simpleIcons[type]);
    iconElem.classList.add(`swishMessage__message--icon`);
    iconElem.classList.add(`swishMessage__message--${type}`);
    elem.appendChild(iconElem);

    const msgElem = document.createElement("p");
    msgElem.classList.add("swishMessage__message");
    msgElem.innerText = text;
    elem.appendChild(msgElem);

    if (subtext) {
      const subElem = document.createElement("p");
      subElem.classList.add("swishMessage__message");
      subElem.classList.add(`swishMessage__message--${type}`);
      subElem.classList.add(`swishMessage__message--sub`);
      subElem.innerText = subtext;
      elem.appendChild(subElem);
    }

    container.appendChild(elem);
    setTimeout(() => {
      container.removeChild(elem);
    }, disappearTimeout);
  } catch (err) {
    console.log(err);
  }
};

export default swishMessage;
