import { FETCH_JOB } from "./types";
import reactLogger from "../Component/utils/helpers/reactLogger";
export const fetchJobs = () => async (dispatch, getState, axiosInstance) => {
  try {
    const res = await axiosInstance.get("/api/jobs/getAllJobs");
    dispatch({
      type: FETCH_JOB,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: FETCH_JOB,
      payload: false,
    });
    reactLogger(err, "jobActions", "job's getAllJobs of axios has failed");
  }
};
