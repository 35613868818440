export const FETCH_USER = "fetch_user";
export const UPDATE_USER = "update_user";
export const FETCH_USER_FAILED = "fetch_user_failed";
export const FETCH_ADMIN = "fetch_admin";
export const USER_SIGNUP = "register_user";
export const USER_LOGIN = "login_user";
export const USER_LOGOUT = "logout_user";
export const USER_LOGIN_FAILED = "login_user_failed";

export const FETCH_JOB = "fetch_job";

export const SET_CONFIG = "set_config";

export const ADD_JOB = "ADD_JOB";
export const CLEAR_JOBS = "CLEAR_JOBS";
export const GET_DASHBOARD_JOB = "GET_DASHBOARD_JOB";
export const GET_ALL_JOBS = "GET_ALL_JOBS";

export const ADD_NEW_CHAT = "ADD_NEW_CHAT";
export const GET_CHAT = "GET_CHAT";
export const GET_CHATS = "GET_CHATs";
export const REFETCH_CHATS = "REFETCH_CHATS";
export const UPDATE_CHATS = "UPDATE_CHATS";
export const INC_CURRENT_CHAT_PAGE = "inc_current_chat_page";
export const FETCHING_MORE_CHATS = "FETCHING_MORE_CHATS";
export const FETCHING_MORE_CHATS_FAILED = "FETCHING_MORE_CHATS_FAILED";
export const CURRENT_CHAT_PAGE_LAST = "CURRENT_CHAT_PAGE_LAST";
export const DEC_CURRENT_CHAT_PAGE = "dec_current_chat_page";

export const ADD_NEW_JOB_HISTORY = "ADD_NEW_JOB_HISTORY";
export const GET_SINGLE_JOB_HISTORY = "GET_JOB_INFO_HISTORY";
export const GET_JOB_HISTORY = "GET_JOB_HISTORY";
export const REFETCH_JOB_HISTORY = "REFETCH_JOB_HISTORY";
export const INC_CURRENT_JOB_HISTORY_PAGE = "INC_CURRENT_JOB_HISTORY_PAGE";
export const FETCHING_MORE_JOB_HISTORY = "FETCHING_MORE_JOB_HISTORY";
export const FETCHING_MORE_JOB_HISTORY_FAILED =
  "FETCHING_MORE_JOB_HISTORY_FAILED";
export const CURRENT_JOB_HISTORY_PAGE_LAST = "CURRENT_JOB_HISTORY_PAGE_LAST";
export const DEC_CURRENT_JOB_HISTORY_PAGE = "dec_current_JOB_HISTORY_page";

export const INCOMING_MESSAGE = "incoming_message";

export const UPDATE_TEXT_MESSAGE = "update_text_message";

export const DARK_MODE_SETTING = "dark_mode_setting";

export const SET_CURRENT_SOCKET = "set_socket";
export const SET_CURRENT_SOCKET_UNDEF = "set_socket_undef";

export const CURRENT_JOB_POST = "current_job_post";
export const CURRENT_JOB_POST_UNDEF = "current_job_post_undef";

export const CURRENT_CANDIDATE_IN_VIEW = "current_candidate_in_view";
export const CURRENT_CANDIDATE_IN_VIEW_UNDEF =
  "current_candidate_in_view_undef";
