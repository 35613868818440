import React, { Component, Fragment } from "react";

import SignupForm from "./authForms/SignupForm";
import LoginForm from "./authForms/LoginForm";
import SimpleLineIcon from "../utils/components/SimpleLineIcons";

class LoginPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopupWithSignup: this.props.openPopupWithSignup,
      displayLoginMessage: false,
      loginWindowErrorVisible: false,
      loginWindowError: "",
      loginFormPasswordFocus: false,
      signupEmailValue: "",
      loginWindowSuccessVisible: this.props.successText.length > 0,
      loginWindowSuccess: this.props.successText,
      loginWindowSpecialSuccessVisible: false,
      loginWindowSpecialSuccess: "",
      loading: false,
    };
  }

  displayLoginError = (errorStatement) => {
    if (this.state.loginWindowError || this.state.loginWindowSuccess) {
      this.removeLoginWindowError();
      this.removeLoginWindowSuccess();
      setTimeout(() => {
        this.setState({
          loginWindowErrorVisible: true,
          loginWindowError: errorStatement,
        });
      }, 300);
    } else {
      this.setState({
        loginWindowErrorVisible: true,
        loginWindowError: errorStatement,
      });
    }
  };

  displayLoginSuccess = (successStatement) => {
    if (this.state.loginWindowError || this.state.loginWindowSuccess) {
      this.removeLoginWindowError();
      setTimeout(() => {
        this.setState({
          loginWindowSuccessVisible: true,
          loginWindowSuccess: successStatement,
        });
      }, 300);
    } else {
      this.setState({
        loginWindowSuccessVisible: true,
        loginWindowSuccess: successStatement,
      });
    }
  };

  specialLoginSuccess = (successStatement) => {
    if (this.state.loginWindowError || this.state.loginWindowSuccess) {
      this.removeLoginWindowError();
      setTimeout(() => {
        this.setState({
          loginWindowSpecialSuccessVisible: true,
          loginWindowSpecialSuccess: successStatement,
        });
      }, 300);
    } else {
      this.setState({
        loginWindowSpecialSuccessVisible: true,
        loginWindowSpecialSuccess: successStatement,
      });
    }
  };

  closePopup = () => {
    if (this.loginPopupChild) {
      this.loginPopupChild.classList.remove("anim__appearFromAboveLoginPopup");
      this.loginPopupChild.classList.add("anim__disappearFromAboveLoginPopup");
    }
    setTimeout(() => {
      if (this.loginPopupChild)
        this.loginPopupChild.classList.remove(
          "anim__disappearFromAboveLoginPopup"
        );
      this.removeLoginWindowError();
      this.removeLoginWindowSuccess();
      this.props.closePopup();
    }, 730);
  };

  removeLoginWindowError = () => {
    this.setState({ loginWindowErrorVisible: false });
  };

  removeLoginWindowSuccess = () => {
    this.setState({ loginWindowSuccessVisible: false });
  };

  activateLoginTab = () => {
    if (this.state.openPopupWithSignup === true)
      this.setState({ openPopupWithSignup: false });
  };

  activateSignupTab = () => {
    if (this.state.openPopupWithSignup === false)
      this.setState({ openPopupWithSignup: true });
  };

  moveToLogin = (successText, emailUserUsedToSignUp) => {
    this.specialLoginSuccess(successText);
    if (
      emailUserUsedToSignUp !== null ||
      emailUserUsedToSignUp !== "" ||
      typeof emailUserUsedToSignUp !== "undefined"
    )
      this.setState({
        openPopupWithSignup: false,
        loginFormPasswordFocus: true,
        signupEmailValue: emailUserUsedToSignUp,
      });
    else
      this.setState({
        openPopupWithSignup: false,
      });
  };

  startPopupLoading = () => {
    const popupDomElement = document.getElementById("loginPopupContainer");
    const popupCloseDomElement = document.getElementById(
      "loginPopupButtonClose"
    );
    popupDomElement.classList.remove("loginPopup__container");
    popupDomElement.classList.add("loginPopup__container--loading");
    popupCloseDomElement.classList.remove("loginPopup__button--close");
    popupCloseDomElement.classList.add("loginPopup__button--close--loading");
    this.setState({ loading: true });
  };

  stopPopupLoading = () => {
    const popupDomElement = document.getElementById("loginPopupContainer");
    const popupCloseDomElement = document.getElementById(
      "loginPopupButtonClose"
    );
    popupDomElement.classList.remove("loginPopup__container--loading");
    popupDomElement.classList.add("loginPopup__container");
    popupCloseDomElement.classList.remove("loginPopup__button--close--loading");
    popupCloseDomElement.classList.add("loginPopup__button--close");
    this.setState({ loading: false });
  };

  renderPopupWithSignup = () => {
    return (
      <>
        <div className="loginPopup__container__backdrop" />
        <div
          id="loginPopupContainer"
          ref={(ref) => (this.loginPopupChild = ref)}
          className="loginPopup__container anim__appearFromAboveLoginPopup"
        >
          <button
            type="button"
            id="loginPopupButtonLogin"
            className="loginPopup__button loginPopup__button--inactive"
            onClick={this.activateLoginTab}
          >
            Candidate Login
          </button>
          <button
            type="button"
            id="loginPopupButtonSignup"
            className="loginPopup__button  loginPopup__button--active"
            onClick={this.activateSignupTab}
          >
            Candidate Signup
          </button>
          <div
            id="loginPopupButtonClose"
            className="loginPopup__button loginPopup__button--close"
            onClick={this.closePopup}
          >
            <SimpleLineIcon name="close" color="red" />
          </div>
          <SignupForm
            displayLoginError={this.displayLoginError}
            displayLoginSuccess={this.displayLoginSuccess}
            specialLoginSuccess={this.specialLoginSuccess}
            removeLoginWindowError={this.removeLoginWindowError}
            startPopupLoading={this.startPopupLoading}
            stopPopupLoading={this.stopPopupLoading}
            moveToLogin={this.moveToLogin}
          />
          {this.state.loginWindowErrorVisible && (
            <div id="loginPopupError" className="loginPopup__error">
              {this.state.loginWindowError}
            </div>
          )}
          {this.state.loading && (
            <div className="createVcv__review__form__loader" />
          )}
        </div>
      </>
    );
  };

  renderPopupWithLogin = () => {
    return (
      <>
        <div className="loginPopup__container__backdrop" />
        <div
          id="loginPopupContainer"
          ref={(ref) => (this.loginPopupChild = ref)}
          className="loginPopup__container anim__appearFromAboveLoginPopup"
        >
          <button
            type="button"
            id="loginPopupButtonLogin"
            className="loginPopup__button loginPopup__button--active"
            onClick={this.activateLoginTab}
          >
            Candidate Login
          </button>
          <button
            type="button"
            id="loginPopupButtonSignup"
            className="loginPopup__button loginPopup__button--inactive"
            onClick={this.activateSignupTab}
          >
            Candidate Signup
          </button>
          <div
            id="loginPopupButtonClose"
            className="loginPopup__button loginPopup__button--close"
            onClick={this.closePopup}
          >
            <SimpleLineIcon name="close" color="red" />
          </div>
          {this.state.loginWindowSpecialSuccessVisible && (
            <div
              id="loginPopupSuccess"
              className="loginPopup__success heading__tertiary"
            >
              {this.state.loginWindowSpecialSuccess}
            </div>
          )}
          <LoginForm
            signupEmailValue={this.state.signupEmailValue}
            passwordFocus={this.state.loginFormPasswordFocus}
            displayLoginError={this.displayLoginError}
            displayLoginSuccess={this.displayLoginSuccess}
            removeLoginWindowError={this.removeLoginWindowError}
            startPopupLoading={this.startPopupLoading}
            stopPopupLoading={this.stopPopupLoading}
          />
          {this.state.loginWindowErrorVisible && (
            <div id="loginPopupError" className="loginPopup__error">
              {this.state.loginWindowError}
            </div>
          )}
          {this.state.loginWindowSuccessVisible && (
            <div
              id="loginPopupSuccess"
              className="loginPopup__success heading__tertiary"
            >
              {this.state.loginWindowSuccess}
            </div>
          )}
          {this.state.loading && <div className="loginPopup__loader" />}
        </div>
      </>
    );
  };

  render() {
    if (this.state.openPopupWithSignup) {
      return this.renderPopupWithSignup();
    }
    return this.renderPopupWithLogin();
  }
}
export default LoginPopup;
