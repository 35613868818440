import {
  INC_CURRENT_JOB_HISTORY_PAGE,
  CURRENT_JOB_HISTORY_PAGE_LAST,
  DEC_CURRENT_JOB_HISTORY_PAGE,
  FETCHING_MORE_JOB_HISTORY,
  FETCHING_MORE_JOB_HISTORY_FAILED,
} from "../Action/types";

export default (
  // eslint-disable-next-line default-param-last
  state = { currentPage: 0, hasFetchedAllJobHistory: false },
  action
) => {
  switch (action.type) {
    case INC_CURRENT_JOB_HISTORY_PAGE:
      return {
        currentPage: state.currentPage + 1,
        hasFetchedAllJobHistory: false,
      };
    case CURRENT_JOB_HISTORY_PAGE_LAST:
      return {
        currentPage: state.currentPage + 1,
        hasFetchedAllJobHistory: true,
      };
    case FETCHING_MORE_JOB_HISTORY:
      return { currentPage: state.currentPage, hasFetchedAllJobHistory: null };
    case FETCHING_MORE_JOB_HISTORY_FAILED:
      return {
        currentPage: state.currentPage,
        hasFetchedAllJobHistory: "failed",
      };
    case DEC_CURRENT_JOB_HISTORY_PAGE:
      return {
        currentPage: state.currentPage + 1,
        hasFetchedAllJobHistory: false,
      };
    default:
      return state;
  }
};
