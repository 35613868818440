import { UPDATE_USER } from "./types";

export const updateEmployer =
  (obj) => async (dispatch, getState, axiosInstance) => {
    try {
      await axiosInstance.put("/api/employer/v1/update_prop", { obj });
      const { auth } = getState();
      const updatedAuth = { ...auth, ...obj };
      dispatch({
        payload: updatedAuth,
        type: UPDATE_USER,
      });
    } catch (err) {
      console.log(err);
    }
  };
