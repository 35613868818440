import React, { Component, Fragment } from "react";
import axios from "axios";
import Footer from "../../Component/Footer/Footer";
import TopBlogs from "../../Component/BlogComponents/TopBlogs";
import Header from "../../Component/Header/Header";
import CheckMarkAnim from "../../Component/utils/components/CheckMarkAnim";
import SimpleLineIcon from "../../Component/utils/components/SimpleLineIcons";
import reactLogger from "../../Component/utils/helpers/reactLogger";

import "../../sass/components/_card.scss";

let hasCounted = false;
let videoCVQuestionsTimer = null;
let videoCVQuestionsTimeout = null;
let currentVideoCVQuestionsIndex = 0;
class AltLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: "",
      demoScheduled: false,
      demoScheduledEmail: "",
      demoScheduledName: "",
      demoScheduledPhone: "",
      companyNameValue: "",
      phoneValue: "",
      loading: false,
      displayError: false,
      error: "",
      videoCVQuestions: [
        "Q. What are your strengths?",
        "Q. How can you improve sales funnel at ACME LLC?",
        "Q. Briefly explain your personal client analysis process",
      ],
    };
  }

  displayLoginError = (message) => {
    this.setState({ error: message, displayError: true });
  };

  removeLoginError = () => {
    this.setState({ error: "", displayError: false });
  };

  handleSignup = (e) => {
    const EmailCheck = performEmailCheck(this.state.emailValue);
    const companyNameCheck = performStringCheck(this.state.companyNameValue);
    const phoneCheck = performPhoneCheck(this.state.phoneValue);

    if (!EmailCheck.success) {
      console.log("error", EmailCheck.message);
      this.displayLoginError(EmailCheck.message);
      this.childEmailInput.focus();
    } else if (!companyNameCheck.success) {
      console.log("error", companyNameCheck.message);
      this.displayLoginError(companyNameCheck.message);
      this.childCompanyInput.focus();
    } else if (!phoneCheck.success) {
      console.log("error", phoneCheck.message);
      this.displayLoginError(phoneCheck.message);
      this.childPhoneInput.focus();
    } else {
      const user = {
        email: this.state.emailValue.trim(),
        companyName: this.state.companyNameValue.trim(),
        phone: this.state.phoneValue.trim(),
      };
      this.startLoading();
      this.removeLoginError();
      this.submitDemoForm(user);
    }
    if (e) e.preventDefault();
  };

  submitDemoForm = async (user) => {
    this.startLoading();
    try {
      await axios.post("/api/employer/request_demo", { ...user });
      this.stopLoading();
      this.setState({
        demoScheduled: true,
        demoScheduledEmail: user.email,
        demoScheduledName: user.companyName,
        demoScheduledPhone: user.phone,
      });
    } catch (err) {
      this.stopLoading();
      console.log(err);
      if (err.response && err.response.data && err.response.data.errorMessage) {
        this.displayLoginError(err.response.data.errorMessage);
      } else {
        console.log(err);
        reactLogger(err, "AltLanding", "request demo form of axios has failed");
        this.displayLoginError("Something went wrong");
      }
    }
  };

  handleEmailChange = (e) => {
    this.setState({ emailValue: e.target.value });
  };

  handleCompanyNameChange = (e) => {
    this.setState({ companyNameValue: e.target.value });
  };

  handlePhoneChange = (e) => {
    this.setState({ phoneValue: e.target.value });
  };

  handleGstChange = (e) => {
    this.setState({ gstValue: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ passwordValue: e.target.value });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  startLoading = () => {
    this.setState({ loading: true });
  };

  moveSlider = (e) => {
    const sliderValue = 100 - e.target.value;
    this.childComparisonHandle.style.left = `${e.target.value}%`;
    this.childComparisonDivisor.style.width = `${sliderValue}%`;
  };

  changeQuestion = () => {
    const questionsElem = document.getElementById("videocv_question");

    if (questionsElem) {
      currentVideoCVQuestionsIndex =
        currentVideoCVQuestionsIndex === -1
          ? 0
          : currentVideoCVQuestionsIndex + 1;

      currentVideoCVQuestionsIndex =
        currentVideoCVQuestionsIndex === this.state.videoCVQuestions.length
          ? 0
          : currentVideoCVQuestionsIndex;

      questionsElem.innerHTML = "";

      const currentQuestion =
        this.state.videoCVQuestions[currentVideoCVQuestionsIndex];
      let i = 0;
      const speed = 50;

      (function typeWriter() {
        if (i < currentQuestion.length) {
          questionsElem.innerHTML += currentQuestion.charAt(i);
          i++;
          videoCVQuestionsTimeout = setTimeout(typeWriter, speed);
        }
      })();
    }
  };

  countToInteger = () => {
    function counter(element) {
      const duration = 1000;
      if (element) {
        const start = +element.getAttribute("item-min");
        const end = +element.getAttribute("item-max");

        let current = start;
        const range = end - start;
        const increment = end > start ? 10 : -10;
        const step = Math.abs(Math.floor(duration / range));
        const timer = setInterval(() => {
          current += increment;
          element.innerText = current;
          element.innerText += "+";
          if (current == end) {
            clearInterval(timer);
          }
        }, step);
      }
    }
    const counterElems = document.getElementsByClassName("integer_counter");
    Array.from(counterElems).forEach((elem) => counter(elem));
  };

  componentWillUnmount() {
    if (videoCVQuestionsTimer) {
      clearInterval(videoCVQuestionsTimer);
    }
    if (videoCVQuestionsTimeout) {
      clearTimeout(videoCVQuestionsTimeout);
    }
  }

  // refer to header.js for scroll button hiding

  componentDidMount() {
    window.addEventListener("scroll", (e) => {
      const scrollDistance =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollDistance >= 350) {
        if (!hasCounted) {
          this.countToInteger();
          hasCounted = true;
        }
      }
    });
    videoCVQuestionsTimer = setInterval(this.changeQuestion, 7000);
  }

  render() {
    return (
      <>
        <Header
          employerLandingAlt
          openLoginPopup={this.props.location.openLoginPopup}
        />

        <header className="landing__alt__hero landing__alt__section--even">
          <div
            style={{
              position: "absolute",
              width: "70%",
              right: 0,
              height: "100%",
              background: "url('https://i.ibb.co/FKZC2f6/dots-back.png')",
            }}
          />
          <div className="landing__alt__hero__content">
            <div className="landing__alt__testimonial--backPole--3" />
            <div className="landing__alt__testimonial--backPole--4" />
            <div id="comparison">
              <figure className="divisor__content--figure">
                <div
                  ref={(ref) => (this.childComparisonDivisor = ref)}
                  className="comparisonBox--anim"
                  id="divisor"
                >
                  <div className="divisor__content">
                    <div className="divisor__content__header">
                      <div className="divisor__content__header--img" />
                      <div style={{ marginTop: "1rem" }}>
                        <p className="divisor__content__header--head">
                          Jasmine Thompson
                        </p>
                        <p className="divisor__content__header--sub">
                          Abq, New Mexico | jthompson@me.co
                        </p>
                      </div>
                    </div>
                    <div className="divisor__content__body">
                      <p
                        style={{ textAlign: "left", marginBottom: "1rem" }}
                        className="divisor__content__footer--sub"
                      >
                        <strong id="videocv_question">
                          {this.state.videoCVQuestions[0]}
                        </strong>
                      </p>
                      <video
                        ref={(ref) => (this.childDemoVideo = ref)}
                        poster="https://i.ibb.co/wzGZBxR/Screenshot-2020-11-06-at-6-31-07-PM.png"
                        loop
                        autoPlay="autoplay"
                        muted
                        className="divisor__content__body--video"
                        width="100%"
                        src="https://hiresome-static.s3.ap-south-1.amazonaws.com/v/jasmine_thomson_videocv.mp4"
                      />
                      <div className="divisor__content__body__player">
                        <div
                          onClick={(e) =>
                            this.childDemoVideo.paused
                              ? this.childDemoVideo.play()
                              : this.childDemoVideo.pause()
                          }
                          className="divisor__content__body__player__play"
                        >
                          <SimpleLineIcon name="control-pause" color="white" />
                        </div>
                        <div className="divisor__content__body__player__seekbar">
                          <div>0:00</div>
                          <div className="divisor__content__body__player__seekbar--seekbar" />
                          <div>2:39</div>
                        </div>
                      </div>
                    </div>
                    <div className="divisor__content__footer">
                      <p className="divisor__content__footer--head">
                        {process.env.REACT_APP_HOST_TITLE} score:
                        <strong>8</strong>
                      </p>
                      <p className="divisor__content__footer--sub">
                        <strong>Education</strong>
                        <br />
                        <br />
                        University of Akron—Akron, Ohio
                        <br />
                        Psychology
                      </p>
                      <p className="divisor__content__footer--sub">
                        <br />
                        <br />
                        <strong>Experience</strong>
                        <br />
                        <br />
                        Venton ASSOCIATES, Somerset, NY
                        <br />
                        Social Media Manager, 2008 to Present
                      </p>
                    </div>
                  </div>
                </div>
              </figure>
              <div style={{ cursor: "pointer" }}>
                <div
                  ref={(ref) => (this.childComparisonHandle = ref)}
                  className="handle--anim"
                  id="handle"
                />
                <input
                  onChange={this.moveSlider}
                  className="comparison_slider"
                  type="range"
                  min="0"
                  max="100"
                  value="30"
                  id="slider"
                />
              </div>
            </div>
            <div className="landing__alt__hero__main">
              <h1 className="heading__goth heading__goth--bold landing__alt__hero--heading">
                #WatchTalkHire
              </h1>
              <h1
                style={{ fontSize: "2.615rem", marginTop: "1.4rem" }}
                className="heading__goth heading__goth--bold landing__alt__hero--heading"
              >
                The Right Culture Fit. Remotely.
              </h1>
              <p className="heading__goth heading__goth--secondary landing__alt__hero--sub">
                Join the prime interviewing platform and ATS to discern talent
                through VideoCVs.
              </p>
              <div className="landing__alt__hero__actions">
                <button
                  onClick={() => {
                    if (document.getElementById("employerLandingSignup")) {
                      window.scroll({
                        behavior: "smooth",
                        left: 0,
                        top:
                          document.getElementById("employerLandingSignup")
                            .offsetTop - 100,
                      });
                    }
                  }}
                  className="btn btn__primary btn__glow btn__goth--bold"
                >
                  Request Demo
                </button>
              </div>
            </div>
          </div>
        </header>
        {/* section  brands */}
        <section className="landing__alt__section">
          <div className="landing__alt__section__content">
            <div className="landing__alt__brands">
              <img
                style={{ opacity: "70%" }}
                className="landing__alt__brands--img"
                src="https://i.ibb.co/xg89VLD/amazon-logo-bw.png"
                alt="amazon"
              />
              <img
                style={{ opacity: "80%" }}
                className="landing__alt__brands--img"
                src="https://i.ibb.co/Qr3y3gv/zooper-logo-bw.png"
                alt="zooper"
              />
              {/* <img className="landing__alt__brands--img" src="https://i.ibb.co/TBFmdYT/128px-Amazon-logo-svg.png" alt="amazon" style={{width:"10rem"/> }} */}
              <img
                className="landing__alt__brands--img"
                src="https://i.ibb.co/D48NJnH/hk-logo-bw.png"
                alt="healthkart"
              />
              <img
                className="landing__alt__brands--img"
                src="https://i.ibb.co/NZFxshR/scootr-logo-bw.png"
                alt="skootr"
              />
              {/* <img className="landing__alt__brands--img" src="https://i.ibb.co/QKbRLqk/chayoos-logo-color.png" alt="chaayos" style={{width:"10rem"/> }} */}
              <img
                className="landing__alt__brands--img"
                src="https://i.ibb.co/mh1zMqy/chayoos-logo-bw.png"
                alt="chaayos"
              />
              {/* <img className="landing__alt__brands--img" src="https://i.ibb.co/ZBbyJSv/Healthkart-logo-color.png" alt="healthkart" style={{width:"10rem"/> }} */}
              {/* <img className="landing__alt__brands--img" src="https://i.ibb.co/wgKJg7r/toppr-logo-color.png" alt="toppr" style={{width:"10rem"/>}} */}

              <img
                style={{ opacity: "60%" }}
                className="landing__alt__brands--img"
                src="https://i.ibb.co/xKZTtj9/toppr-logo-bw.png"
                alt="toppr"
              />
              {/* <img className="landing__alt__brands--img" src="https://i.ibb.co/FVqBdvw/Zopper-logo-color.png" alt="zopper"/> */}

              {/* <img className="landing__alt__brands--img" src="https://i.ibb.co/k1DpKg5/skootr-logo-color.png" alt="skootr"/> */}
            </div>
          </div>
        </section>
        {/* section brands ends */}
        {/* section  Numbers */}

        <section
          style={{
            background: "linear-gradient(to bottom right,#3c72e2,#1bd1bdf2)",
          }}
          className="landing__alt__section landing__alt__section"
        >
          <div className="landing__alt__section__content">
            <div
              style={{ color: "#ffffff", marginBottom: "8rem" }}
              className="landing__title heading__primary heading__primary--light heading__goth--fam"
            >
              Numbers That Matter
            </div>
            <div className="landing__flexRowContainer">
              <div className="landing__flexRowContainer--item3">
                <div
                  style={{ textAlign: "center" }}
                  className="landing__alt__counter--int integer_counter heading__primary"
                  item-max="10000"
                  item-min="0"
                />
                <p
                  style={{ textAlign: "center" }}
                  className="landing__alt__counter--heading heading__goth"
                >
                  VideoCVs received
                </p>
                <p className="landing__alt__counter--sub heading__raleway--fam">
                  Only active, top-notch candidates from across industries and
                  hierarchies
                </p>
              </div>

              <div className="landing__flexRowContainer--item3">
                <div
                  style={{ textAlign: "center" }}
                  className="landing__alt__counter--int integer_counter heading__primary"
                  item-max="1000"
                  item-min="0"
                />
                <p
                  style={{ textAlign: "center" }}
                  className="landing__alt__counter--heading heading__goth"
                >
                  Interviews Conducted
                </p>
                <p className="landing__alt__counter--sub heading__raleway--fam">
                  Discover talent, schedule interviews, message, and hold
                  discussions with candidates.
                </p>
              </div>
              <div className="landing__flexRowContainer--item3">
                <div
                  style={{ textAlign: "center" }}
                  className="landing__alt__counter--int integer_counter heading__primary"
                  item-max="500"
                  item-min="0"
                />
                <p
                  style={{ textAlign: "center" }}
                  className="landing__alt__counter--heading heading__goth"
                >
                  Clients
                </p>
                <p className="landing__alt__counter--sub heading__raleway--fam">
                  A seamless transition with improved conversion rates worth
                  your time.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* section Numbers ends */}
        {/* section  Results */}
        <section className="landing__alt__section">
          <div className="landing__alt__section__content--spaced landing__alt__section__content--spaced">
            <div className="landing__flexRowContainer">
              <div className="landing__flexRowContainer--item3">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="">
                    <SimpleLineIcon
                      name="clock"
                      className="util__textColorAccent"
                      style={{ fontSize: "4.8rem" }}
                    />
                  </div>
                </div>
                <p
                  style={{ textAlign: "center", margin: "2rem 0 .5rem 0" }}
                  className="heading__secondary heading__goth--fam"
                >
                  Every minute counts
                </p>
                <p className="heading__tertiary landing__steps--step--subtitle heading__raleway--fam">
                  Why waste time on passive applicants and repetitive tasks?
                  Expedite your recruitment process and commit time to
                  meaningful interactions!
                </p>
              </div>

              <div className="landing__flexRowContainer--item3">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="">
                    <SimpleLineIcon
                      name="diamond"
                      color="#e91e63"
                      style={{ fontSize: "4.8rem" }}
                    />
                  </div>
                </div>
                <p
                  style={{ textAlign: "center", margin: "2rem 0 .5rem 0" }}
                  className="heading__secondary heading__goth--fam"
                >
                  Culture Fit
                </p>
                <p className="heading__tertiary landing__steps--step--subtitle heading__raleway--fam ">
                  Remote hiring made better when you meet the real candidate and
                  not their keyword version. Hire Better, Hire Intuitively
                  through VideoCVs!
                </p>
              </div>

              <div className="landing__flexRowContainer--item3">
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="">
                    <SimpleLineIcon
                      name="pie-chart"
                      color="#673AB7"
                      style={{ fontSize: "4.8rem" }}
                    />
                  </div>
                </div>
                <p
                  style={{ textAlign: "center", margin: "2rem 0 .5rem 0" }}
                  className="heading__secondary heading__goth--fam"
                >
                  Optimize Hiring
                </p>
                <p className="heading__tertiary landing__steps--step--subtitle heading__raleway--fam">
                  Say yes to a hassle-free, efficient hiring experience. All the
                  tools you need to source, connect, assess, and schedule
                  interviews on a single platform!
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* section Results ends */}
        {/* section Testimonial */}

        <section className="landing__alt__section landing__alt__section--even">
          <div
            style={{}}
            className="landing__alt__section__content landing__alt__section__content--testimonial"
          >
            <img
              src="/images/left_quote.svg"
              className="landing__alt__testimonial--leftQuote"
            />
            <div className="landing__alt__testimonial--backPole--1" />
            <div className="landing__alt__testimonial--backPole--2" />
            <div className="landing__alt__testimonial__action">
              <p className="landing__alt__testimonial__action--label heading__raleway--fam">
                {process.env.REACT_APP_HOST_TITLE} reduced hiring efforts for
                IVCA by 55% whilst shaping their company culture.
              </p>
              {/* <button className="btn btn__primary btn__goth">Read Their Story</button> */}
            </div>
            <div className="landing__alt__testimonial__story card--nohover">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img className="landing__alt__testimonial__story--img" />
                <div className="landing__alt__testimonial__story--icon">
                  <img
                    src="/images/left_quote.svg"
                    style={{ height: "2rem" }}
                  />
                </div>
              </div>
              <div style={{ margin: "5rem 0" }}>
                <p style={{ fontSize: "1.8rem" }}>
                  “ {process.env.REACT_APP_HOST_TITLE} helped me hire the right
                  culture fit 80% faster. I Couldn’t have been happier. ”
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    height: ".5rem",
                    width: "5rem",
                    backgroundColor: "#cb356b",
                    borderRadius: "2rem",
                  }}
                />
                <p
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: "bold",
                    color: "#cb356b",
                    margin: "1rem 0 .5rem 0",
                  }}
                >
                  Amit Pandey
                </p>
                <p style={{ fontSize: "1.2rem" }}>AVP, IVCA</p>
              </div>
            </div>
          </div>
        </section>

        {/* section Testimonial ends */}

        {/* section   Features */}
        <section
          style={{ position: "relative" }}
          className="landing__alt__section landing__alt__section--even"
        >
          <div className="landing__alt__section__content">
            <div className="landing__alt__signup__chip" />
            <div className="landing__title heading__primary heading__primary--light heading__goth--fam">
              Your complete toolkit!
            </div>
            <div
              style={{
                position: "relative",
                alignItems: "center",
              }}
              className="landing__flexRowContainer"
            >
              <div className="card card__infographic">
                <div className="card__infographic--image__container card__infographic--image__container--1">
                  <div
                    className="card__infographic--image"
                    style={{
                      backgroundImage:
                        'url("https://i.ibb.co/sv52Dws/Video-chat-business-meeting-concept-Businesswoman-taking-selfie-photo-using-smart-phone-app-on-smart.jpg")',
                    }}
                  />
                </div>
                <div className="card__infographic__info">
                  <p className="card__infographic__info--heading heading__goth">
                    VideoCVs
                  </p>
                  <p className="card__infographic__info--sub heading__raleway--fam">
                    <ul>
                      <li>Meet your candidate</li>
                      <li>Domain-specific questions</li>
                      <li>Communication and Confidence</li>
                    </ul>
                  </p>
                  <a
                    href="/features/videocv"
                    className="util__noStyleLink card__infographic__info--link"
                  >
                    Learn more
                  </a>
                </div>
              </div>
              <div className="card card__infographic">
                <div className="card__infographic--image__container card__infographic--image__container--2">
                  <div
                    className="card__infographic--image"
                    style={{
                      backgroundImage:
                        'url("https://i.ibb.co/1KCjjjc/pexels-ketut-subiyanto-4474047.jpg")',
                    }}
                  />
                </div>
                <div className="card__infographic__info">
                  <p className="card__infographic__info--heading heading__goth">
                    Assessments
                  </p>
                  <p className="card__infographic__info--sub heading__raleway--fam">
                    <ul>
                      <li>AI Fueled Scoring</li>
                      <li>Real-time Online tests </li>
                      <li>Transparent and secure</li>
                    </ul>
                  </p>
                  <a
                    href="/features/assessments"
                    className="util__noStyleLink card__infographic__info--link"
                  >
                    Learn more
                  </a>
                </div>
              </div>
              <div className="card card__infographic">
                <div className="card__infographic--image__container card__infographic--image__container--3">
                  <div
                    className="card__infographic--image"
                    style={{
                      backgroundImage:
                        'url("https://i.ibb.co/n1Bz7DF/pexels-anna-shvets-4226261.jpg")',
                    }}
                  />
                </div>
                <div className="card__infographic__info">
                  <p className="card__infographic__info--heading heading__goth">
                    Interviews
                  </p>
                  <p className="card__infographic__info--sub heading__raleway--fam">
                    <ul>
                      <li>Meaningful Video Interviews</li>
                      <li>Live assessments</li>
                      <li>Customize your interview rounds</li>
                    </ul>
                  </p>
                  <a
                    href="/features/video-interview"
                    className="util__noStyleLink card__infographic__info--link"
                  >
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* section Features ends */}

        {/* section  Form */}
        <section id="employerLandingSignup">
          {this.state.demoScheduled ? (
            <div className="landing__alt__signup__success__container">
              <div className="landing__alt__signup__success">
                <CheckMarkAnim scale="1" />
                <div
                  style={{ color: "#f1f1f1", textAlign: "center" }}
                  className="heading__secondary"
                >
                  Thank you! {this.state.demoScheduledName} for signing up for
                  the demo!
                  <br /> We'll reach out to you soon at{" "}
                  {this.state.demoScheduledEmail} &{" "}
                  {this.state.demoScheduledPhone}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="landing__alt__signup__poster">
                <div className="landing__alt__section__content">
                  <div className="landing__alt__signup__poster--title heading__goth">
                    Ready to become {process.env.REACT_APP_HOST_TITLE}!
                  </div>
                  <p className="landing__alt__signup__poster--sub heading__raleway--fam">
                    Let's get started
                  </p>
                  <div
                    style={{
                      position: "absolute",
                      width: "48rem",
                      right: "10rem",
                      height: "48rem",
                      transform: "rotate(49deg)",
                      backgroundColor: "#3b3bb188",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      width: "77rem",
                      left: "8rem",
                      height: "62rem",
                      transform: "rotate(35deg)",
                      backgroundColor: "#3b3bb1",
                    }}
                  />
                </div>
              </div>
              <div className="landing__alt__form__container">
                <div
                  style={{ backgroundColor: "white" }}
                  className="landing__alt__section__content"
                >
                  <div
                    style={{
                      transform: "translateY(-100px)",
                      backgroundColor: "white",
                      margin: "0",
                      boxShadow: "0.2rem 0.2rem 1rem 0 #e1e1e1",
                    }}
                    className={
                      this.state.loading
                        ? "landing__alt__authForm--loading "
                        : "landing__alt__authForm card--nohover"
                    }
                  >
                    <form
                      onSubmit={this.handleSignup}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.handleSignup();
                        }
                      }}
                    >
                      <div className="input__control">
                        <label
                          className="landing__alt__signup__form--label"
                          htmlFor="signupFormEmail"
                        >
                          Company Email
                        </label>
                        <input
                          ref={(ref) => (this.childEmailInput = ref)}
                          id="signupFormEmail"
                          className="landing__alt__signup__form--input input--wide input--active"
                          type="email"
                          name="email"
                          value={this.state.emailValue}
                          onChange={this.handleEmailChange}
                        />
                      </div>
                      <div className="input__control">
                        <label
                          className="landing__alt__signup__form--label"
                          htmlFor="signupFormName"
                        >
                          Company Name
                        </label>
                        <input
                          id="signupFormName"
                          ref={(ref) => (this.childCompanyInput = ref)}
                          className="landing__alt__signup__form--input input--wide input--active"
                          type="name"
                          name="name"
                          value={this.state.companyNameValue}
                          onChange={this.handleCompanyNameChange}
                        />
                      </div>
                      <div className="input__control">
                        <label
                          className="landing__alt__signup__form--label"
                          htmlFor="signupFormName"
                        >
                          Phone No.
                        </label>
                        <input
                          id="signupFormPhone"
                          ref={(ref) => (this.childPhoneInput = ref)}
                          className="landing__alt__signup__form--input input--wide input--active"
                          type="phone"
                          name="phone"
                          value={this.state.phoneValue}
                          onChange={this.handlePhoneChange}
                        />
                      </div>
                      {this.state.displayError && (
                        <div
                          id="signUpPopInError"
                          style={{ textAlign: "left" }}
                          className="employer__error"
                        >
                          {this.state.error}
                        </div>
                      )}
                      <button
                        onClick={!this.state.loading ? this.handleSignup : ""}
                        id="submitS"
                        style={{ marginTop: "8rem" }}
                        className="btn btn__primary btn__active btn__wide loginPopup__action btn__goth  landing__employer__signupPoster--btn"
                        type="button"
                      >
                        Request Demo
                      </button>
                    </form>

                    {this.state.loading && (
                      <div className="employer__authForm--loader" />
                    )}
                  </div>
                  <div className="landing__alt__signup__features">
                    <p className="landing__alt__signup__features--title heading__goth">
                      See how {process.env.REACT_APP_HOST_TITLE}
                      !
                      <br /> helps your team save time
                    </p>
                    <div className="landing__alt__signup__features--feature">
                      <SimpleLineIcon
                        style={{ marginRight: "1rem" }}
                        className="util__textColorAccent"
                        name="magic-wand"
                      />
                      <p className="heading__raleway--fam">24x7 support</p>
                    </div>
                    <div className="landing__alt__signup__features--feature">
                      <SimpleLineIcon
                        style={{ marginRight: "1rem" }}
                        className="util__textColorAccent"
                        name="calculator"
                      />
                      <p className="heading__raleway--fam">Save costs</p>
                    </div>
                    <div className="landing__alt__signup__features--feature">
                      <SimpleLineIcon
                        style={{ marginRight: "1rem" }}
                        className="util__textColorAccent"
                        name="diamond"
                      />
                      <p className="heading__raleway--fam">Right culture fit</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </section>

        {/* section Form ends */}

        <TopBlogs />
        <Footer altLanding />
      </>
    );
  }
}

function performEmailCheck(val) {
  if (typeof val !== "string" || val === null || typeof val === "undefined")
    return { success: false, message: "Email poorly formatted" };
  const re =
    /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const ans = re.test(String(val).toLowerCase());
  return { success: ans, message: "Email is poorly formatted" };
}

function performPhoneCheck(val) {
  if (typeof val !== "string" || val === null || typeof val === "undefined")
    return { success: false, message: "Phone poorly formatted" };
  val = val.trim();
  if (val.split("").length < 1)
    return { success: false, message: "Phone field cannot be left Empty" };
  if (val.split("").length < 10 || val.split("").length > 10)
    return { success: false, message: "Phone must be 10 digits long" };
  return { success: true };
}

function performStringCheck(val) {
  if (typeof val !== "string" || val === null || typeof val === "undefined")
    return { success: false, message: "name can only contain alphabets" };
  if (val.length < 1)
    return { success: false, message: "Field cannot be left empty " };
  /* else if ()
    return { success: false, message: "Name can only be an alphabet" }; */ return {
    success: true,
  };
}

export default {
  component: AltLanding,
};
