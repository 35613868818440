import { FETCH_JOB } from "../Action/types";

export default (state = null, action) => {
	switch (action.type) {
		case FETCH_JOB:
			if (action.payload === "") return false;
			return action.payload;
		default:
			return state;
	}
};
