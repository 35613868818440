import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import SimpleLineIcon from "../utils/components/SimpleLineIcons";

class EmployerToolsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      messagesVisible: false,
      notificationsVisible: false,
    };
  }

  componentDidMount() {
    if (window.location.pathname.includes("/employer/settings"))
      this.setState({ active: true });
  }

  render() {
    const { mobile } = this.props;
    const { active } = this.state;
    if (mobile)
      return (
        <div className="header__controlpane__toolsMenu__user">
          {active && (
            <Link className="util__noStyleLink" to="/employer/dashboard">
              <SimpleLineIcon name="arrow-left" color="#f1f1f1" />
            </Link>
          )}
        </div>
      );
    return (
      <div className="header__controlpane__toolsMenu">
        <Link
          className={
            active
              ? "util__noStyleLink header__controlpane__toolsMenu--item header__controlpane__toolsMenu--active"
              : "util__noStyleLink header__controlpane__toolsMenu--item"
          }
          style={active ? { backgroundColor: "#d3d3d3" } : {}}
          data-background-color="black"
          data-tooltip="Settings"
          to="/employer/settings"
        >
          <SimpleLineIcon name="settings" />
        </Link>
      </div>
    );
  }
}
EmployerToolsMenu.defaultProps = {
  mobile: false,
};
EmployerToolsMenu.propTypes = {
  mobile: PropTypes.bool,
};

export default EmployerToolsMenu;
